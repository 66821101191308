import { useLayoutEffect, useState } from 'react';

export const useWidthRef = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth || 0);
  }, [ref]);
  return width;
};
