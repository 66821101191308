import React from 'react';
import { TwoPanelContent } from '../../../../../../theme/ui/Layout/TwoPanelContent';
import { LabelIcon } from '../../../../../../assets';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorMessage from '../../../../../../shared/hooks/useErrorMessage';
import { useFormik } from 'formik';
import useWatchError from '../../../../../../shared/hooks/useWatchError';
import { getDeviceFromParamSelector } from '../../../../../../service/locations/selectors/getDeviceFromParam';
import { useAppSelector } from '../../../../../../redux/store.model';
import LocationAPI from '../../../../../../service/locations/LocationAPI';
import * as yup from 'yup';
import Button from '../../../../../../theme/ui/Atoms/Button/Button';
import Input from '../../../../../../theme/ui/Atoms/Input/Input';

const validationSchema = yup.object({
  unitName: yup.string().required('initial_setup.unit_name_required'),
});

function ManagerDeviceUpdateSetup() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const errorMessage = useErrorMessage();

  const params = useParams<{
    storeId: string;
    regionId: string;
    deviceId: string;
  }>();

  // get device
  const {
    isLoading: isLoadingLocations,
    error: errorLocations,
    // data: locations,
  } = LocationAPI.useGetLocationsQuery(undefined);
  const device = useAppSelector((state) =>
    getDeviceFromParamSelector(state, params)
  );

  const [updateSetup] = LocationAPI.useUpdateSetupDeviceMutation();

  const error = errorLocations;
  const isLoading = isLoadingLocations;

  useWatchError(error);

  const onClickBack = () => {
    navigate(`../actions`);
  };
  const formik = useFormik({
    initialValues: {
      unitName: device?.unitName ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) =>
      updateSetup({ ...device, unitName: values.unitName })
        .unwrap()
        .then(onClickBack)
        .catch(errorMessage),
  });
  return (
    <TwoPanelContent
      leftContent={
        <>
          <LabelIcon height={'5em'} />
          <Typography variant="subtitle2">
            {t('initial_setup.title_update')}
          </Typography>
          <Typography>{t('initial_setup.title_update_info')}</Typography>
        </>
      }
      rightContent={
        <Container maxWidth="xs" sx={{ paddingY: 3, width: '900px' }}>
          <Paper elevation={5} sx={{ padding: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Input
                  id={`unitName`}
                  name={`unitName`}
                  label={t('initial_setup.unit_name_label')}
                  placeholder={
                    t('initial_setup.unit_name_placeholder') ?? undefined
                  }
                  value={formik.values?.unitName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.unitName && formik.errors?.unitName
                  )}
                  helperText={
                    formik.touched.unitName && formik.errors?.unitName
                      ? t(formik.errors?.unitName)
                      : undefined
                  }
                  required
                  aria-required
                  fullWidth
                />
              </Box>

              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained-gray"
                    rounded
                    fullWidth
                    onClick={onClickBack}
                    disabled={formik.isSubmitting}
                  >
                    {t('button.back')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    rounded
                    fullWidth
                    type="submit"
                    loading={
                      formik.isSubmitting || !formik.isValid || isLoading
                    }
                  >
                    {t('button.confirm')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      }
    />
  );
}

export default ManagerDeviceUpdateSetup;
