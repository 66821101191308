import { emptyArray } from '../../../shared/ArrayUtil';
import { ZoneSettingWitIdResponse } from '../ZoneSettingsAPI.model';

/**
 * Get options from zone settings
 *
 * @param zoneSettings
 * @returns {Array<{value: string, text: string}>}
 */
export default function getOptionsFromZoneSettings(
  zoneSettings: ZoneSettingWitIdResponse[] | undefined
): { value: string; text: string }[] {
  return (
    zoneSettings?.map((zoneSetting) => ({
      value: zoneSetting.id,
      text: zoneSetting.displayName,
    })) ?? emptyArray
  );
}
