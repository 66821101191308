import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import {
  DayPartAPIResponse,
  DayPartEditAPIResponse,
  ScheduleAPIResponse,
  ScheduleEditAPIResponse,
  StoreSchedulesUpdateRequiredAPIResponse,
} from './ScheduleAPI.model';
import { v4 as uuidv4 } from 'uuid';

const ScheduleAPI = createApi({
  reducerPath: 'schedule',
  tagTypes: ['day-part-actions', 'schedule-actions', 'schedule-update'],

  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getDayPartByRegion: build.query<DayPartAPIResponse[], string>({
        query: (regionId: string) => {
          return {
            url: `group/${regionId}/dayparts`,
            method: 'get',
          };
        },
        providesTags: ['day-part-actions'],
      }),
      setDayPartByRegion: build.mutation<
        any,
        {
          dayPart: DayPartEditAPIResponse;
          regionId: string;
        }
      >({
        query: ({ dayPart, regionId }) => {
          const dayPartId = dayPart.id ?? uuidv4();
          return {
            url: `group/${regionId}/dayparts/${dayPartId}?recursive=true`,
            method: 'put',
            data: dayPart,
          };
        },
        invalidatesTags: ['day-part-actions'],
      }),
      deleteDayPartByRegion: build.mutation<
        any,
        {
          dayPartId: string;
          regionId: string;
        }
      >({
        query: ({ dayPartId, regionId }) => {
          return {
            url: `group/${regionId}/dayparts/${dayPartId}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['day-part-actions'],
      }),
      getSchedulesByRegion: build.query<ScheduleAPIResponse[], string>({
        query: (regionId: string) => {
          return {
            url: `group/${regionId}/schedules`,
            method: 'get',
          };
        },
        providesTags: ['schedule-actions'],
      }),

      setSchedulesByRegion: build.mutation<
        any,
        {
          schedule: ScheduleEditAPIResponse;
          regionId: string;
        }
      >({
        query: ({ schedule, regionId }) => {
          const scheduleId = schedule.scheduleId ?? uuidv4();
          return {
            url: `group/${regionId}/schedules/${scheduleId}?recursive=true`,
            method: 'put',
            data: schedule,
          };
        },
        invalidatesTags: ['schedule-actions'],
      }),
      deleteScheduleByRegion: build.mutation<
        any,
        {
          scheduleId: string;
          regionId: string;
        }
      >({
        query: ({ scheduleId, regionId }) => {
          return {
            url: `group/${regionId}/schedules/${scheduleId}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['schedule-actions'],
      }),
      // Operator
      getSchedulesUpdateRequiredByStore: build.query<
        StoreSchedulesUpdateRequiredAPIResponse['devices'],
        void
      >({
        query: () => {
          return {
            url: `operator/store/schedules`,
            method: 'get',
          };
        },
        providesTags: ['schedule-update'],
        transformResponse: (
          response: StoreSchedulesUpdateRequiredAPIResponse
        ) => {
          return response.devices;
        },
      }),
    };
  },
});

export default ScheduleAPI;
