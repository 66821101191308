import React from 'react';
import { CircularProgress } from '@mui/material';
import { ShelfSettingDayPartResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';

interface ZonesSettingsStringProps {
  shelfSettings: ShelfSettingDayPartResponse[];
  optionsZoneSettings: {
    value: string;
    text: string;
  }[];
  loading?: boolean;
}

/**
 * to string of ShelfStettings per day part
 * @param props
 * @returns print a string with the zone settings sperated by " / "
 */
function ZonesSettingsString(props: ZonesSettingsStringProps) {
  const { shelfSettings, optionsZoneSettings, loading } = props;

  if (loading) return <CircularProgress />;
  return (
    <span>
      {shelfSettings
        .map(
          (shelfSetting, index) =>
            optionsZoneSettings.find(
              (option) => option.value === shelfSetting.setting
            )?.text
        )
        .join(' / ')}
    </span>
  );
}

export default ZonesSettingsString;
