import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../slice/AsyncTasksSlice.model';
import StoreService from '../../../../../../service/store/StoreService';
import { TriggerDayPartAPIRequest } from '../../../../../../service/store/StoreService.model';
import { toBase64 } from '../../../../../../shared/util/base64';

export const triggerDayPartAsyncThunk = createAsyncThunk(
  `${nameReducer}/triggerDayPartAsyncThunk`,
  async ({
    deviceId,
    triggerDayPart,
  }: {
    deviceId: string;
    triggerDayPart: TriggerDayPartAPIRequest;
  }) => {
    return await StoreService.triggerDayPart(deviceId, triggerDayPart);
  }
);

export const setScheduleRequireUpdateAsyncThunk = createAsyncThunk(
  `${nameReducer}/setScheduleRequireUpdateAsyncThunk`,
  async (args: { deviceId: string; scheduleId: string | null }, thunkAPI) => {
    const { deviceId } = args;
    const response = await StoreService.setScheduleRequireUpdate(
      toBase64(deviceId)
    );
    return response;
  }
);
