import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getTypeUserSelector } from '../../redux/auth/selectors';
import { RootState } from '../../redux/store.model';

interface HomeProps extends ConnectedComponentProps {}
/**
 * No home, just redirection to login or timers depending if is authenticated
 * */
function Home(props: HomeProps) {
  switch (props.typeUser) {
    case 'operator':
      return <Navigate to="/operation" replace />;
    case 'manager':
      return <Navigate to="/manager/view-locations" replace />;
    default:
      return <Navigate to="/login" replace />;
  }
}

const mapStateToProps = (state: RootState) => ({
  typeUser: getTypeUserSelector(state),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(Home);
