import { AxiosRequestConfig } from 'axios';
import { getManagerAuthTokenSelector } from '../../../redux/auth-manager/selectors';
import { getAuthTokenSelector } from '../../../redux/auth/selectors';
import authMutex from './authMutex';

/**
 * Add the authorization header to the request, with bearer token stored in redux store
 * @param request
 * @returns
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const store = (await import('./../../../redux/Store')).default;

  if (!store) throw new Error('store not initialized');
  if (!request?.url?.includes('/token') && authMutex.isLocked())
    await authMutex.waitForUnlock();
  const token = getAuthTokenSelector() || getManagerAuthTokenSelector();

  // not authenticated
  if (token === '') {
    return request;
  }

  const authHeaderValue = `Bearer ${token}`;
  if (!request.headers) request.headers = {};
  request.headers.Authorization = authHeaderValue;

  return request;
};

export default authInterceptor;
