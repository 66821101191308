import React from 'react';
import clsx from 'clsx';
import { buttonClasses, getButtonUtilityClass } from '@mui/material/Button';
import MUIButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { alpha, CSSInterpolation } from '@mui/system';
import capitalize from '@mui/material/utils/capitalize';
import { PaletteColor, styled, useThemeProps } from '@mui/material/styles';
import composeClasses from '@mui/base/composeClasses';
import { shouldForwardProp } from '../../util/shouldForwardProps';
import { CircularProgress } from '@mui/material';

export interface ButtonProps extends ButtonBaseProps {
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
   * @default 'primary'
   */
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';

  /**
   * The variant to use.
   * @default 'contained'
   */
  variant: 'contained' | 'text' | 'contained-gray';
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';

  // ownerState: ButtonProps;
  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /** chose true if you want border rounded, false if you want squared a bit rounded */
  rounded?: boolean;
  /** chose true if you want to disable, and use loading icon as a children */
  loading?: boolean;
  component?: any;
}

const useUtilityClasses = (ownerState: ButtonProps) => {
  const {
    variant = 'text',
    color = 'primary',
    size = 'medium',
    classes,
    fullWidth,
  } = ownerState;

  const slots = {
    root: [
      'root',
      variant,
      `${variant}${capitalize(color ?? '')}`,
      `size${capitalize(size)}`,
      `${variant}Size${capitalize(size)}`,
      color === 'inherit' && 'colorInherit',
      fullWidth && 'fullWidth',
    ],
    label: ['label'],
    startIcon: ['startIcon', `iconSize${capitalize(size)}`],
    endIcon: ['endIcon', `iconSize${capitalize(size)}`],
  };

  const composedClasses = composeClasses(slots, getButtonUtilityClass, classes);

  return {
    ...classes, // forward the focused, disabled, etc. classes to the ButtonBase
    ...composedClasses,
  };
};

const ButtonRoot = styled(MUIButtonBase, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
  name: 'FSButton',
  slot: 'Root',
  overridesResolver: (props: ButtonProps, styles) => {
    return [
      styles.root,
      styles[props.variant],
      styles[`${props.variant}${capitalize(props.color ?? 'primary')}`],
      styles[`size${capitalize(props.size ?? 'medium')}`],
      styles[`${props.variant}Size${capitalize(props.size ?? 'medium')}`],
      props.color === 'inherit' && styles.colorInherit,
      // ownerState.disableElevation && styles.disableElevation,
      props.fullWidth && styles.fullWidth,
    ];
  },
})<ButtonProps>(
  ({ ...otherProps }) => {
    const {
      variant = 'text',
      color = 'primary',
      size = 'medium',
      rounded,
      fullWidth,
      theme,
    } = otherProps;
    const result: CSSInterpolation = {
      ...theme.typography.button,
      textTransform: undefined,
      minWidth: 64,
      padding: '6px 16px',
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create(
        [
          'background-color',
          'box-shadow',
          'border-color',
          'color',
          'background-position',
        ],
        {
          duration: theme.transitions.duration.standard,
        }
      ),
      '&:hover': {
        // change the direction of the change here
        backgroundPosition: 'right center',

        textDecoration: 'none',
        backgroundColor: alpha(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        ...(variant === 'text' &&
          color !== 'inherit' && {
            backgroundColor: alpha(theme.palette[color].main, 0.3),
            color: theme.palette.text.primary,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          }),

        ...(variant === 'contained' && {
          boxShadow: theme.shadows[4],
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            boxShadow: theme.shadows[2],
          },
        }),
        ...(variant === 'contained-gray' && {
          color: (theme.palette as any)[color]?.main,
          backgroundColor: theme.palette.mode === 'dark' ? '#DEDEDE' : '#AAA',
          fontWeight: 'bold',
        }),
      },
      '&:active': {
        ...(variant === 'contained' && {
          boxShadow: theme.shadows[8],
        }),
      },
      [`&.${buttonClasses.focusVisible}`]: {
        ...(variant === 'contained' && {
          boxShadow: theme.shadows[6],
        }),
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.action.disabled,

        ...(variant === 'contained' && {
          color: theme.palette.action.disabled,
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.action.disabledBackground,
        }),
      },
      ...(variant === 'text' && {
        padding: '6px 8px',
      }),
      ...(variant === 'text' &&
        color !== 'inherit' && {
          color: theme.palette[color].main,
        }),
      ...(variant === 'contained' && {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        fontWeight: 'bold',
        backgroundImage: theme.palette.gradientColor.extended,
        backgroundSize: '200% auto',
        boxShadow: theme.shadows[2],
      }),
      ...(color === 'inherit' && {
        color: 'inherit',
        borderColor: 'currentColor',
      }),
      ...(variant === 'contained-gray' && {
        color:
          (theme.palette[color as keyof typeof theme.palette] as PaletteColor)
            ?.main ?? 'inherit',
        backgroundColor: theme.palette.mode === 'dark' ? '#EAEAEB' : '#CCC',
        fontWeight: 'bold',
      }),
      ...(size === 'small' &&
        variant === 'text' && {
          padding: '4px 5px',
          fontSize: theme.typography.pxToRem(13),
        }),
      ...(size === 'large' &&
        variant === 'text' && {
          padding: '8px 11px',
          fontSize: theme.typography.pxToRem(15),
        }),

      ...(size === 'small' &&
        variant === 'contained' && {
          padding: '4px 10px',
          fontSize: theme.typography.pxToRem(13),
        }),
      ...(size === 'large' &&
        variant === 'contained' && {
          padding: '8px 22px',
          minWidth: '14rem',
          fontSize: theme.typography.pxToRem(15),
        }),
      ...(rounded && {
        borderRadius: '2rem',
      }),
      ...(fullWidth && {
        width: '100%',
      }),
    };
    return result;
  },
  (props) =>
    (props as any).disableElevation && {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      [`&.${buttonClasses.focusVisible}`]: {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      [`&.${buttonClasses.disabled}`]: {
        boxShadow: 'none',
      },
    }
);

const Button = React.forwardRef(function Button(
  inProps: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const props = useThemeProps({
    props: inProps,
    name: 'FSButton',
  });
  const {
    children,
    className,
    disabled = false,
    focusVisibleClassName,
    type,
    variant = 'text',
    color = 'primary',
    size = 'medium',
    loading = false,
    ...other
  } = props;
  const classes = useUtilityClasses(props);

  return (
    <ButtonRoot
      focusVisibleClassName={clsx(classes.focusVisible, focusVisibleClassName)}
      ref={ref}
      disabled={disabled}
      variant={variant}
      color={color}
      size={size}
      type={type}
      {...other}
      className={clsx(classes.root, className)}
      classes={classes}
    >
      {loading ? (
        <CircularProgress color="inherit" size={'1.75em'} />
      ) : (
        children
      )}
    </ButtonRoot>
  );
});
export default Button;
