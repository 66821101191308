import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { shouldForwardProp } from '../../util/shouldForwardProps';

const PaperButton = styled(Paper, {
  shouldForwardProp,
  name: 'FSPaperButton',
  slot: 'Root',
})(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create(
    [
      'background-color',
      'box-shadow',
      'border-color',
      'color',
      'background-position',
    ],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.hoverOpacity
    ),
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof Paper;

export default PaperButton;
