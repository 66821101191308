import axios from 'axios';
import packageJson from '../../../package.json';
import envs from '../../shared/constants/Environment';
import authInterceptor from './interceptors/authInterceptor';
import enhanceErrorInterceptor from './interceptors/enhanceErrorInterceptor';
import {
  networkInterceptorsError,
  networkInterceptorsResponse,
} from './interceptors/networkInterceptors';
import refreshTokenErrorInterceptor from './interceptors/refreshTokenErrorInterceptor';

let restClientAbortController = new AbortController();

export const abortCallsRestClient = () => {
  restClientAbortController.abort();
  restClientAbortController = new AbortController();
  AxiosFlexeServeInstance.defaults.signal = restClientAbortController.signal;
};

const AxiosFlexeServeInstance = axios.create({
  baseURL: `${envs.backendURL}/api/`,
  headers: {
    // Custom headers to monitoring easy
    'X-Request-Source': 'Flexe-Serve-Web-UI',
    'X-Flexe-Serve-Web-UI-Version': packageJson.version,
  },
  responseType: 'json',
  signal: restClientAbortController.signal,
  withCredentials: true,
});

// add interceptors
AxiosFlexeServeInstance.interceptors.request.use(authInterceptor);
AxiosFlexeServeInstance.interceptors.response.use(
  (response) => response,
  refreshTokenErrorInterceptor(AxiosFlexeServeInstance)
);

AxiosFlexeServeInstance.interceptors.response.use(
  networkInterceptorsResponse,
  networkInterceptorsError
);

AxiosFlexeServeInstance.interceptors.response.use(
  (response) => response,
  enhanceErrorInterceptor
);

// Log interceptor
// AxiosFlexeServeInstance.interceptors.response.use(
//   (response) => {
//     console.log('AxiosResponse:', response);
//     return response;
//   },
//   (error) => {
//     console.log('AxiosReject:', error);
//     return Promise.reject(error);
//   }
// );

export default AxiosFlexeServeInstance;
