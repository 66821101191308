import { RootState } from '../../../../../redux/store.model';
import { getAllDevicesFromStoreSelector } from '../../../Store.selectors';
import ZoneApi from '../ZoneApi';

export const getDevicesWithZoneSettingsSelector = (state: RootState) =>
  getAllDevicesFromStoreSelector(state).map((device) => {
    const response = ZoneApi.endpoints.getZoneSettings.select(device.uuid)(
      state
    );
    return {
      device,
      zoneSettings: response.data,
      zoneSettingsLoading: response?.isLoading,
    };
  });

export default getDevicesWithZoneSettingsSelector;
