import { getStateFromTimer } from '../../../../../theme/ui/Atoms/TimerButton/TimerButton';
import { TimersResponse } from '../ZoneService.model';

/**
 * get true if any timer inside of zone on the position `zoneIndex` has state `alert` using `getStateFromTimer`
 * @param device
 * @param zoneIndex
 * @returns has any timer alert
 */
export function getAlertFromZone(
  timers: TimersResponse | undefined,
  zoneIndex: number | string
): boolean {
  const timersZone = timers?.[zoneIndex];
  if (timersZone?.length) {
    return timersZone.some((timer) => getStateFromTimer(timer) === 'alert');
  }
  return false;
}
