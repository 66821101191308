class Environment {
  // Auth constants
  /** Auth constant for OAuth */
  clientID = process.env.REACT_APP_CLIENT_ID
    ? process.env.REACT_APP_CLIENT_ID
    : 'client_fm9k6e04frv42les69bla6fate';
  /** Auth constant for OAuth */
  clientSecret = process.env.REACT_APP_CLIENT_SECRET
    ? process.env.REACT_APP_CLIENT_SECRET
    : 'nhvf9t24re998o7os1aoehucuk';

  // Backend constant
  /** Backend constant @default hostname */
  backendHost = process.env.REACT_APP_BACKEND_HOST || window.location.hostname;
  /** Backend constant @default protocol */
  backendProto =
    process.env.REACT_APP_BACKEND_PROTOCOL || window.location.protocol;
  /** Backend constant @default port */
  backendPort = process.env.REACT_APP_BACKEND_PORT || window.location.port;
  /** Backend constant composed with `hostname` `port` and `protocol` */
  get backendURL() {
    return `${this.backendProto}//${this.backendHost}${
      this.backendPort ? ':' + this.backendPort : ''
    }`;
  }
  // Storage constants
  authInfoKey = 'flexeserve_auth_info';
  authInfoManagerKey = 'flexeserve_auth_manager_info';
}

export default new Environment();
