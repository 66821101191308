import React from 'react';
import Header, {
  HeaderProps,
} from '../../../../../../theme/ui/Layout/Header/Header';
import LogoImg from '../../../../assets/circleK-logo.svg';

function HeaderCircleK(props: HeaderProps) {
  return <Header {...props} headerLogoSrc={LogoImg} />;
}

export default HeaderCircleK;
