import React, { useRef } from 'react';
import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import ElevationScroll from '../ElevationScroll';

export interface ModalProps extends MUIDialogProps {
  open: boolean;
  title?: string;
  iconURL?: string;

  onClose?: () => unknown;
  disabledClose?: boolean;
  tabs?: JSX.Element;
  buttonActions?: JSX.Element;
  children: React.ReactNode;
}

export function Modal(props: ModalProps) {
  const {
    iconURL,
    title,
    disabledClose,
    onClose,
    buttonActions,
    tabs,
    children,
    open,
    ...dialogProps
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const contentRef = useRef(null);

  return (
    <MUIDialog
      onClose={disabledClose ? onClose : undefined}
      fullScreen={fullScreen}
      open={!!open}
      {...dialogProps}
      aria-labelledby="form-dialog-title"
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar position="static" variant="elevation" sx={{ zIndex: 1 }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {iconURL ? (
                <Box
                  component="img"
                  sx={{ height: '1.2em', mr: 1, verticalAlign: 'middle' }}
                  src={iconURL}
                  alt="icon_header"
                ></Box>
              ) : null}
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              disabled={disabledClose}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          {tabs}
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
      {buttonActions && <DialogActions>{buttonActions}</DialogActions>}
    </MUIDialog>
  );
}

export default Modal;
