import {
  COLOR_SCALE_CHARTS_COLORS,
  colorsScale,
  getDefaultMode,
} from 'stoerk-ui-components';
import { STTimeLineChartData } from '../monitoring.model';
import { CommanderMonitoringFilterLogicalObject } from './filter.model';
import { uniq, orderBy } from 'lodash';
import { SensordataSTChartLine } from '../../../../../service/monitoring/monitoring.model';

export function getPaletteColors(
  paletteColors: ReturnType<typeof getPaletteColorsByControlUnitsDevices>,
  digitalChannels: STTimeLineChartData,
  sensordata: SensordataSTChartLine[]
) {
  const paletteColorsDigital = getPaletteColorsDigital(
    paletteColors,
    digitalChannels
  );
  const paletteColorsAnalog = getPaletteColorsAnalog(paletteColors, sensordata);
  return {
    paletteColorsDigital,
    paletteColorsAnalog,
  } as const;
}

function getPaletteColorsDigital(
  paletteColors: ReturnType<typeof getPaletteColorsByControlUnitsDevices>,
  digitalChannels: STTimeLineChartData
): string[] {
  // order by data length and name,
  // because the ChartTimeline component do the same inside
  const orderedListData = orderBy(
    Object.values(digitalChannels).map((item) => ({
      ...item,
      dataLength: item.data.length,
    })),
    ['data.length', 'name'],
    ['desc', 'asc']
  );

  return orderedListData.map(
    (channel) => paletteColors[`${channel.deviceId}-${channel.controlUnitId}`]
  );
}

function getPaletteColorsAnalog(
  paletteColors: ReturnType<typeof getPaletteColorsByControlUnitsDevices>,
  sensordata: SensordataSTChartLine[]
): string[] {
  return sensordata.map(
    (channel) => paletteColors[`${channel.deviceId}-${channel.controlunit}`]
  );
}

/**
 * Get palette colors by control units and devices involved
 * Only one color for each control units
 * @param digitalFilterObjectEvents
 * @param analogFilterObjectEvents
 *
 */
export function getPaletteColorsByControlUnitsDevices(
  digitalFilterObjectEvents: CommanderMonitoringFilterLogicalObject,
  analogFilterObjectEvents: CommanderMonitoringFilterLogicalObject,
  mode = getDefaultMode()
): { [deviceId_controlUnitId: string]: string } {
  /** 1. get all possible keys by control unit */
  const controlUnitKeys = [
    ...getKeysControlUnitsDevicesFromFilterObject(digitalFilterObjectEvents),
    ...getKeysControlUnitsDevicesFromFilterObject(analogFilterObjectEvents),
  ];
  /** 2. remove  */
  const uniqControlUnitKeys = uniq(controlUnitKeys);

  /** 3. get palette colors */
  const paletteColors = colorsScale(
    mode,
    Object.keys(uniqControlUnitKeys).length,
    COLOR_SCALE_CHARTS_COLORS
  );
  /** 4. create dic to associate each color with control unit */
  return uniqControlUnitKeys.reduce((acc, key, index) => {
    acc[key] = paletteColors[index];
    return acc;
  }, {} as { [deviceId_controlUnitId: string]: string });
}

function getKeysControlUnitsDevicesFromFilterObject(
  filterObjectEvents: CommanderMonitoringFilterLogicalObject
): string[] {
  return Object.entries(filterObjectEvents)
    .map(([deviceId, filterObjectDeviceEvents]) =>
      Object.keys(filterObjectDeviceEvents ?? {}).map(
        (controlUnitId) => `${deviceId}-${controlUnitId}`
      )
    )
    .flat();
}

export default getPaletteColors;
