import React from 'react';
import { useParams } from 'react-router-dom';
import ManagerTemperaturePreference from '../../ManagerTemperaturePreference';
import { toBase64 } from '../../../../../../shared/util/base64';

function ManagerTemperaturePreferenceStore() {
  const params = useParams<{ storeId: string }>();
  const { storeId = '' } = params;
  const groupId = toBase64(storeId);
  return (
    <ManagerTemperaturePreference
      groupId={groupId}
      title="settings.temperature_preference.title_store"
      hintText="settings.temperature_preference.manager_store_hint"
    />
  );
}

export default ManagerTemperaturePreferenceStore;
