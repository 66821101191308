import React from 'react';
import { TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker as MUIMobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import Input from '../Input';
import { Moment } from 'moment';

const TimerPicker = (
  props: Omit<TimePickerProps<any, Moment>, 'renderInput'>
) => {
  return (
    <MUIMobileTimePicker
      {...props}
      renderInput={(params: any) => {
        return <Input {...params} />;
      }}
    />
  );
};

export default TimerPicker;
