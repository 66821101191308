/**
 * Transform @param array to dictionary mapped with specific @param key
 * @param array to transform
 * @param key possible key of each items in array
 *
 * example
 * ```ts
 * arrayToRecord([{uuid:"2x2sda",name:"franco"},{uuid:"2222da",name:"JuanSe"}], 'uuid')
 * // => {2x2sda:{uuid:"2x2sda",name:"franco"},2222da:{uuid:"2222da",name:"JuanSe"}}
 * ```
 */
function recordToArray<T, ResultMap>(
  record: Record<string, T>,
  keyAttribute: keyof ResultMap,
  mapValue = (key: string, value: T) => ({ ...value, [keyAttribute]: key })
): ResultMap[] {
  return Object.entries(record).map(([key, value]) =>
    mapValue(key, value)
  ) as ResultMap[];
}

export default recordToArray;
