import { styled } from '@mui/material';

const Table = styled('table')({
  borderSpacing: '0px 12px',
  width: '100%',
  '& td,th': {
    padding: '0px 38px',
    minWidth: 120,
    height: 46,
    textAlign: 'center',
  },
  '& td': { margin: '38px 0px ' },
});

export default Table;
