import produce from 'immer';
import { DeviceControlUnitsEventsMetadataAPIResponse } from '../../../device/device.model';
import {
  DigitalChannelEvent,
  DigitalChannelEventMixed,
} from '../../../monitoring/monitoring.model';

export const mergeEventAndMetadata = (
  valueEvents: Record<string, DigitalChannelEvent | undefined> | undefined,
  eventsMetadata: DeviceControlUnitsEventsMetadataAPIResponse[] | undefined
) => {
  return produce(
    valueEvents as Record<
      DigitalChannelEventMixed['eventID'],
      DigitalChannelEventMixed | undefined
    >,
    (draftValueEvents) => {
      Object.values(draftValueEvents || {}).forEach((valueEvent) => {
        if (valueEvent)
          valueEvent.metadata = eventsMetadata?.find(
            (eventMetadata) => eventMetadata.id === valueEvent?.eventID
          );
      });
    }
  );
};
