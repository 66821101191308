/**
 * Use Storage to avoid refreshToken invalid and token expiration when the app is used in different tabs
 */
import { IAuthInfo } from '../../../service/auth/AuthService.model';
import Environment from '../../../shared/constants/Environment';
import Storage from '../../../shared/Storage';

export const isManagerAuthenticatedSelector = () =>
  !!getManagerAuthTokenSelector();

export const getManagerAuthRefreshTokenSelector = () =>
  Storage.getItem<IAuthInfo>(Environment.authInfoManagerKey)?.refreshToken;

export const getManagerAuthTokenSelector = () =>
  Storage.getItem<IAuthInfo>(Environment.authInfoManagerKey)?.token;
