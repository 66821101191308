/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/settings.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../../../assets/arrow_back.svg';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../theme/ui/Atoms/Input';
import useWatchError from '../../../../../../shared/hooks/useWatchError';
import useErrorMessage from '../../../../../../shared/hooks/useErrorMessage';
import Button from '../../../../../../theme/ui/Atoms/Button';
import deviceChangePasswordAPI from '../../../../../../service/deviceChangePassword/deviceChangePasswordReduxAPI';

const StyledWhiteSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  fill: theme.palette.text.primary,
}));

export default function ManagerChangePassword() {
  const { t } = useTranslation();
  const { storeId } = useParams();
  const navigate = useNavigate();
  const errorMessage = useErrorMessage();
  const [updatePassword, { isLoading: isLoadingUpdate, error }] =
    deviceChangePasswordAPI.useSetNewPasswordMutation();

  useWatchError(error);

  const validationSchema = useMemo(
    () =>
      yup.object({
        newPassword: yup
          .string()
          .required(t('change_password.new_password_required') ?? ''),
        confirmPassword: yup
          .string()
          .required(t('change_password.confirm_password_required') ?? '')
          .oneOf(
            [yup.ref('newPassword'), null],
            t('change_password.confirm_password_is_not_the_same') ?? ''
          ),
      }),
    []
  );

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      return updatePassword({
        groupId: storeId ?? '',
        newPassword: values.newPassword,
      })
        .unwrap()
        .then(onClickBack)
        .catch((error) =>
          errorMessage(t('change_password.error', { message: error.message }))
        );
    },
  });

  const onClickBack = () => {
    navigate(`../actions`);
  };

  return (
    <Box
      sx={{
        height: '100%',
        maxHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      component={'form'}
      onSubmit={formik.handleSubmit}
    >
      <Box
        className="change-password-header"
        sx={{
          paddingX: 4,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="subtitle2"
          marginY={2}
          sx={{
            fill: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box component={'span'} marginRight={4}>
            <StyledWhiteSettingsIcon height={'2em'} />
          </Box>
          {t('change_password.title')}
        </Typography>
      </Box>

      <Box
        className="change-password-content"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: { sm: 'initial', md: 'center' },
          paddingX: 4,
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {isLoadingUpdate ? null : (
          <Container maxWidth="sm">
            <Paper elevation={5} sx={{ padding: 3 }}>
              <Input
                id="newPassword"
                name="newPassword"
                sx={{ mt: 2, mb: 2 }}
                label={t('change_password.new_password') ?? ''}
                placeholder={
                  t('change_password.new_password_placeholder') ?? ''
                }
                required
                aria-required
                fullWidth
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  (formik.touched.newPassword && formik.errors.newPassword) ||
                  ''
                }
              />
              <Input
                id="confirmPassword"
                name="confirmPassword"
                sx={{ mt: 2, mb: 2 }}
                label={t('change_password.confirm_password') ?? ''}
                placeholder={
                  t('change_password.confirm_password_placeholder') ?? ''
                }
                required
                aria-required
                fullWidth
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  (formik.touched.confirmPassword &&
                    formik.errors.confirmPassword) ||
                  ''
                }
              />

              <Button
                variant="contained"
                size="large"
                rounded
                sx={{ my: 2, mt: 4 }}
                type="submit"
                loading={formik.isSubmitting}
                fullWidth
              >
                {t('button.save')}
              </Button>
            </Paper>
          </Container>
        )}
      </Box>
      <Box
        className="change-password-footer"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 2,
          paddingX: 4,
        }}
      >
        <Button
          variant="contained-gray"
          sx={{ height: '3em', marginLeft: 2 }}
          onClick={onClickBack}
          disabled={isLoadingUpdate}
        >
          <ArrowBackIcon height={'1em'} />
        </Button>
      </Box>
    </Box>
  );
}
