import moment from 'moment';
import { MenuItemResponse, TimerItemRequest } from '../ZoneService.model';

/**
 * from MenuItemResponse to TimerItemRequest using the date now
 * @param menuItem
 * @return <TimerItemRequest>
 */
const serializeMenuItemToTimer = (
  menuItem: MenuItemResponse
): TimerItemRequest => {
  return {
    menuItemId: menuItem.itemName,
    timeEnd: moment().add(menuItem.holdTime, 'seconds').unix(),
    timeStart: moment().unix(),
    timePrepAlarm: moment()
      .add(menuItem.holdTime - menuItem.prepTime, 'seconds')
      .unix(),
  };
};

export default serializeMenuItemToTimer;
