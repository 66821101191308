import { createApi } from '@reduxjs/toolkit/query/react';
import { GroupAPIResponse } from './group.model';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import getGroupsListFromGroupsTree from './serialize/getGroupsListFromGroupsTree';

const GroupAPI = createApi({
  reducerPath: 'groups',
  baseQuery: axiosBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getGroupsList: build.query<GroupAPIResponse[], void>({
        query: () => ({ url: 'groups/', method: 'get' }),
        transformResponse: (response: GroupAPIResponse[]) => {
          return getGroupsListFromGroupsTree(response);
        },
      }),
    };
  },
});

export default GroupAPI;

export const { useGetGroupsListQuery } = GroupAPI;
