import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import LogoConnectedByIcon from '../../Atoms/Icons/LogoConnectedByIcon';

export default function Footer(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        margin: 2,
        marginX: 3,
        display: 'flex',
        justifyContent: 'flex-end',
        ...props.sx,
      }}
    >
      <LogoConnectedByIcon height={'4rem'} />
    </Box>
  );
}
