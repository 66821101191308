import React from 'react';
import { SettingItem } from './SettingItem';
import { TemperatureIcon } from '../../../assets';
import {
  DEFAULT_TEMPERATURE_UNIT,
  TEMPERATURE_UNIT_TYPES,
} from '../../../service/temperaturePreference';
import { useTranslation } from 'react-i18next';
import Switch from '../../../theme/ui/Atoms/Switch';
import StoreAPI from '../../../service/store/StoreAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { Box, CircularProgress } from '@mui/material';
import useErrorMessage from '../../../shared/hooks/useErrorMessage';

function TemperatureSettingItem() {
  const { t } = useTranslation();
  const {
    isLoading: isLoadingStoreInfo,
    isFetching: isFetchingStoreInfo,
    data: storeInfo,
    error: errorStoreInfo,
  } = StoreAPI.useGetStoreInfoDetailsQuery();
  useWatchError(errorStoreInfo);

  const temperaturePreference =
    storeInfo?.preferredUnit || DEFAULT_TEMPERATURE_UNIT;
  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${temperaturePreference}`
  );
  const [
    setStoreTemperaturePreference,
    { isLoading: isLoadingUpdateTemperaturePreference },
  ] = StoreAPI.useUpdateTemperaturePreferenceMutation();
  const errorMessage = useErrorMessage();
  const toggleOperatorView = () => {
    const newTemperaturePreference =
      temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS
        ? TEMPERATURE_UNIT_TYPES.FAHRENHEIT
        : TEMPERATURE_UNIT_TYPES.CELSIUS;
    setStoreTemperaturePreference(newTemperaturePreference)
      .unwrap()
      .catch(errorMessage);
  };
  let action = (
    <Switch
      checked={temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS}
      onChange={toggleOperatorView}
      label={temperaturePreferenceLabel}
    />
  );

  if (
    isLoadingStoreInfo ||
    isLoadingUpdateTemperaturePreference ||
    isFetchingStoreInfo
  ) {
    action = (
      <Box textAlign="center">
        <CircularProgress size={'2.75em'} />
      </Box>
    );
  }
  return (
    <SettingItem
      IconComponent={TemperatureIcon}
      title={t('settings.temperature_preference.title')}
      action={action}
    />
  );
}

export default TemperatureSettingItem;
