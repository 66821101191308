import { GroupAPIResponse } from '../group.model';

export default function getGroupsListFromGroupsTree(
  groups: GroupAPIResponse[] | null,
  individualGroup?: GroupAPIResponse | undefined
): GroupAPIResponse[] {
  let result: GroupAPIResponse[] = [];

  // add ids from individualGroup
  if (individualGroup) {
    result = [...result, individualGroup];
  }

  // add ids from nested groups
  if (groups) result = [...result, ...groups];

  // add ids from child groups
  const groupsChild =
    groups?.map((group) => getGroupsListFromGroupsTree(group.childGroups)) ??
    [];
  result = [...result, ...groupsChild.flat()];

  return result;
}
