import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { ScheduleAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import { EditIcon, TrashIcon } from '../../../../../../../assets';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import DayPartsDisplay from '../DayPart/DayPartDiplay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import getWeekDaysNotEmpty from '../../../../../../../service/schedule/util/getWeekDaysNotEmpty';

const widthTotal =
  300 -
  // padding
  24 * 2;
const widthEditIcon = 40;
const widthDeleteIcon = 40;

interface ScheduleItemProps {
  item: ScheduleAPIResponse;
  onEdit: () => void;
  onDelete: () => void;
}

const ScheduleItem = (props: ScheduleItemProps) => {
  const { item, onEdit, onDelete } = props;
  const { t } = useTranslation();
  const { regionId } = useParams<{ regionId: string }>();
  const {
    isFetching: isFetchingZoneSettings,
    isLoading: isLoadingZoneSettings,
    error: errorZoneSettings,
    data: zoneSettings = [],
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '');
  const {
    error: errorDayParts,
    isLoading: isLoadingDayParts,
    isFetching: isFetchingDayParts,
    data: dayParts = [],
  } = ScheduleAPI.useGetDayPartByRegionQuery(regionId ?? '');

  useWatchError(errorZoneSettings ?? errorDayParts);

  return (
    <Grid
      container
      item
      sx={{ marginY: 1, minWidth: widthTotal, alignItems: 'center' }}
    >
      <Accordion sx={{ flexGrow: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListItemText
            primary={item.scheduleName}
            secondary={`${t('schedule.settings.schedule.n_zones', {
              count: item.zoneCount,
            })}, ${t('schedule.settings.schedule.n_weekdays', {
              count: getWeekDaysNotEmpty(item.schedule).length,
            })}`}
          />
        </AccordionSummary>
        <AccordionDetails>
          <DayPartsDisplay
            zoneSettings={zoneSettings}
            schedule={item.schedule}
            dayParts={dayParts}
            loading={
              isFetchingZoneSettings ||
              isLoadingZoneSettings ||
              isFetchingDayParts ||
              isLoadingDayParts
            }
          />
        </AccordionDetails>
      </Accordion>

      <Grid item width={widthEditIcon}>
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item width={widthDeleteIcon}>
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function ScheduleListPage() {
  const { regionId } = useParams<{ regionId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error: errorZoneSettings } =
    ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '');
  const { error: errorDayParts } = ScheduleAPI.useGetDayPartByRegionQuery(
    regionId ?? ''
  );
  const {
    error,
    isLoading,
    isFetching,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByRegionQuery(regionId ?? '', {
    refetchOnMountOrArgChange: true,
  });
  const [deleteSchedule] = ScheduleAPI.useDeleteScheduleByRegionMutation();
  useWatchError(error ?? errorZoneSettings ?? errorDayParts);
  const errorMessage = useErrorMessage();
  const onDelete = (item: ScheduleAPIResponse) => async () => {
    await deleteSchedule({
      regionId: regionId ?? '',
      scheduleId: item.scheduleId,
    })
      .unwrap()
      .catch(errorMessage);
  };
  const onEdit = (item: ScheduleAPIResponse) => () => {
    navigate(`./${item.scheduleId}`);
  };

  if (!schedules) return null;
  const scheduleNewLink = `/manager/region/${regionId}/schedule-settings/schedule/new`;

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1" m={0}>
          {t('schedule.settings.schedule.list')}
        </Typography>
        <Button
          variant="contained"
          rounded
          sx={{ m: 2 }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore forward params not working in TS
          to={scheduleNewLink}
          component={RouterLink}
        >
          {t('schedule.settings.schedule.new')}
        </Button>
      </Box>

      <Divider sx={{ mb: 2 }} />
      <Grid container item>
        {/* <Header /> */}

        {isLoading || isFetching ? (
          <CircularProgress />
        ) : (
          schedules.map((item) => (
            <ScheduleItem
              key={item.scheduleId}
              item={item}
              onEdit={onEdit(item)}
              onDelete={onDelete(item)}
            />
          ))
        )}
      </Grid>
    </Container>
  );
}

export default ScheduleListPage;
