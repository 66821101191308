import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { DayPartAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import getOptionsFromZoneSettingsWithDefaults from '../../../../../../../service/zoneSettings/serialize/getOptionsFromZoneSettingsWithDefaults';
import ZonesSettingsString from '../ZoneSettingsSmall/ZonesSettingsString';
import { EditIcon, TrashIcon } from '../../../../../../../assets';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';

const widthTotal =
  900 -
  // padding
  24 * 2;
const widthDisplayName = 350;
const widthNZonses = 80;
const widthEditIcon = 40;
const widthDeleteIcon = 40;
const widthShelfSettings =
  widthTotal -
  widthDisplayName -
  widthNZonses -
  widthEditIcon -
  widthDeleteIcon;

const TableHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      item
      sx={{ marginY: 2, ...theme.typography.h3, fontSize: '1.4rem' }}
    >
      <Grid item width={widthDisplayName} sx={{ pl: 2, margin: 'auto' }}>
        {t('schedule.settings.day_part.displayName_header')}
      </Grid>
      <Grid item width={widthNZonses} sx={{ textAlign: 'center' }}>
        {t('schedule.settings.day_part.numberZones_header')}
      </Grid>
      <Grid item width={widthShelfSettings} sx={{ margin: 'auto' }}>
        {t('schedule.settings.day_part.shelfSettings')}
      </Grid>
      <Grid item width={widthEditIcon}>
        {/* empty */}
      </Grid>
      <Grid item width={widthDeleteIcon}>
        {/* empty */}
      </Grid>
    </Grid>
  );
};

interface DayPartItemProps {
  item: DayPartAPIResponse;
  onEdit: () => void;
  onDelete: () => void;
}

const DayPartItem = (props: DayPartItemProps) => {
  const { item, onEdit, onDelete } = props;
  const { regionId } = useParams<{ regionId: string }>();
  const {
    isFetching,
    isLoading,
    error,
    data: zoneSettings,
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '', {
    refetchOnMountOrArgChange: true,
  });

  useWatchError(error);
  const optionsZoneSettings = useMemo(
    () => getOptionsFromZoneSettingsWithDefaults(zoneSettings),
    [zoneSettings]
  );
  return (
    <Grid container item sx={{ marginY: 1, minWidth: widthTotal }}>
      <Grid
        container
        item
        component={Paper}
        width={'auto'}
        sx={{ minHeight: 43, paddingY: 1 }}
      >
        <Grid item width={widthDisplayName} sx={{ pl: 2 }}>
          {item.displayName}
        </Grid>
        <Grid item width={widthNZonses} sx={{ textAlign: 'center' }}>
          {item.shelfSettings.length}
        </Grid>
        <Grid item width={widthShelfSettings} sx={{ pr: 2 }}>
          <ZonesSettingsString
            shelfSettings={item.shelfSettings}
            optionsZoneSettings={optionsZoneSettings}
            loading={isFetching || isLoading}
          />
        </Grid>
      </Grid>
      <Grid item width={widthEditIcon}>
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item width={widthDeleteIcon}>
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function DayPartListPage() {
  const { regionId } = useParams<{ regionId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { error: errorZoneSettings } =
    ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '');
  const {
    error,
    isLoading,
    isFetching,
    data: dayParts,
  } = ScheduleAPI.useGetDayPartByRegionQuery(regionId ?? '', {
    refetchOnMountOrArgChange: true,
  });
  const [deleteDayPart] = ScheduleAPI.useDeleteDayPartByRegionMutation();
  useWatchError(error ?? errorZoneSettings);
  const errorMessage = useErrorMessage();
  const onDelete = (item: DayPartAPIResponse) => async () => {
    await deleteDayPart({ regionId: regionId ?? '', dayPartId: item.id })
      .unwrap()
      .catch(errorMessage);
  };
  const onEdit = (item: DayPartAPIResponse) => () => {
    navigate(`./${item.id}`);
  };
  const dayPartNewLink = `/manager/region/${regionId}/schedule-settings/day-part/new`;

  if (!dayParts) return null;
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1" m={0}>
          {t('schedule.settings.day_part.list')}
        </Typography>
        <Button
          variant="contained"
          rounded
          sx={{ m: 2 }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore forward params not working in TS
          to={dayPartNewLink}
          component={RouterLink}
        >
          {t('schedule.settings.day_part.new')}
        </Button>
      </Box>
      <Divider />
      <Grid container item sx={{ width: 'fit-content' }}>
        <TableHeader />

        {isLoading || isFetching ? (
          <CircularProgress />
        ) : (
          dayParts.map((item) => (
            <DayPartItem
              key={item.id}
              item={item}
              onEdit={onEdit(item)}
              onDelete={onDelete(item)}
            />
          ))
        )}
      </Grid>
    </Container>
  );
}

export default DayPartListPage;
