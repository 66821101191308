import React from 'react';
import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface ModalInfoProps extends MUIDialogProps {
  open: boolean;
  title?: any;
  description?: React.ReactNode;
  buttonActions?: React.ReactNode;
}

export function ModalInfo(props: ModalInfoProps) {
  const { title, description, buttonActions, children, open, ...dialogProps } =
    props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MUIDialog
      {...dialogProps}
      open={!!open}
      fullScreen={fullScreen}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
    >
      <DialogTitle id="info-dialog-title">{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions sx={{ margin: 1 }}>{buttonActions}</DialogActions>
    </MUIDialog>
  );
}

export default ModalInfo;
