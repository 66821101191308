import { CUTranslationState } from '../../redux/CUTranslation/slice/CUTranslationSlice';
import { ControlUnitAPIResponse } from '../../service/controlUnit/ControlUnit.model';
import {
  ControlUnitTranslationAPIResponse,
  TranslationCommanderObject,
  TranslationControlUnitObject,
} from '../../service/CUTranslation/CUTranslation.model';

/** function to get translation from dictionaries inspired by ST-Studio does */
export const getTranslateFunction =
  (
    translations: Record<
      ControlUnitAPIResponse['id'],
      ControlUnitTranslationAPIResponse | undefined
    > = {},
    controlUnitIdParam: string | number = ''
  ) =>
  (
    keyTranslation: string,
    controlUnitId: string | number = controlUnitIdParam,
    // Always would search in CommanderTranslations first, then in the type translation
    type: 'ram' | 'parameters' = 'ram',
    keyTranslationObject:
      | keyof TranslationCommanderObject
      | keyof TranslationControlUnitObject = 'shortText',
    // return keyTranslation if not found
    fallback = true,
    recursion = false
  ): string | { id: string; text: string }[] => {
    // get from commanderTranslations
    const commanderTranslations =
      translations[controlUnitId]?.translations?.commanderTranslation ??
      Object.values(translations)[0]?.translations?.commanderTranslation ??
      [];
    const textCommanderTranslations = commanderTranslations.find(
      (commanderTranslation) => commanderTranslation.key === keyTranslation
    )?.[keyTranslationObject as keyof TranslationCommanderObject];
    if (textCommanderTranslations) return textCommanderTranslations;

    // get from RAM or PARAMS
    const ramTranslations =
      translations[controlUnitId]?.translations?.ramValues ?? {};
    const paramTranslations =
      translations[controlUnitId]?.translations?.parameterTranslations ?? {};

    let text: string | { id: string; text: string }[] | null | undefined = '';
    switch (type) {
      case 'parameters':
        text =
          paramTranslations[keyTranslation]?.[
            keyTranslationObject as keyof TranslationControlUnitObject
          ];
        break;

      case 'ram':
        text =
          ramTranslations[keyTranslation]?.[
            keyTranslationObject as keyof TranslationControlUnitObject
          ];
        break;

      default:
        break;
    }
    if (text?.length) return text;

    // Try to find the value with the equality, because some times F01 has a real name like H11
    const valuesTranslations =
      translations[controlUnitId]?.translations?.commanderValues ?? ({} as any);
    const equalityText = valuesTranslations[keyTranslation];
    if (equalityText && !recursion)
      return getTranslateFunction(translations)(
        keyTranslation,
        controlUnitId,
        type,
        keyTranslationObject,
        fallback,
        true
      );
    return keyTranslation;
  };
export const getTranslateManyDevicesFunction =
  (translationsState: CUTranslationState['dictionaryCUTranslation'] = {}) =>
  (
    keyTranslation: string,
    deviceId: string,
    controlUnitId: string | number = '',
    // Always would search in CommanderTranslations first, then in the type translation
    type: 'ram' | 'parameters' = 'ram',
    keyTranslationObject:
      | keyof TranslationCommanderObject
      | keyof TranslationControlUnitObject = 'shortText',
    // return keyTranslation if not found
    fallback = true,
    recursion = false
  ): string | { id: string; text: string }[] => {
    const translations = translationsState[deviceId] ?? {};
    // get from commanderTranslations
    const commanderTranslations =
      translations[controlUnitId]?.translations?.commanderTranslation ??
      Object.values(translations)[0]?.translations?.commanderTranslation ??
      [];
    const textCommanderTranslations = commanderTranslations.find(
      (commanderTranslation) => commanderTranslation.key === keyTranslation
    )?.[keyTranslationObject as keyof TranslationCommanderObject];
    if (textCommanderTranslations) return textCommanderTranslations;

    // get from RAM or PARAMS
    const ramTranslations =
      translations[controlUnitId]?.translations?.ramValues ?? {};
    const paramTranslations =
      translations[controlUnitId]?.translations?.parameterTranslations ?? {};

    let text: string | { id: string; text: string }[] | null | undefined = '';
    switch (type) {
      case 'parameters':
        text =
          paramTranslations[keyTranslation]?.[
            keyTranslationObject as keyof TranslationControlUnitObject
          ];
        break;

      case 'ram':
        text =
          ramTranslations[keyTranslation]?.[
            keyTranslationObject as keyof TranslationControlUnitObject
          ];
        break;

      default:
        break;
    }
    if (text?.length) return text;

    // Try to find the value with the equality, because some times F01 has a real name like H11
    const valuesTranslations =
      translations[controlUnitId]?.translations?.commanderValues ?? ({} as any);
    const equalityText = valuesTranslations[keyTranslation];
    if (equalityText && !recursion)
      return getTranslateFunction(translations)(
        keyTranslation,
        controlUnitId,
        type,
        keyTranslationObject,
        fallback,
        true
      );
    return keyTranslation;
  };
