import moment from 'moment';
import {
  DigitalEventsControlUnits,
  DigitalChannelEvent,
} from '../monitoring.model';
import { ShowLevelOption } from '../../../redux/groups/selectors/getLevelOfView';
import { getLabelName } from './getLabelName';
import { ControlUnitState } from '../../../redux/controlUnit/slice/controlUnitSlice';
import { getEventMetadataFromControlUnitState } from '../../../redux/controlUnit/selectors/events';
import { mapDigitalEvent } from './mapDigitalEvent';
import { STTimeLineChartData } from '../../../pages/AuthenticatedPages/Manager/ManagerPerformances/monitoring.model';
import { getTranslateManyDevicesFunction } from '../../../redux/CUTranslation/getTranslateFunction';

/**
 * mapping API EventDigitalChannels to data from ST-components CharTimeLine
 * @param {DigitalEventsControlUnits} listControlUnits listOfControlUnits from a commander
 * @returns {STTimeLineChartData}
 */
export const mapEventsDigitalToSTCharTimeLine = (
  listControlUnits: DigitalEventsControlUnits[],
  dateMin: number,
  dateMax: number,
  CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
  showLevelOption: ShowLevelOption,
  controlUnitState: ControlUnitState
): STTimeLineChartData => {
  const dic = listControlUnits
    // Map with device control event name
    .flatMap((controlUnitEvents) => {
      const { events, ...otherInfo } = controlUnitEvents;

      return events.map((event) => {
        const textTranslation = CUsTranslate(
          event.name ?? event.eventID,
          otherInfo.deviceId,
          otherInfo.idCU
        ) as string;
        const name = getLabelName(
          showLevelOption,
          textTranslation,
          otherInfo.cuName,
          otherInfo.deviceName || ''
        );
        const id = `${otherInfo.deviceId}-${otherInfo.idCU}-${event.eventID}`;
        const metadata = getEventMetadataFromControlUnitState(
          controlUnitState.dictionaryControlUnit,
          otherInfo.deviceId,
          otherInfo.idCU,
          event.eventID
        );
        return {
          ...event,
          ...otherInfo,
          value: mapDigitalEvent(event.value, metadata),
          valueOld: mapDigitalEvent(event.valueOld, metadata),
          textTranslation,
          name,
          id,
          eventId: event.eventID,
          deviceId: otherInfo.deviceId,
          controlUnitId: otherInfo.idCU,
        };
      });
    })
    // reduce the events with tha same origin device control eventId
    .reduce((accumulatedDictionary, currentEvent) => {
      if (!accumulatedDictionary[currentEvent.id])
        accumulatedDictionary[currentEvent.id] = {
          name: currentEvent.name,
          id: currentEvent.id,
          data: [],
          dataList: [],
          eventId: currentEvent.eventID,
          deviceId: currentEvent.deviceId,
          controlUnitId: currentEvent.idCU,
          controlUnitName: currentEvent.cuName,
          textTranslation: currentEvent.textTranslation as string,
        };
      accumulatedDictionary[currentEvent.id].dataList?.push(currentEvent);

      return accumulatedDictionary;
    }, {} as STTimeLineChartData);
  Object.values(dic).forEach((item) => {
    item.data = eventsToRangeEventsWithoutDisable(
      item.dataList ?? [],
      dateMin,
      dateMax
    );

    delete item.dataList;
  });

  return dic;
};

export interface DataValue {
  /** Date on unix format*/
  timestamp: number;
  value: 0 | 1;
  /** ValueOld is used to know if need a start event or not */
  valueOld: 0 | 1;
}

export interface RangeDataValue {
  /** Date on unix format*/
  start: number;
  /** Date on unix format*/
  end: number;
  fakeEnd?: boolean;
  fakeStart?: boolean;
  value: DigitalChannelEvent['value'];
}

export function eventsToRangeEventsWithoutDisable(
  listData: DigitalChannelEvent[],
  dateMin: number,
  dateMax: number
) {
  const rangeEvents = eventsToRangeEvents(listData, dateMin, dateMax);

  // eslint-disable-next-line eqeqeq
  return rangeEvents.filter((itemValue) => itemValue.value != '0');
}

/**
 * Map list of DataValue to list of RangeDataValue
 * @param listData
 * @param precision
 * @returns
 */
function eventsToRangeEvents(
  listData: DigitalChannelEvent[],
  dateMin: number,
  dateMax: number
): RangeDataValue[] {
  const rangesValues = listData.reduce((listEventRange, itemEvent, index) => {
    // one event
    // if (listData.length === 1) {
    //   return [{ start: dateMin, end: dateMax, value: itemEvent.value }];
    // }

    // first item
    if (index === 0) {
      //** if the first event has changed value previously use limit of the startPoint */
      if (itemEvent.valueOld !== itemEvent.value) {
        listEventRange.push({
          start:
            dateMin < itemEvent.timestamp ? dateMin : itemEvent.timestamp - 1,
          end: itemEvent.timestamp,
          value: itemEvent.valueOld,
          fakeStart: true,
        });
      }
      listEventRange.push({
        start: itemEvent.timestamp,
        end: 0,
        value: itemEvent.value,
        fakeStart: itemEvent.timestamp === dateMin,
      });
    }

    const previousEvent = listData[index - 1] ?? {
      value: itemEvent.valueOld,
      timestamp: 0,
    };
    let lastEventRange = listEventRange[listEventRange.length - 1];

    // each item
    if (
      moment(previousEvent.timestamp).isSameOrBefore(itemEvent.timestamp) &&
      previousEvent.value !== itemEvent.value &&
      index !== 0
    ) {
      // Mutate last RangeDataValue with end
      lastEventRange.end = itemEvent.timestamp;
      // Create next RangeDataValue
      lastEventRange = {
        start: itemEvent.timestamp,
        value: itemEvent.value,
        end: 0,
      };
      listEventRange.push(lastEventRange);
    }

    /** last item
     * Use dateMax to close the las RangeDataValue
     */
    if (index === listData.length - 1) {
      lastEventRange.end =
        dateMax > lastEventRange.start ? dateMax : lastEventRange.start + 1;
      lastEventRange.fakeEnd = true;
    }

    return listEventRange;
  }, [] as RangeDataValue[]);

  return rangesValues;
}

export default eventsToRangeEvents;
