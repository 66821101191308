import { createSelector } from '@reduxjs/toolkit';
import { emptyArray } from '../../shared/ArrayUtil';
import StoreAPI from './StoreAPI';
import { DevicesStoreInfoResponse } from './StoreService.model';
import { RootState } from '../../redux/store.model';

export const getStoreDetailsSelector =
  StoreAPI.endpoints.getStoreInfoDetails.select();

export const getAllDevicesFromStoreSelector = createSelector(
  [StoreAPI.endpoints.getStoreInfoDetails.select()],
  (storeInfoDetails) =>
    storeInfoDetails.data?.devices ?? (emptyArray as DevicesStoreInfoResponse[])
);

export const getAllDevicesIdsFromStoreSelector = createSelector(
  [StoreAPI.endpoints.getStoreInfoDetails.select()],
  (storeInfoDetails) =>
    storeInfoDetails.data?.devices.map((device) => device.uuid) ??
    (emptyArray as string[])
);

export const getStoreTemperaturePreference = (state: RootState) =>
  getStoreDetailsSelector(state).data?.preferredUnit;

export const getStoreNameSelector = (state: RootState) =>
  getStoreDetailsSelector(state).data?.storeName;

export const getDeviceByIDFromStoreSelector = (
  state: RootState,
  props: { deviceId: string }
) => {
  const { deviceId } = props;
  return getAllDevicesFromStoreSelector(state)?.find(
    (device) => device.uuid === deviceId
  );
};

export const getDeviceByIDIsConnectedFromStoreSelector = createSelector(
  [getDeviceByIDFromStoreSelector],
  (device) => !!device?.deviceState.mqttConnected
);

export const getZonesStateSelector = (state: RootState) => state.zone;
