import React from 'react';
import {
  POSSIBLE_ALARMS,
  ZoneResponse,
} from '../../../../service/store/modules/zone/ZoneService.model';
import { ReactComponent as PowerIcon } from '../../../../assets/power.svg';
import { ReactComponent as LightIcon } from '../../../../assets/light.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/error.svg';
import { ReactComponent as DisconnectedIcon } from '../../../../assets/disconnected.svg';
import Button, { ButtonProps } from '../Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme, Theme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import {
  ErrorDoorOpenIcon,
  TempNotReachedIcon,
  TempTooHighIcon,
  TempTooLowIcon,
} from '../../../../assets';
import {
  TEMPERATURE_UNIT_TYPES,
  Temperature,
  useTemperaturePreference,
} from '../../../../service/temperaturePreference';

export type ZoneButtonProps = Omit<ButtonProps, 'variant'> & {
  zone: ZoneResponse;
  alert?: boolean;
  disconnected?: boolean;
  loading?: boolean;
};

export function getBackgroundColor(
  theme: Theme,
  disconnected?: boolean,
  error?: boolean,
  isOff?: boolean,
  alert?: boolean
) {
  if (disconnected) return '#303030';
  if (error) return '#E50E0E';
  if (isOff) return undefined;
  if (alert) return '#E50E0E';
  return theme.palette.mode === 'dark' ? '#EAEAEB' : '#FFF';
}

function getErrorIcon(zone: ZoneResponse) {
  const alarms = zone.shelfStatus.alarms ?? [];
  let firstCompatibleAlarm: POSSIBLE_ALARMS | 'DOOR_OPEN_ERROR' | undefined =
    alarms.find(
      (alarm) =>
        alarm === POSSIBLE_ALARMS.OVERTEMP ||
        alarm === POSSIBLE_ALARMS.UNDERTEMP ||
        alarm === POSSIBLE_ALARMS.SETPOINT_NOT_REACHED
    );
  // Add error case door open
  if (!firstCompatibleAlarm && zone.shelfStatus.doorState === 'ERROR') {
    firstCompatibleAlarm = 'DOOR_OPEN_ERROR';
  }

  switch (firstCompatibleAlarm) {
    case POSSIBLE_ALARMS.OVERTEMP:
      return {
        icon: <TempTooHighIcon height={35} />,
        text: 'timers.error_temperature_overtemp',
      };
    case POSSIBLE_ALARMS.UNDERTEMP:
      return {
        icon: <TempTooLowIcon height={35} />,
        text: 'timers.error_temperature_undertemp',
      };
    case POSSIBLE_ALARMS.SETPOINT_NOT_REACHED:
      return {
        icon: <TempNotReachedIcon height={35} />,
        text: 'timers.error_temperature_SetpointNotReached',
      };
    case 'DOOR_OPEN_ERROR':
      return {
        icon: <ErrorDoorOpenIcon height={35} />,
        text: 'timers.error_door_open',
      };
    default:
      return { icon: <ErrorIcon height={35} />, text: 'timers.error' };
  }
}
const getBackgroundColorFromZone = (props: ZoneButtonProps, theme: Theme) => {
  const { zone, alert, disconnected } = props;
  const isOff =
    zone.activeSetting === 'OFF' || zone.shelfStatus.deviceOn === false;
  const {
    shelfStatus: { operatingMode },
  } = zone;
  const error =
    operatingMode === 'ERROR' || operatingMode === 'ERROR_CU_MISMATCH';
  return getBackgroundColor(theme, disconnected, error, isOff, alert);
};

export function getBackgroundColorHover(
  theme: Theme,
  disconnected?: boolean,
  error?: boolean,
  isOff?: boolean,
  alert?: boolean
) {
  if (disconnected) return '#303030';
  if (error) return 'error.main';
  if (isOff) return undefined;
  if (alert) return '#CE0F0F';
  return theme.palette.mode === 'dark' ? '#DEDEDE' : '#DDD';
}
const getBackgroundColorHoverFromZone = (
  props: ZoneButtonProps,
  theme: Theme
) => {
  const { zone, alert, disconnected } = props;
  const isOff =
    zone.activeSetting === 'OFF' || zone.shelfStatus.deviceOn === false;
  const {
    shelfStatus: { operatingMode },
  } = zone;
  const error =
    operatingMode === 'ERROR' || operatingMode === 'ERROR_CU_MISMATCH';
  return getBackgroundColorHover(theme, disconnected, error, isOff, alert);
};

const ZoneButton = React.forwardRef(function ButtonRef(
  inProps: ZoneButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const {
    zone: { activeSetting, shelfStatus },
    disconnected,
    sx,
    loading,
    ...props
  } = inProps;

  const error =
    shelfStatus.operatingMode === 'ERROR' ||
    shelfStatus.operatingMode === 'ERROR_CU_MISMATCH';
  const theme = useTheme();
  const isOff = activeSetting === 'OFF' || shelfStatus.deviceOn === false;
  const variant = isOff && !disconnected ? 'contained' : 'contained-gray';

  return (
    <Button
      {...props}
      ref={ref}
      variant={variant}
      disabled={disconnected || props.disabled}
      sx={{
        height: 90,
        width: 130,
        borderRadius: 3,
        padding: error ? 0.5 : 1.5,
        backgroundColor: getBackgroundColorFromZone(inProps, theme),
        position: 'relative',
        '&:hover': {
          backgroundColor: getBackgroundColorHoverFromZone(inProps, theme),
        },
        ...sx,
      }}
    >
      <ZoneButtonContent {...inProps} />
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(1.5),
            left: theme.spacing(2.5),
            color: 'white',
          }}
        >
          <CircularProgress size={15} color={isOff ? 'inherit' : 'primary'} />
        </Box>
      )}
    </Button>
  );
});

const ZoneButtonContent = ({
  zone,
  disconnected,
  alert,
  loading,
}: ZoneButtonProps): JSX.Element => {
  const {
    activeSetting,
    shelfStatus: { deviceOn, operatingMode, temperatureSetpoint, alarms },
  } = zone;
  const { t } = useTranslation();
  const warning = operatingMode === 'TRANSITIONING';
  const error =
    operatingMode === 'ERROR' || operatingMode === 'ERROR_CU_MISMATCH';
  const isOff = activeSetting === 'OFF' || deviceOn === false;
  const isLightOnly = activeSetting === 'LIGHTS_ONLY';
  const temperaturePreference = useTemperaturePreference();
  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${temperaturePreference}`
  );
  const setpointValue = temperatureSetpoint;

  const bodyText =
    temperaturePreference === TEMPERATURE_UNIT_TYPES.FAHRENHEIT
      ? Temperature.convertCelsiusToFahrenheitRound(setpointValue)
      : Temperature.round(setpointValue);
  if (disconnected)
    return (
      <Box
        sx={{
          width: '100%',
          color: 'darkgray',
          stroke: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginX: 1,
          }}
        >
          <Circle disconnected={disconnected} warning={warning} />
          <Typography
            component={'span'}
            lineHeight={1}
            fontSize={18}
            fontWeight={600}
          ></Typography>
        </Box>
        <DisconnectedIcon height={25} />

        <Typography lineHeight={1} fontWeight={600}>
          {t('timers.disconnected')}
        </Typography>
      </Box>
    );
  if (error || alarms.length) {
    const { icon, text } = getErrorIcon(zone);
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          color: (theme) => theme.palette.error.main,
          stroke: (theme) => theme.palette.error.main,
          backgroundColor: '#F59F9F',
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {icon}
        <Typography
          lineHeight={1}
          fontWeight={600}
          marginTop={1}
          textTransform="uppercase"
          fontSize={'0.75em'}
        >
          {t(text)}
        </Typography>
      </Box>
    );
  }
  /** Impossible case, zone unused are hidden */
  if (operatingMode === 'UNUSED') {
    return <>UNUSED</>;
  }

  if (isOff) {
    return <PowerIcon height={60} />;
  }

  if (isLightOnly) {
    return <LightIcon height={60} />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        color: alert ? 'white' : 'black',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingX: 1,
        }}
      >
        {loading ? (
          <div></div>
        ) : (
          <Circle disconnected={disconnected} warning={warning} />
        )}

        <Typography
          component={'span'}
          lineHeight={1}
          fontSize={18}
          fontWeight={600}
        >
          {temperaturePreferenceLabel}
        </Typography>
      </Box>
      <Typography fontSize={50} lineHeight={1} fontWeight={600}>
        {bodyText}
      </Typography>
    </Box>
  );
};

const Circle = ({
  disconnected,
  warning,
}: {
  warning?: boolean;
  disconnected?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      className="Zone-status"
      title={`${t('timers.status')} ${
        disconnected
          ? t('timers.disconnected')
          : warning
          ? t('timers.status_warning')
          : t('timers.status_ok')
      }`}
      sx={{
        borderRadius: '10px',
        backgroundColor: 'white',
        height: (theme) => theme.spacing(2.2),
        width: (theme) => theme.spacing(2.2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0 3px 4px 0;',
      }}
    >
      <Box
        className="Zone-status"
        sx={{
          borderRadius: '10px',
          backgroundColor: disconnected
            ? 'gray'
            : warning
            ? 'warning.main'
            : 'success.main',
          height: (theme) => theme.spacing(1.8),
          width: (theme) => theme.spacing(1.8),
        }}
      ></Box>
    </Box>
  );
};
export default ZoneButton;
