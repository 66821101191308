/**
 * Example: 
```json
{
    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJhdWQiOiJodHRwczovL2NvbW1hbmRlci1jbG91ZC5ldS9hcGkiLCJzdWIiOiI5YjAxM2Q0MC0zMjk3LTRiYTctYWRiYS0yZjk4OTA4NDYwMDIiLCJzY29wZSI6IntcImRldmljZTo5YjAxM2Q0MC0zMjk3LTRiYTctYWRiYS0yZjk4OTA4NDYwMDJcIjpbXCJ6b25lLW9wZXJhdG9yXCJdLFwiKlwiOltcImF1dGhvcml6ZWQtdXNlclwiXX0iLCJpc3MiOiJmbGV4ZXNlcnZlLXNlcnZpY2VAY29tbWFuZGVyLWNsb3VkLmV1IiwiYWN0aXZlIjoidHJ1ZSIsImV4cCI6MTY2ODY0NjE1MCwiaWF0IjoxNjY4NjQ0MjkwLCJjbGllbnRfaWQiOiJjbGllbnRfZm05azZlMDRmcnY0MmxlczY5YmxhNmZhdGUifQ.H_8ULD3Y1tYrlF7JQ6GIBg4l3sacGfkJrB0dLQXEOfQOfBFSbLeoO8D7_SgqoCpyVXpYtF5AfD1HKJZH3L8N_g",
    "refresh_token": "f20c9d7972d50a84c9957598ca003db6",
    "expires_in": 1800
}
```
 */
export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}
export interface IAuthInfo {
  token: string;
  refreshToken: string;
  expiration: number;
}
export class AuthInfo {
  constructor(
    loginResponse: LoginResponse,
    public token = loginResponse.access_token,
    public refreshToken = loginResponse.refresh_token,
    public expiration = new Date().getTime() + loginResponse.expires_in * 1000
  ) {}
  getPayload(): IAuthInfo {
    return {
      token: this.token,
      refreshToken: this.refreshToken,
      expiration: this.expiration,
    };
  }
}
