import { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { ReactComponent as ChartIcon } from '../../../../assets/charts.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/arrow_back.svg';
import WidthRefResize from '../../../../shared/renderProps/WidthRefResize';
import { useMetrics } from './hooks/useMetrics';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { ComboCharts } from './ComboCharts';
import { ChartsFilters } from './ChartsFilters';
import Paper from '@mui/material/Paper';
import Button from '../../../../theme/ui/Atoms/Button';

const ChartIconStyled = styled(ChartIcon)(
  ({ theme }) => `
  stroke: ${theme.palette.text.primary};`
);

export default function ManagerPerformances() {
  const { t } = useTranslation();
  const { deviceId, groupId, controlUnitId } = useParams();
  const navigate = useNavigate();

  /** Init Monitoring */
  const {
    timeControls: { timeFrame, setTimeFrame },
    timeControls,
    analogControls,
    digitalControls,
    paletteColors,
    loading,
    metrics,
    error,
  } = useMetrics({ deviceId });
  useWatchError(error);

  /** View */
  const setTimeFrameSelection = (start: Moment, end: Moment) => {
    setTimeFrame({ start, end });
  };

  const onClickBack = () => {
    navigate(`../actions`);
  };

  return (
    <Box
      id="performances"
      sx={{
        height: '100%',
        maxHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        className="performances-header"
        sx={{
          width: '100%',
          paddingX: 4,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="subtitle2"
          marginY={2}
          sx={{
            fill: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box component={'span'} marginRight={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <ChartIconStyled height={'1.5em'} />
            )}
          </Box>
          {t('performance.title')}
        </Typography>
      </Box>

      <Box
        className="performances-content"
        sx={{
          width: '100%',
          paddingY: 2,
          paddingX: 4,
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <Box sx={{ width: '100%', paddingBottom: 2 }}>
          <ChartsFilters
            onChangeDatePicker={setTimeFrameSelection}
            groupId={groupId}
            deviceId={deviceId}
            controlUnitId={controlUnitId}
            analogControls={analogControls}
            digitalControls={digitalControls}
          />
          {!loading && !error ? (
            <PaperChart>
              <WidthRefResize
                render={(width) => (
                  <div>
                    <ComboCharts
                      paletteColors={paletteColors}
                      digitalChannels={metrics.digitalChannels}
                      sensordata={metrics.sensordata}
                      timeControls={timeControls}
                      timeFrame={timeFrame}
                      maxWidth={width - 128}
                    />
                  </div>
                )}
              />
            </PaperChart>
          ) : null}
        </Box>
      </Box>
      <Box
        className="performances-footer"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          marginY: 2,
          paddingX: 4,
        }}
      >
        <Button
          variant="contained-gray"
          sx={{ height: '3em' }}
          onClick={onClickBack}
        >
          <ArrowBackIcon height={'1em'} />
        </Button>
      </Box>
    </Box>
  );
}

const PaperChart = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
  backgroundImage: 'initial',
  paddingTop: theme.spacing(2),
  paddingX: theme.spacing(2),
}));
