import { Middleware } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

export const POST_REHYDRATE = 'persist/POST_REHYDRATE';
const postRehydrate: Middleware = (store) => (next) => (action) => {
  let result = next(action);
  if (action.type === REHYDRATE) result = next({ type: POST_REHYDRATE });
  return result;
};
export default postRehydrate;
