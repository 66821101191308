import React, { useCallback } from 'react';
import { TYPE_CHART_TIMELINE, Chart, UiMessage } from 'stoerk-ui-components';
import _ from 'lodash';
import { STTimeLineChartData, TimeFrame } from '../../monitoring.model';
import { useTranslation } from 'react-i18next';

interface DigitalChannelsProps {
  data: STTimeLineChartData;
  internalTimeFrame: TimeFrame;
  width: number;
  height: number;
  labels: any;
  controls: any[];
  paletteColors: string[];
}

// eslint-disable-next-line react/display-name
export const DigitalChannels = React.memo((props: DigitalChannelsProps) => {
  const { data, internalTimeFrame, labels, width, height, paletteColors } =
    props;
  const i18n = useTranslation();
  const getColorPalette = useCallback(() => paletteColors, [paletteColors]);

  const dataEmpty = _.isEmpty(data);
  if (dataEmpty)
    return (
      <UiMessage
        attached
        icon="warning"
        header=""
        content={i18n.t('device.monitoring.not_data_to_show_digital_channels')}
      />
    );

  return (
    <Chart
      data={data}
      type={TYPE_CHART_TIMELINE}
      // mode={mode}
      height={height}
      width={width}
      minDatetime={internalTimeFrame.start.unix()}
      maxDatetime={internalTimeFrame.end.unix()}
      zoom={true}
      language={i18n.i18n.language}
      labels={labels}
      getColorPalette={getColorPalette}
    />
  );
});
