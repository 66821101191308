import * as React from 'react';
import { useUID } from 'react-uid';
import { styled, useThemeProps } from '@mui/material/styles';
import composeClasses from '@mui/base/composeClasses';
import SwitchUnstyled, {
  switchUnstyledClasses,
  SwitchUnstyledOwnProps,
} from '@mui/base/SwitchUnstyled';
import Box, { BoxProps } from '@mui/system/Box';
import { merge } from 'lodash';
import { FormHelperText, FormLabel } from '@mui/material';

const useUtilityClasses = (props: SwitchContainerProps) => {
  const { error, ownerState } = props;
  const slots = {
    root: ['root', error && 'error'],
    container: [
      'container',
      error && 'error',
      ownerState?.checked && 'checked',
    ],
    label: ['label', error && 'error'],
    input: ['input', error && 'error'],
    helperText: ['helperText', error && 'error'],
  };

  const composedClasses = composeClasses(
    slots,
    (slot) => `FSSwitch-${slot}`,
    switchUnstyledClasses as any
  );

  return {
    ...switchUnstyledClasses, // forward the focused, disabled, etc. classes to the ButtonBase
    ...composedClasses,
  };
};
const ContainerRootCheckbox = styled(Box)(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  & .FSSwitch-label{
    margin: 10px;
  }
  &.${switchUnstyledClasses.checked} {
    .FSSwitch-label {
      color: ${theme.palette.primary.main};
    }
  }
}`
);
const RootCheckbox = styled('div')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[400]
    };
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${theme.palette.grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 20px;
      top: 4px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background-image: ${theme.palette.gradientColor.short};

    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }

  `
);
export interface SwitchProps extends SwitchUnstyledOwnProps {
  id?: HTMLDivElement['id'];
  label?: string | null;
  sx?: BoxProps['sx'];
  /** not supported yet */
  error?: boolean;
  helperText?: string;
}
const Switch = React.forwardRef(function FSSwitch(
  inProps: SwitchProps,

  ref: React.ForwardedRef<HTMLDivElement>
) {
  const props = useThemeProps({ props: inProps, name: 'FSSwitch' });
  const uniqueId = useUID();
  const id = props.id || `FSSwitch-${uniqueId}`;
  const slotProps = merge(props.slotProps, {
    input: { 'aria-label': 'Demo switch', id },
  });

  return (
    <SwitchUnstyled
      component={SwitchContainer}
      ref={ref}
      slotProps={slotProps}
      {...props}
    />
  );
});
interface SwitchContainerProps extends SwitchProps {
  children?: React.ReactNode;
  ownerState?: SwitchProps;
}
const SwitchContainer = React.forwardRef(function FSSwitchContainer(
  inProps: SwitchContainerProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const props = useThemeProps({ props: inProps, name: 'FSSwitch' });
  const classes = useUtilityClasses(props);

  return (
    <Box sx={props.sx}>
      <ContainerRootCheckbox className={classes.container}>
        <RootCheckbox {...props}>{props.children}</RootCheckbox>
        {props.label && (
          <FormLabel
            htmlFor={props.id}
            className={classes.label}
            required={props.ownerState?.required}
          >
            {props.label}
          </FormLabel>
        )}
      </ContainerRootCheckbox>
      {props.helperText && (
        <FormHelperText
          color={props.error ? 'error' : undefined}
          sx={{ lineHeight: '2rem' }}
          className={classes.helperText}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </Box>
  );
});

export default Switch;
