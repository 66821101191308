import { Box } from '@mui/material';
import React from 'react';
import MarketLogoImg from '../../../../../assets/Market_SQ_Logo.png';

function HeaderLogo() {
  return (
    <Box
      component={'img'}
      src={MarketLogoImg}
      alt="market logo"
      sx={{
        height: '3rem',
        marginLeft: 2,
        color: (theme) => theme.palette.text.primary,
      }}
    />
  );
}

export default HeaderLogo;
