import React, { useMemo } from 'react';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import { CircularProgress, Stack } from '@mui/material';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { useTranslation } from 'react-i18next';
import InputSelect from '../../../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import getOptionsFromZoneSettingsWithDefaults from '../../../../../../../service/zoneSettings/serialize/getOptionsFromZoneSettingsWithDefaults';
import {
  POSSIBLE_SHELF_ZONE_ID,
  ShelfSettingDayPartResponse,
} from '../../../../../../../service/schedule/ScheduleAPI.model';

interface ZoneSettingsSmallProps {
  values: ShelfSettingDayPartResponse[];
  onChange: (values: ShelfSettingDayPartResponse[]) => void;
  regionId?: string;
}
function ZoneSettingsSmall(props: ZoneSettingsSmallProps) {
  const { regionId, values, onChange } = props;
  const { t } = useTranslation();
  const {
    isFetching,
    isLoading,
    error,
    data: zoneSettings,
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '', {
    refetchOnMountOrArgChange: true,
  });
  const optionsZoneSettings = useMemo(
    () => getOptionsFromZoneSettingsWithDefaults(zoneSettings),
    [zoneSettings]
  );
  useWatchError(error);

  if (isLoading || isFetching) return <CircularProgress />;
  if (error) return <div>{t('error.unknown')}</div>;
  const handleChange = (index: number, value: string) => {
    const newValues = [...values];
    newValues[index] = {
      zoneId: `${index + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: value,
    };
    onChange(newValues);
  };

  return (
    <div>
      <Stack spacing={2}>
        {values.map((value, index) => (
          <InputSelect
            select
            key={index}
            id={`zone-settings-${index}`}
            name={`zone-settings-${index}`}
            label={`${t(`timers.zone`)} ${index + 1}`}
            options={optionsZoneSettings}
            value={value.setting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
              handleChange(index, value)
            }
            required
            aria-required
            fullWidth
          />
        ))}
      </Stack>
    </div>
  );
}

export default ZoneSettingsSmall;
