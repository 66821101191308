import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import { ReactComponent as ZoneSettingsIcon } from '../../../../../assets/zone_settings.svg';
import { ReactComponent as TimerIcon } from '../../../../../assets/timer.svg';
import { DayPartScheduleIcon, TemperatureIcon } from '../../../../../assets';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../ActionButton';
import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';

export default function ManagerRegionActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { regionId } = useParams();
  const { Schedule } = useAppSelector(getFeatureToggle);

  return (
    <Box display={'grid'} alignItems={'center'} height={'100%'}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <ActionButton
          text={t('zone_settings.title')}
          IconComponent={ZoneSettingsIcon}
          onClick={() => navigate(`/manager/region/${regionId}/zone-settings`)}
        />
        <ActionButton
          text={t('timer_settings.title')}
          IconComponent={TimerIcon}
          onClick={() => navigate(`/manager/region/${regionId}/timer-settings`)}
        />
        {!!Schedule && (
          <ActionButton
            text={t('schedule.settings.title')}
            IconComponent={DayPartScheduleIcon}
            onClick={() =>
              navigate(`/manager/region/${regionId}/schedule-settings`)
            }
          />
        )}
        <ActionButton
          text={t('settings.temperature_preference.title')}
          IconComponent={TemperatureIcon}
          onClick={() =>
            navigate(`/manager/region/${regionId}/temperature-preference`)
          }
        />
      </Stack>
    </Box>
  );
}
