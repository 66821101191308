import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceWithRequireUpdateOpeningTimes from '../../../Settings/OpeningTimes/useDeviceWithRequireUpdateOpeningTimes';
import { ModalInfo } from '../../../../../theme/ui/Atoms/Modal';
import Button from '../../../../../theme/ui/Atoms/Button';
import { useAppDispatch } from '../../../../../redux/store.model';
import { asyncTasksActions } from '../redux/slice/AsyncTasksSlice';
import DeviceOpeningTimeItem from '../../../Settings/OpeningTimes/DeviceOpeningTimeItem';

export function ChangeDetectorOpeningTimesUpdateModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    remoteOpeningTimes,
    devicesNeedRequired,
    devicesWithUpdateRequired,
    hasSomeDeviceDisconnected,
    openingTimesLoadingSet,
    updateOpiningTimesStore,
  } = useDeviceWithRequireUpdateOpeningTimes();

  const onClickConfirm = () => {
    if (remoteOpeningTimes?.storeOpeningTimes)
      updateOpiningTimesStore(remoteOpeningTimes?.storeOpeningTimes);
  };

  const onClickDismiss = () => {
    dispatch(
      asyncTasksActions.skipOpeningTimeUpdateRequired(
        devicesWithUpdateRequired.map(({ deviceId }) => deviceId)
      )
    );
  };
  return (
    <ModalInfo
      open={
        !!devicesNeedRequired.length && !!remoteOpeningTimes?.storeOpeningTimes
      }
      title={t('operator.modal_update.title')}
      description={
        <>
          <p>{t('opening_times.modal_update.description_force_update')}</p>
          <ul>
            {devicesWithUpdateRequired.map(({ device }) => (
              <DeviceOpeningTimeItem key={device?.uuid} device={device} />
            ))}
          </ul>
          {hasSomeDeviceDisconnected ? (
            <p>
              {t('opening_times.modal_update.description_action_disconnected')}
            </p>
          ) : null}
        </>
      }
      buttonActions={
        <>
          <Button
            autoFocus
            variant="text"
            onClick={onClickDismiss}
            disabled={openingTimesLoadingSet}
          >
            {t('operator.modal_update.dismiss_today')}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={onClickConfirm}
            loading={openingTimesLoadingSet}
          >
            {t('button.confirm')}
          </Button>
        </>
      }
    ></ModalInfo>
  );
}
