import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ViewLocationIcon } from '../../../../assets/view_location.svg';
import LocationAPI from '../../../../service/locations/LocationAPI';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { useTranslation } from 'react-i18next';
import ManagerViewRegion from './ManagerViewRegion';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../../../../service/store/Store.constants';

export default function ManagerViewLocations() {
  const { t } = useTranslation();
  const { isFetching, isLoading, data, error } =
    LocationAPI.useGetLocationsQuery(undefined, {
      refetchOnMountOrArgChange: true,
      pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
    });
  useWatchError(error);

  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: 2,
        paddingX: 2,
      }}
    >
      <Box
        className="view-location-header"
        sx={{
          width: '100%',
          display: 'flex',
        }}
      >
        <Typography
          variant="subtitle2"
          marginY={2}
          sx={{
            fill: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box component={'span'} marginRight={4}>
            {isLoading || isFetching ? (
              <CircularProgress />
            ) : (
              <ViewLocationIcon height={'2em'} />
            )}
          </Box>
          {t('view_locations.title')}
        </Typography>
      </Box>
      <Box
        className="view-location-content"
        sx={{
          width: 'fit-content',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={2}>
          {data?.regions.map((region) => (
            <ManagerViewRegion
              key={region.groupId}
              region={region}
              devices={data.devices}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
