import { ZoneSettingsResponse } from '../ZoneService.model';

/**
 * from zoneSettings from device get a list of options for select
 * @param device
 * @return zoneOptions
 */
export function getZoneOptions(
  zoneSettings: ZoneSettingsResponse | undefined
): { value: string; text: string }[] {
  if (zoneSettings) {
    return Object.keys(zoneSettings.configured)
      .filter((key) => zoneSettings.configured[key]?.visible)
      .map((key) => ({
        value: key,
        text: zoneSettings.configured[key]?.displayName || key,
      }));
  }
  return [];
}
