import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Paper,
  Stack,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../../../../../../../theme/ui/Atoms/Input/Input';
import { useTranslation } from 'react-i18next';
import RadioGroup from '../../../../../../../theme/ui/Atoms/RadioGroup/RadioGroup';
import ZoneSettingsSmall from '../ZoneSettingsSmall/ZoneSettingsSmall';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon } from '../../../../../../../assets';
import { ShelfSettingDayPartResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';

const validationSchema = yup.object({
  displayName: yup
    .string()
    .required('schedule.settings.day_part.displayName_required'),
  numberZones: yup
    .number()
    .required('schedule.settings.day_part.numberZones_required'),
  shelfSettings: yup.array(
    yup.object({
      setting: yup
        .string()
        .nullable()
        .required('schedule.settings.day_part.shelfSettings_required'),
    })
  ),
});
const clearInitialValues = {
  displayName: '',
  confirmable: true,
  numberZones: null as number | null | undefined,
  shelfSettings: [] as ShelfSettingDayPartResponse[],
};
const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

function DayPartNewPage() {
  const { t } = useTranslation();
  const { regionId } = useParams<{ regionId: string }>();
  const navigate = useNavigate();
  const [setDayPart] = ScheduleAPI.useSetDayPartByRegionMutation();
  const errorMessage = useErrorMessage();

  // Edit daypart
  const {
    error,
    isLoading,
    isFetching,
    data: dayParts,
  } = ScheduleAPI.useGetDayPartByRegionQuery(regionId ?? '');
  useWatchError(error);
  const { dayPartId } = useParams<{ dayPartId: string }>();
  const dayPart = useMemo(() => {
    const dayPart = dayParts?.find((dp) => dp.id === dayPartId);
    if (dayPart)
      return { ...dayPart, numberZones: dayPart.shelfSettings.length };
  }, [dayPartId, dayParts]);

  const formik = useFormik({
    initialValues: (dayPart ?? clearInitialValues) as typeof clearInitialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log('DayPartNewPage:onSubmit', values);

      return await setDayPart({ dayPart: values, regionId: regionId ?? '' })
        .unwrap()
        .then(onClickBack)
        .catch(errorMessage);
    },
  });

  const onClickBack = () => {
    const dayPartListLink = `/manager/region/${regionId}/schedule-settings/day-part`;
    navigate(dayPartListLink);
  };

  const handleChangeNumberZones = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberZones = Number(e.target.value);
    formik.setFieldValue('numberZones', numberZones);
    const arrayEmpty = new Array(numberZones).fill(null).map((v, i) => ({
      zoneId: `${i}`,
      setting: v,
    }));
    formik.setFieldValue('shelfSettings', arrayEmpty);
  };
  if (isLoading || isFetching)
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Input
            id={`displayName`}
            name={`displayName`}
            label={t(`schedule.settings.day_part.displayName`)}
            placeholder={t(
              `schedule.settings.day_part.displayName_placeholder`
            )}
            value={formik.values?.displayName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.displayName &&
                formik.errors.displayName &&
                t(formik.errors.displayName)
            )}
            helperText={
              formik.touched.displayName &&
              formik.errors.displayName &&
              t(formik.errors.displayName)
            }
            required
            aria-required
            fullWidth
          />

          {/* <FormControl component="fieldset">
            <FormLabel component="legend">
              {t('schedule.settings.day_part.confirmable')}
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values?.confirmable}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    input: { id: 'confirmable', name: 'confirmable' },
                  }}
                  helperText={
                    (formik.touched.confirmable && formik.errors.confirmable
                      ? t(formik.errors.confirmable)
                      : t(
                          'schedule.settings.day_part.confirmable_help_text'
                        )) ?? ''
                  }
                />
              }
              label=""
            />
          </FormControl> */}

          <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
            <RadioGroup
              label={t('schedule.settings.day_part.numberZones')}
              id={`numberZones`}
              name={`numberZones`}
              onChange={handleChangeNumberZones}
              onBlur={formik.handleBlur}
              value={formik.values?.numberZones}
              options={options}
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
            />
            <FormHelperText
              error={Boolean(
                formik.touched.numberZones && formik.errors.numberZones
              )}
            >
              {formik.touched.numberZones && formik.errors.numberZones
                ? t(formik.errors.numberZones)
                : t('schedule.settings.day_part.numberZones_help_text')}
              {}
            </FormHelperText>
          </Box>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              {t('schedule.settings.day_part.shelfSettings')}
            </FormLabel>
            {!!formik.values.shelfSettings.length && (
              <Paper sx={{ p: 2, mt: 1 }}>
                <ZoneSettingsSmall
                  regionId={regionId}
                  values={formik.values.shelfSettings}
                  onChange={(values) => {
                    formik.setFieldValue('shelfSettings', values);
                  }}
                />
              </Paper>
            )}
            <FormHelperText
              error={Boolean(
                formik.touched.shelfSettings && formik.errors.shelfSettings
              )}
            >
              {formik.touched.shelfSettings &&
                formik.errors.shelfSettings &&
                t(Object.values(formik.errors.shelfSettings)[0])}
            </FormHelperText>
          </FormControl>

          {/* debug form */}
          {/* <p style={{ width: 280, wordWrap: 'break-word' }}>
          {JSON.stringify({
            c: formik.submitCount,
            v: formik.values,
            e: formik.errors,
            t: formik.touched,
          })}
        </p> */}
        </Stack>

        <Box
          className="timer-settings-footer"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginY: 4,
          }}
        >
          <Box>
            <Button
              variant="contained-gray"
              sx={{ height: '3em' }}
              onClick={onClickBack}
              disabled={formik.isSubmitting}
            >
              <ArrowBackIcon height={'1em'} />
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            size="large"
            rounded
            disabled={formik.isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default DayPartNewPage;
