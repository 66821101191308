import React from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material/styles';
import themeDark from './dark';
import themeLight from './light';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { useAppSelector } from '../../redux/store.model';
import { getTheme } from '../../redux/features/selectors';
import {
  MODE_CLOUD_LIGHT,
  MODE_FLEXESERVE,
  setDefaultMode,
} from 'stoerk-ui-components';
import './fonts';

export default function ThemeProviderWithTheme({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme?: Theme;
}) {
  const themeMode = useAppSelector(getTheme);
  const themeLoaded = theme ?? (themeMode === 'dark' ? themeDark : themeLight);
  const mode = themeMode === 'dark' ? MODE_FLEXESERVE : MODE_CLOUD_LIGHT;
  setDefaultMode(mode);

  return (
    <StyledEngineProvider injectFirst>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <ThemeProvider theme={themeLoaded}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
}
