import { emptyArray } from '../../../../../shared/ArrayUtil';
import {
  CONFIRMATION_TYPE,
  DayPartAPIResponse,
  DayPartZipWithZoneSettings,
  ScheduleAPIResponse,
  ScheduleZipWithDayParts,
  WeekDaysISO,
} from '../../../../schedule/ScheduleAPI.model';
import produce from 'immer';
import { ZoneSettingsResponse } from '../../zone/ZoneService.model';
import { RootState } from '../../../../../redux/store.model';
import { createSelector } from '@reduxjs/toolkit';
import { getLastScheduleTaskStateSelector } from '../../../../../pages/AuthenticatedPages/Operator/AsyncTasks/redux/selectors';
import moment from 'moment';
import { timeToMoment } from '../../../../../shared/util/moment/parseTime';
import { getStoreDetailsSelector } from '../../../Store.selectors';
import { getDevicesWithZoneSettingsSelector } from '../../zone/selectors';

export const getSchedulesSelector = (state: RootState) =>
  getStoreDetailsSelector(state).data?.schedules ??
  (emptyArray as ScheduleAPIResponse[]);

export const getDayPartsSelector = (state: RootState) =>
  getStoreDetailsSelector(state).data?.dayparts ??
  (emptyArray as DayPartAPIResponse[]);

export const zipScheduleWithDayParts = (
  schedule: ScheduleAPIResponse | undefined,
  dayParts: DayPartZipWithZoneSettings[]
) => {
  return (
    schedule &&
    produce(schedule as ScheduleZipWithDayParts, (draft) => {
      Object.values(draft.schedule).forEach((scheduleDayParts) =>
        scheduleDayParts?.forEach((dayPart) => {
          dayPart.dayPart = dayParts.find((dp) => dp.id === dayPart.partId);
        })
      );
    })
  );
};

export const zipDayPartsWithZoneSettings = (
  dayParts: DayPartAPIResponse[],
  zoneSettingsResponse?: ZoneSettingsResponse
) => {
  return produce(dayParts as DayPartZipWithZoneSettings[], (draft) => {
    draft.forEach((dayPart) => {
      zoneSettingsResponse &&
        dayPart.shelfSettings.forEach((shelfSetting) => {
          shelfSetting.zoneSettings =
            zoneSettingsResponse.configured[shelfSetting.setting ?? ''];
        });
    });
  });
};

export const getDevicesWithSchedulesFromStoreSelector = createSelector(
  [
    getDevicesWithZoneSettingsSelector,
    getSchedulesSelector,
    getDayPartsSelector,
    getLastScheduleTaskStateSelector,
  ],
  (devices, schedules, dayParts, scheduleTasks) => {
    return (
      devices?.map(({ device, zoneSettings }) => {
        const schedule = schedules?.find(
          (schedule) => schedule.scheduleId === device?.schedule
        );

        return {
          device,
          scheduleObj: zipScheduleWithDayParts(
            schedule,
            zipDayPartsWithZoneSettings(dayParts, zoneSettings)
          ),
          scheduleTasks: scheduleTasks?.[device.uuid ?? ''] ?? [],
        };
      }) ?? []
    );
  }
);

export const getDevicesWithSchedulesActiveFromStoreSelector = createSelector(
  [getDevicesWithSchedulesFromStoreSelector],
  (devices) => {
    return (
      devices
        .filter(
          ({ device }) => device.deviceState?.mqttConnected && device.schedule
        )
        // magic filter with schedule
        .filter(getNextDayPartFromDevice)
    );
  }
);

const MINUTES_BEFORE = 30;
export const CONFIRMATIONS_CLOUD: Array<string | undefined> = [
  CONFIRMATION_TYPE.NOT_SPECIFIED,
  CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED,
  CONFIRMATION_TYPE.ANY_CONFIRMATION_REQUIRED,
];
/** this selector has effects for use moment, could be affected if past more than 1 day (hard) */
export function getNextDayPartFromDevice(
  composedDevice: ReturnType<typeof getDevicesWithSchedulesFromStoreSelector>[0]
) {
  // warning the operator should has the same timezone of the device
  const now = moment();
  const weekDaysISO = `${now.isoWeekday()}` as WeekDaysISO;
  const scheduleDayParts =
    composedDevice.scheduleObj?.schedule[weekDaysISO] ?? [];
  const nextDayPart = scheduleDayParts
    // remove the first day part,
    // because the device would switch on with the first day part
    .slice(1)
    // find the next day part that are bewteen -30 and 0 minutes from now
    // and not dismissed
    // and has confirmation type of cloud
    .find((dayPart) => {
      if (!CONFIRMATIONS_CLOUD.includes(dayPart.confirmationType)) return false;
      const momentDayPartStart = timeToMoment(dayPart.startTime);
      const isDismissed = composedDevice.scheduleTasks?.some(
        (task) =>
          task.partId === dayPart.partId &&
          task.scheduleId === composedDevice.device.schedule &&
          now.isBefore(moment(task.dismissUntil))
      );
      const diffMinutes = now.diff(momentDayPartStart, 'minutes', true);
      if (isDismissed) {
        return false;
      }

      return -MINUTES_BEFORE < diffMinutes && diffMinutes < 0;
    });
  return nextDayPart;
}
