/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { ReactComponent as TimerLocationIcon } from '../../../../../../assets/timer.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/trash.svg';
import { ArrowBackIcon } from '../../../../../../assets';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../theme/ui/Atoms/Input';
import useWatchError from '../../../../../../shared/hooks/useWatchError';
import useErrorMessage from '../../../../../../shared/hooks/useErrorMessage';
import Button from '../../../../../../theme/ui/Atoms/Button';
import { waitPromise } from '../../../../../../shared/util/waitPromise';
import TimerSettingsAPI from '../../../../../../service/timerSettings/TimerSettingsAPI';
import { MenuItemEditResponse } from '../../../../../../service/timerSettings/TimerSettingsAPI.model';
import TimerPicker from '../../../../../../theme/ui/Atoms/TimerPicker/TimerPicker';
import _ from 'lodash';
import {
  durationToMoment,
  momentToDuration,
} from '../../../../../../shared/util/moment/parseTime';
import ZoneSettingsAPI from '../../../../../../service/zoneSettings/ZoneSettingsAPI';
import getOptionsFromZoneSettings from '../../../../../../service/zoneSettings/serialize/getOptionsFromZoneSettings';
import InputSelect from '../../../../../../theme/ui/Atoms/InputSelect';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';

const validationSchema = yup.array().of(
  yup.object({
    itemName: yup.string().max(5).required('error'),
    displayName: yup.string().required('error'),
    holdTime: yup.number().required('error'),
    prepTime: yup.number().required('error'),
    setting: yup.string().required('error'),
  })
);

const StyledTimerLocationIcon = styled(TimerLocationIcon)(({ theme }) => ({
  fill: theme.palette.text.primary,
}));

const StyledTrashIcon = styled(TrashIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

export default function ManagerTimerSettings() {
  const { t } = useTranslation();
  const { regionId } = useParams();
  const navigate = useNavigate();
  const errorMessage = useErrorMessage();
  const { isFetching, isLoading, error, data } =
    TimerSettingsAPI.useGetMenuItemsQuery(regionId || '', {
      refetchOnMountOrArgChange: true,
    });
  const {
    isFetching: isZoneSettingsFetching,
    isLoading: isZoneSettingsLoading,
    error: zoneSettingsError,
    data: zoneSettingsData,
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '', {
    refetchOnMountOrArgChange: true,
  });
  const zoneSettingsOptions = useMemo(
    () => getOptionsFromZoneSettings(zoneSettingsData ?? []),
    [zoneSettingsData]
  );

  const [updateMenuItems, { isLoading: isLoadingUpdate }] =
    TimerSettingsAPI.useSetMenuItemsMutation();
  const busy =
    isFetching ||
    isLoading ||
    isLoadingUpdate ||
    isZoneSettingsLoading ||
    isZoneSettingsFetching;

  useWatchError(error);
  useWatchError(zoneSettingsError);
  useEffect(() => {
    formik.setValues(data ?? []);
  }, [data]);

  const formik = useFormik({
    initialValues: (data ?? []) as MenuItemEditResponse[],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('ManagerZoneSettings:onSubmit', values);
      updateMenuItems({ regionId: regionId || '', menuItems: formik.values })
        .unwrap()
        .then(onClickBack)
        .catch(errorMessage);
    },
  });

  const onClickBack = () => {
    navigate(`/manager/region/${regionId}/actions`);
  };

  const onClickAddZoneSetting = () => {
    formik.setValues([
      ...formik.values,
      {
        itemName: '',
        displayName: '',
        holdTime: 0,
        prepTime: 0,
        setting: '',
      },
    ]);
    waitPromise(200).then(() => {
      document.getElementById(`[${formik.values.length}].id`)?.scrollIntoView();
    });
  };

  const onChangeTimePicker =
    (path: string) =>
    (value: moment.Moment | null, keyboardInputValue?: string | undefined) => {
      const duration = momentToDuration(value);
      formik.setFieldValue(path, duration);
    };
  const getValueTime = (path: string) => {
    const value = _.get(formik.values, path);
    const valueMoment = durationToMoment(value);
    return valueMoment;
  };
  const onDeleteRow = (index: number) => {
    const values = [...formik.values];
    values.splice(index, 1);
    formik.setValues(values);
    setNumberRows(values.length);
  };

  // use this logic to avoid re-rendering the whole form when some input changes,
  // it losses the focus
  const [numberRows, setNumberRows] = useState(formik.values.length);
  useEffect(() => {
    if (formik.values.length !== numberRows) {
      setNumberRows(formik.values.length);
    }
  }, [formik.values]);
  const array = useMemo(() => {
    return Array.from(Array(numberRows).keys());
  }, [numberRows]);

  return (
    <Box
      sx={{
        height: '100%',
        maxHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      component={'form'}
      onSubmit={formik.handleSubmit}
    >
      <Box
        className="timer-settings-header"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingX: 4,
        }}
      >
        <Typography
          variant="subtitle2"
          marginY={2}
          sx={{
            fill: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box component={'span'} marginRight={2}>
            {busy ? (
              <CircularProgress />
            ) : (
              <StyledTimerLocationIcon height={'1.5em'} />
            )}
          </Box>
          {t('timer_settings.title')}
        </Typography>
      </Box>

      <Box
        className="timer-settings-content"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          paddingX: 4,
          overflowY: 'auto',
        }}
      >
        {isFetching || isLoading ? null : (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="d-flex-row-center">
                <Typography color="primary" gutterBottom>
                  {t('timer_settings.header_item')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} className="d-flex-row-center">
                <Typography color="primary" gutterBottom>
                  {t('timer_settings.header_setting')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2} className="d-flex-row-center">
                <Typography color="primary" gutterBottom>
                  {t('timer_settings.header_id_name')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} className="d-flex-row-center">
                <Typography color="primary" gutterBottom>
                  {t('timer_settings.header_hold_time')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} className="d-flex-row-center">
                <Typography color="primary" gutterBottom>
                  {t('timer_settings.header_prep_time')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                className="d-flex-row-center"
                sx={{ justifyContent: { md: 'center' } }}
              >
                <Typography color="primary" gutterBottom>
                  <StyledTrashIcon height={'1em'} />
                </Typography>
              </Grid>

              {/* content */}
              {array?.map((_, index) => {
                if (!formik.values[index]) return null;
                return (
                  <React.Fragment key={`[${index}]`}>
                    <Grid item xs={12} md={4}>
                      <Input
                        id={`[${index}].displayName`}
                        name={`[${index}].displayName`}
                        value={formik.values?.[index].displayName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched[index]?.displayName &&
                            formik.errors[index]?.displayName
                        )}
                        required
                        aria-required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <InputSelect
                        id={`[${index}].setting`}
                        name={`[${index}].setting`}
                        options={zoneSettingsOptions}
                        value={formik.values?.[index].setting}
                        onChange={(_e: any, value: string) => {
                          formik.setFieldValue(`[${index}].setting`, value);
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched[index]?.setting &&
                            formik.errors[index]?.setting
                        )}
                        required
                        aria-required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Input
                        id={`[${index}].itemName`}
                        name={`[${index}].itemName`}
                        value={formik.values?.[index].itemName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{
                          maxLength: 5,
                        }}
                        error={Boolean(
                          formik.touched[index]?.itemName &&
                            formik.errors[index]?.itemName
                        )}
                        required
                        aria-required
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={1}>
                      <TimerPicker
                        ampm={false}
                        InputProps={{
                          id: `[${index}].holdTime`,
                          name: `[${index}].holdTime`,
                          onBlur: formik.handleBlur,
                          error: Boolean(
                            formik.touched[index]?.holdTime &&
                              formik.errors[index]?.holdTime
                          ),
                          required: true,
                          'aria-required': true,
                        }}
                        value={getValueTime(`[${index}].holdTime`)}
                        onChange={onChangeTimePicker(`[${index}].holdTime`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <TimerPicker
                        ampm={false}
                        InputProps={{
                          id: `[${index}].prepTime`,
                          name: `[${index}].prepTime`,
                          onBlur: formik.handleBlur,
                          error: Boolean(
                            formik.touched[index]?.prepTime &&
                              formik.errors[index]?.prepTime
                          ),
                          required: true,
                          'aria-required': true,
                        }}
                        value={getValueTime(`[${index}].prepTime`)}
                        onChange={onChangeTimePicker(`[${index}].prepTime`)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={1}
                      className="d-flex-center"
                      sx={{ justifyContent: { md: 'center' } }}
                    >
                      <Box
                        className="d-flex-center-center"
                        sx={{
                          borderLeft: { md: '1px solid #E0E0E0' },
                          width: { md: '100%' },
                          padding: 1,
                        }}
                      >
                        <IconButton onClick={() => onDeleteRow(index)}>
                          <CloseRounded />
                        </IconButton>
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
      <Box
        className="timer-settings-footer"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginY: 2,
          paddingX: 4,
        }}
      >
        <Box>
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClickAddZoneSetting}
            disabled={busy}
          >
            <PlusIcon height={'1em'} />
          </Button>
          <Button
            variant="contained-gray"
            sx={{ height: '3em', marginLeft: 2 }}
            onClick={onClickBack}
            disabled={busy}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          size="large"
          rounded
          disabled={busy}
        >
          {t('button.save')}
        </Button>
      </Box>
    </Box>
  );
}
