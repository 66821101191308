import { ShowLevelOption } from '../../../redux/groups/selectors/getLevelOfView';

const LENGTH_MAX = 22;
export function getLabelName(
  showLevelOption: ShowLevelOption,
  textTranslation: string,
  controlUnitName: string,
  deviceName: string
) {
  controlUnitName = getLabelMax(controlUnitName);
  deviceName = getLabelMax(deviceName);

  switch (showLevelOption) {
    case 'CONTROL_UNIT':
      return `${textTranslation}`;
    case 'DEVICE':
      return `${textTranslation} - ${controlUnitName}`;
    case 'GROUP':
    default:
      return `${textTranslation} - ${controlUnitName} - ${deviceName}`;
  }
}

function getLabelMax(text: string, lengthMax = LENGTH_MAX) {
  return text.length > lengthMax
    ? text.substring(0, lengthMax - 3) + '...'
    : text;
}
