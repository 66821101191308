import { getTranslateManyDevicesFunction } from '../../../redux/CUTranslation/getTranslateFunction';
import { ShowLevelOption } from '../../../redux/groups/selectors/getLevelOfView';
import {
  TEMPERATURE_UNIT_TYPES,
  Temperature,
} from '../../temperaturePreference';
import { Sensordata, SensordataSTChartLine } from '../monitoring.model';
import { getLabelName } from './getLabelName';

export function mapSensordataToSTChartLine(
  sensordata: Sensordata,
  deviceId: string,
  deviceName: string,
  CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
  showLevelOption: ShowLevelOption,
  temperaturePreference?: TEMPERATURE_UNIT_TYPES
): SensordataSTChartLine {
  const { values, unit, ...sensordataOtherData } = sensordata;

  return {
    ...sensordataOtherData,
    deviceId,
    deviceName,
    name: getLabelName(
      showLevelOption,
      CUsTranslate(
        sensordata.channel,
        deviceId || '',
        sensordata.controlunit
      ) as string,
      sensordata.controlunitName,
      deviceName
    ),
    unit:
      temperaturePreference === TEMPERATURE_UNIT_TYPES.FAHRENHEIT ? '°F' : unit,
    data: values.map((value) => ({
      // Unix timestamp (seconds)
      timestamp: Math.floor(value.timestamp / 1000),
      val:
        temperaturePreference === TEMPERATURE_UNIT_TYPES.FAHRENHEIT
          ? Temperature.convertCelsiusToFahrenheitRound(value.value, 2)
          : value.value,
    })),
  };
}
