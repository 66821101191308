/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar, OptionsObject } from 'notistack';
import { useAppSelector } from '../../redux/store.model';
import { getNetworkIsOnline } from '../../redux/network/selectors';
import useParseErrorMessage from './useParseErrorMessage';

/**
 * function to get error message from error object or string and sent to `enqueueSnackbar`
 * extracted from hook with `variant` and `error` and other options
 *
 * @returns `errorMessage` function
 * @usage
 * ```ts
 * const errorMessage = useErrorMessage()
 * errorMessage(axiosError)
 * errorMessage('Ups, something went wrong')
 * ```
 */
function useErrorMessage() {
  const { enqueueSnackbar } = useSnackbar();
  const parseErrorMessage = useParseErrorMessage();
  const online = useAppSelector(getNetworkIsOnline);
  return useCallback(
    (error: AxiosError | string | unknown, options: OptionsObject = {}) => {
      const { variant = 'error', ...restOptions } = options;
      const message = parseErrorMessage(error);
      console.error(error);
      if (!online && message === 'Network Error') return;
      enqueueSnackbar(message, {
        variant,
        ...restOptions,
      });
      // throw error;
    },
    [enqueueSnackbar]
  );
}

export default useErrorMessage;
