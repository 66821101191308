import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { get, memoize, isEqual, setWith } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ZipDeviceControlUnit } from '../../../../../redux/controlUnit/controlUnit.model';
import { ControlUnitAPIResponse } from '../../../../../service/controlUnit/ControlUnit.model';
import {
  CommanderMonitoringFilterLogicalObject,
  ControlUnitChannelFilterLogicalObject,
  getFilterLogicalObjectFromZipDeviceControlUnits,
  KEYS_CONTROL_UNITS_BY_TYPE,
} from '../util/filter.model';

export type STATUS_FILTER_CHANNEL_OR_EVENT =
  | 'checked'
  | 'indeterminate'
  | 'none';
export const useFilterChannelOrEvent = (
  type: 'events' | 'channels',
  zipDevicesControlUnits: ZipDeviceControlUnit[],
  defaultValue?: CommanderMonitoringFilterLogicalObject
) => {
  const defaultFilterObject = useMemo(
    () =>
      getFilterLogicalObjectFromZipDeviceControlUnits(
        zipDevicesControlUnits,
        type,
        true
      ),
    [zipDevicesControlUnits, type]
  );
  const [filterLogicalObject, setFilterLogicalObject] =
    useState<CommanderMonitoringFilterLogicalObject>(
      defaultValue ?? defaultFilterObject
    );

  const reset = () => {
    setFilterLogicalObject(defaultValue ?? defaultFilterObject);
  };
  const getStateItem = (
    deviceId: string,
    controlUnitId: string,
    channelOrEventId: string
  ): STATUS_FILTER_CHANNEL_OR_EVENT => {
    const path = [deviceId, controlUnitId, channelOrEventId];
    const value = get(filterLogicalObject, path);
    return value ? 'checked' : 'none';
  };

  const toggleItem = useCallback(
    (deviceId: string, controlUnitId: string, channelOrEventId: string) => {
      setFilterLogicalObject(
        produce((draftFilterLogicalObject) => {
          setChannelOrEventId(
            draftFilterLogicalObject,
            deviceId,
            controlUnitId,
            channelOrEventId
          );
        })
      );
    },
    [setFilterLogicalObject]
  );
  const getStateControlUnit: (
    deviceId: string,
    controlUnitId: string
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ) => STATUS_FILTER_CHANNEL_OR_EVENT = useCallback(
    memoize(
      (deviceId: string, controlUnitId: string) => {
        const path = [deviceId, controlUnitId];
        const eventsOrChannelsOnFilter = get(
          filterLogicalObject,
          path
        ) as ControlUnitChannelFilterLogicalObject;

        if (
          !eventsOrChannelsOnFilter ||
          Object.values(eventsOrChannelsOnFilter ?? {}).every(
            (item) => item === false
          )
        )
          return 'none';

        const eventsOrChannelsOnControlUnit = get(
          defaultFilterObject,
          path
        ) as ControlUnitChannelFilterLogicalObject;
        const hasAllChecked = isEqual(
          eventsOrChannelsOnControlUnit,
          eventsOrChannelsOnFilter
        );
        return hasAllChecked ? 'checked' : 'indeterminate';
      },
      (...args: any[]) => args.join('-')
    ),
    [filterLogicalObject, type]
  );
  const toggleControlUnit = useCallback(
    (deviceId: string, controlUnit: ControlUnitAPIResponse): void => {
      setFilterLogicalObject(
        produce((draftFilterLogicalObject) => {
          const newValue =
            getStateControlUnit(deviceId, controlUnit.id) === 'checked'
              ? false
              : true;
          setControlUnit(
            draftFilterLogicalObject,
            deviceId,
            controlUnit,
            type,
            newValue
          );
        })
      );
    },
    [getStateControlUnit, type]
  );

  const getStateDevice: (
    zipDeviceControlUnit: ZipDeviceControlUnit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ) => STATUS_FILTER_CHANNEL_OR_EVENT = useCallback(
    memoize((zipDeviceControlUnit: ZipDeviceControlUnit) => {
      const path = [zipDeviceControlUnit.deviceId];
      const eventsOrChannelsOnFilter = get(
        filterLogicalObject,
        path
      ) as ControlUnitChannelFilterLogicalObject;

      if (
        !eventsOrChannelsOnFilter ||
        Object.values(eventsOrChannelsOnFilter ?? {})
          .flatMap((item) => Object.values(item ?? {}))
          .every((item) => item === false)
      )
        return 'none';

      const eventsOrChannelsOnControlUnit = get(
        defaultFilterObject,
        path
      ) as ControlUnitChannelFilterLogicalObject;
      const hasAllChecked = isEqual(
        eventsOrChannelsOnControlUnit,
        eventsOrChannelsOnFilter
      );
      return hasAllChecked ? 'checked' : 'indeterminate';
    }),
    [filterLogicalObject, type]
  );
  const toggleDevice = useCallback(
    (zipDeviceControlUnit: ZipDeviceControlUnit): void => {
      setFilterLogicalObject(
        produce((draftFilterLogicalObject) => {
          const newValue =
            getStateDevice(zipDeviceControlUnit) === 'checked' ? false : true;
          const { deviceId, controlUnits } = zipDeviceControlUnit;
          controlUnits?.forEach((controlUnit) => {
            setControlUnit(
              draftFilterLogicalObject,
              deviceId,
              controlUnit,
              type,
              newValue
            );
          });
        })
      );
    },
    [getStateDevice, type]
  );
  return {
    filterLogicalObject,
    toggleItem,
    toggleControlUnit,
    toggleDevice,
    getStateItem,
    getStateControlUnit,
    getStateDevice,
    reset,
  };
};

function setChannelOrEventId(
  draftFilterLogicalObject: WritableDraft<CommanderMonitoringFilterLogicalObject>,
  deviceId: string,
  controlUnitId: string,
  channelOrEventId: string,
  value?: boolean
) {
  const path = [deviceId, controlUnitId, channelOrEventId];
  const newValue = value ?? !get(draftFilterLogicalObject, path);
  setWith(draftFilterLogicalObject, path, newValue, Object);
}

function setControlUnit(
  draftFilterLogicalObject: WritableDraft<CommanderMonitoringFilterLogicalObject>,
  deviceId: string,
  controlUnit: ControlUnitAPIResponse,
  type: 'events' | 'channels',
  value?: boolean
) {
  const channelOrEventIds = KEYS_CONTROL_UNITS_BY_TYPE[type].flatMap(
    (keyControlIUnit) =>
      controlUnit[keyControlIUnit].map((eventOrChannel) => eventOrChannel.id)
  );
  channelOrEventIds.forEach((channelOrEventId) => {
    setChannelOrEventId(
      draftFilterLogicalObject,
      deviceId,
      controlUnit.id,
      channelOrEventId,
      value
    );
  });
}
