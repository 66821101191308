import { mapEventsDigitalToSTCharTimeLine } from './util/mapEventsDigitalToSTCharTimeLine';
import { mapSensordataToSTChartLine } from './util/mapSensordataToSTChartLine';
import { SensordataSTChartLine, UUID } from './monitoring.model';
import { MonitoringCalls } from './MonitoringCalls';
import { ControlUnitState } from '../../redux/controlUnit/slice/controlUnitSlice';
import { CommanderControlUnitChannelFilterLogicalObject } from '../../pages/AuthenticatedPages/Manager/ManagerPerformances/util/filter.model';
import { STTimeLineChartData } from '../../pages/AuthenticatedPages/Manager/ManagerPerformances/monitoring.model';
import { ShowLevelOption } from '../../redux/groups/selectors/getLevelOfView';
import { getTranslateManyDevicesFunction } from '../../redux/CUTranslation/getTranslateFunction';
import { TEMPERATURE_UNIT_TYPES } from '../temperaturePreference';

/**
 * Service class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transform it
 */
export class MonitoringService {
  constructor(private monitoringCalls = new MonitoringCalls()) {}
  /**
   * @param deviceId {string} id of device
   * @param start {new Date().getTime()} Milliseconds of the date where you want to start searching
   * @param end {new Date().getTime()} Milliseconds of the date where you want to finish searching
   * @returns Dictionary of Sensordata from this device in the datetime range used
   */
  getSensordata(
    deviceId: string,
    start: number,
    end: number,
    deviceName: string,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject,
    CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
    showLevelOption: ShowLevelOption,
    temperaturePreference?: TEMPERATURE_UNIT_TYPES
  ): Promise<Record<UUID, SensordataSTChartLine[]>> {
    return Promise.all(
      Object.entries(commanderFilterObject).map(
        ([keyControlUnit, valueObject]) => {
          return this.monitoringCalls.getSensordataByDeviceID(
            deviceId,
            start,
            end,
            {
              [keyControlUnit]: valueObject,
            }
          );
        }
      )
    )
      .then((data) => data.flat())
      .then((data) => ({
        [deviceId]: data.map((sensorData) =>
          mapSensordataToSTChartLine(
            sensorData,
            deviceId,
            deviceName,
            CUsTranslate,
            showLevelOption,
            temperaturePreference
          )
        ),
      }));
  }

  /**
   * @param deviceId {string} id of device
   * @param start {new Date().getTime()} Milliseconds of the date where you want to start searching
   * @param end {new Date().getTime()} Milliseconds of the date where you want to finish searching
   * @returns Dictionary of Sensordata from this device in the datetime range used
   */
  getDigitalChannels(
    deviceId: string,
    start: number,
    end: number,
    deviceName: string,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject,
    CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
    showLevelOption: ShowLevelOption,
    controlUnitState: ControlUnitState
  ): Promise<Record<UUID, STTimeLineChartData>> {
    // return Promise.resolve(mockDigital(deviceId + '', start, end));
    return Promise.all(
      Object.entries(commanderFilterObject).map(
        ([keyControlUnit, valueObject]) => {
          return this.monitoringCalls.getEventsByID(deviceId, start, end, {
            [keyControlUnit]: valueObject,
          });
        }
      )
    )
      .then((data) => data.flat())
      .then((data) => ({
        [deviceId]: mapEventsDigitalToSTCharTimeLine(
          data.map((item) => ({
            ...item,
            deviceId: deviceId,
            deviceName: deviceName,
            events: item.events.map((event) => ({
              ...event,
              timestamp: Math.floor(event.timestamp / 1000),
            })),
          })),
          Math.floor(start / 1000),
          Math.floor(end / 1000),
          CUsTranslate,
          showLevelOption,
          controlUnitState
        ),
      }));
  }
}
