import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../ActionButton';
import {
  ChangePasswordIcon,
  ChartsIcon,
  DayPartScheduleIcon,
  LabelIcon,
  TemperatureIcon,
} from '../../../../../assets';
import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';

export default function ManagerDeviceActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Schedule } = useAppSelector(getFeatureToggle);

  return (
    <Box display={'grid'} alignItems={'center'} height={'100%'}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <ActionButton
          text={t('performance.title')}
          IconComponent={ChartsIcon}
          onClick={() => navigate(`../performances`)}
        />
        <ActionButton
          text={t('change_password.title')}
          IconComponent={ChangePasswordIcon}
          onClick={() => navigate(`../change-password`)}
        />
        <ActionButton
          text={t('initial_setup.title_update')}
          IconComponent={LabelIcon}
          onClick={() => navigate(`../update-setup`)}
        />
        {!!Schedule && (
          <ActionButton
            text={t('schedule.settings.view_locations.assign_schedule')}
            IconComponent={DayPartScheduleIcon}
            onClick={() => navigate(`../schedule`)}
          />
        )}
        <ActionButton
          text={t('settings.temperature_preference.title')}
          IconComponent={TemperatureIcon}
          onClick={() => navigate(`../temperature-preference`)}
        />
      </Stack>
    </Box>
  );
}
