import { get } from 'lodash';
import { SensordataSTChartLine } from '../../../../../service/monitoring/monitoring.model';
import { CommanderMonitoringFilterLogicalObject } from './filter.model';

export function filterAnalogChannels(
  sensordataList: SensordataSTChartLine[],
  filterObject: CommanderMonitoringFilterLogicalObject
): SensordataSTChartLine[] {
  return sensordataList.filter((sensordata) => {
    return get(filterObject, [
      sensordata.deviceId ?? '',
      sensordata.controlunit,
      sensordata.channel,
    ]);
  });
}
