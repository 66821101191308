import { createTheme, ThemeOptions } from '@mui/material/styles';
import _ from 'lodash';
import sharedThemeOptions, { polyfillTheme } from './sharedThemeOptions';

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#202020',
      paper: '#222222',
    },
  },
};
const themeOptions = _.merge({}, sharedThemeOptions, darkThemeOptions);
export const themeDark = createTheme(themeOptions);
polyfillTheme(themeDark);

export default themeDark;
