import moment, { Moment } from 'moment';

const timeZero = { hour: 0, minute: 0, second: 0, millisecond: 0 };
/**
 * get string and return a moment object
 * @param time e.g. `08:00:00`
 * @returns <Moment> with the time zone to the current day, if time is not present return null to show empty input
 */
export const timeToMoment = (time?: string) => {
  if (!time) return null;
  const duration = moment.duration(time);

  return moment().set(timeZero).add(duration);
};

/**
 * get string and return a moment object
 * @param time e.g. `moment()`
 * @returns <string> with the text `HH:mm:ss` if the time is valid, otherwise undefined
 */
export const momentToTime = (date?: Moment | null): string | undefined => {
  if (!date?.isValid()) return undefined;
  return date.format('HH:mm:ss');
};

/**
 * get duration in seconds and return a moment object
 * @param durationSeconds e.g. `3600000`
 * @returns
 */
export const durationToMoment = (durationSeconds?: number): Moment | null => {
  if (typeof durationSeconds !== 'number') return null;
  const duration = moment.duration(durationSeconds * 1000);

  return moment().set(timeZero).add(duration);
};

/**
 * get moment object and return a duration in seconds
 * @param date e.g. `moment().add(1, 'hour')`
 * @returns
 */
export const momentToDuration = (date?: Moment | null): number | undefined => {
  if (!date?.isValid()) return undefined;

  return Math.round(date.diff(moment().set(timeZero)) / 1000);
};
