import StoreAPI from '../StoreAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../Store.constants';
import useDevicesOperator from './useDevicesOperator';
import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import ZoneApi from '../modules/zone/ZoneApi';

type Options = Parameters<UseQuerySubscription<any>>[1];
export type DeviceWithSettingsTimers = ReturnType<
  typeof useDeviceWithSettingsTimers
>;
function useDeviceWithSettingsTimers(
  deviceId: string = '',
  options: Options = {}
) {
  const {
    devices,
    error: errorDevicesOperator,
    isFetching: isFetchingDevicesOperator,
    isLoading: isLoadingDevicesOperator,
  } = useDevicesOperator(options);

  const device = devices.find((device) => device.uuid === deviceId);
  const commonOptions = {
    skip: !device,
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
    ...options,
  };

  const {
    data: zoneSettings,
    error: errorZoneSettings,
    isFetching: isFetchingZoneSettings,
    isLoading: isLoadingZoneSettings,
  } = ZoneApi.useGetZoneSettingsQuery(deviceId, commonOptions);
  const {
    data: menuItems,
    error: errorMenuItems,
    isFetching: isFetchingMenuItems,
    isLoading: isLoadingMenuItems,
  } = StoreAPI.useGetMenuItemsQuery(deviceId, commonOptions);
  const {
    data: timers,
    error: errorTimers,
    isFetching: isFetchingTimers,
    isLoading: isLoadingTimers,
  } = StoreAPI.useGetTimersQuery(deviceId, commonOptions);

  const error =
    errorDevicesOperator || errorZoneSettings || errorMenuItems || errorTimers;
  useWatchError(error);
  return {
    device,
    errorDevicesOperator,
    isFetchingDevicesOperator,
    isLoadingDevicesOperator,
    zoneSettings,
    errorZoneSettings,
    isFetchingZoneSettings,
    isLoadingZoneSettings,
    menuItems,
    errorMenuItems,
    isFetchingMenuItems,
    isLoadingMenuItems,
    timers,
    errorTimers,
    isFetchingTimers,
    isLoadingTimers,
    error,
    isFetching:
      isFetchingDevicesOperator ||
      isFetchingZoneSettings ||
      isFetchingMenuItems ||
      isFetchingTimers,
    isLoading:
      isLoadingDevicesOperator ||
      isLoadingZoneSettings ||
      isLoadingMenuItems ||
      isLoadingTimers,
  };
}

export default useDeviceWithSettingsTimers;
