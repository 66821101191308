import { ZoneSettingWitIdResponse } from '../ZoneSettingsAPI.model';
import getOptionsFromZoneSettings from './getOptionsFromZoneSettings';

const defaultOptions = [
  { value: 'OFF', text: 'Off' },
  { value: 'LIGHTS_ONLY', text: 'Lights Only' },
];

function getOptionsFromZoneSettingsWithDefaults(
  zoneSettings: ZoneSettingWitIdResponse[] | undefined
) {
  return [...defaultOptions, ...getOptionsFromZoneSettings(zoneSettings)];
}

export default getOptionsFromZoneSettingsWithDefaults;
