import { createSlice } from '@reduxjs/toolkit';
import { IAuthInfo } from '../../../service/auth/AuthService.model';
import Environment from '../../../shared/constants/Environment';
import Storage from '../../../shared/Storage';
import { loginManagerAThunk } from '../action/thunksAsync';
import { nameReducer } from '../AuthManager.model';

export const initialStateAuthManager: AuthManagerState = {
  authInfo: null,
};
export interface AuthManagerState {
  authInfo?: IAuthInfo | null;
}

export const AuthManagerSlice = createSlice({
  name: nameReducer,
  initialState: initialStateAuthManager,
  reducers: {},
  extraReducers: (builder) => {
    // loginAThunk Thunk
    builder.addCase(loginManagerAThunk.fulfilled, (state, action) => {
      state.authInfo = action.payload;
      /**
       * Use Storage to avoid refreshToken invalid and token expiration when the app is used in different tabs
       */
      Storage.setItem(Environment.authInfoManagerKey, action.payload);
    });
  },
});

export const {
  actions: authActions,
  reducer: authReducer,
  name: authNameReducer,
} = AuthManagerSlice;
