import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalInfo } from '../../../../../theme/ui/Atoms/Modal';
import Button from '../../../../../theme/ui/Atoms/Button';
import useErrorMessage from '../../../../../shared/hooks/useErrorMessage';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/store.model';
import {
  getDevicesWithSchedulesActiveFromStoreSelector,
  getNextDayPartFromDevice,
} from '../../../../../service/store/modules/schedule/selectors/schedule.selectors';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { asyncTasksActions } from '../redux/slice/AsyncTasksSlice';
import { timeToMoment } from '../../../../../shared/util/moment/parseTime';
import { useTick } from '../../../../../shared/hooks/useTick';
import StoreService from '../../../../../service/store/StoreService';
import { toBase64 } from '../../../../../shared/util/base64';
import recordToArray from '../../../../../shared/util/recordToArray/recordToArray';
import { ZoneSettingWitIdResponse } from '../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import ZonesSettingsBoxes from '../../../Manager/ManagerActions/Region/ManagerScheduleSettings/ZoneSettingsSmall/ZonesSettingsBoxes';
import { Typography } from '@mui/material';
import StoreAPI from '../../../../../service/store/StoreAPI';
import ZoneApi from '../../../../../service/store/modules/zone/ZoneApi';

function DeviceScheduleTriggerDetectorModal() {
  const { t } = useTranslation<string>();
  const devices = useAppSelector(
    getDevicesWithSchedulesActiveFromStoreSelector,
    _.isEqual
  );

  const dispatch = useAppDispatch();
  const errorMessage = useErrorMessage();
  const composedDevice = _.first(devices);
  const device = composedDevice?.device;

  const nextDayPart =
    composedDevice && getNextDayPartFromDevice(composedDevice);
  const { data: zoneSettings, isLoading: zoneSettingsLoading } = useAppSelector(
    ZoneApi.endpoints.getZoneSettings.select(composedDevice?.device?.uuid ?? '')
  );

  const momentStartTime = timeToMoment(nextDayPart?.startTime ?? '');
  // force render every 15 seconds
  const now = useTick(() => moment(), 15 * 1000, [nextDayPart?.startTime]);

  const [loading, setLoading] = useState(false);

  const onClickSwitchNow = () => {
    const activateAt = moment();
    onSwitch(activateAt);
  };

  const onClickSwitchAtScheduledTime = () => {
    const activateAt = moment(momentStartTime);
    onSwitch(activateAt);
  };
  const onSwitch = (activateAt: Moment) => {
    Promise.resolve(setLoading(true))
      .then(() =>
        StoreService.triggerDayPart(toBase64(device?.uuid ?? ''), {
          activateAt: activateAt.unix(),
          partId: nextDayPart?.partId ?? '',
        })
      )
      .then(() =>
        dispatch(
          asyncTasksActions.setLastSchedule({
            deviceId: device?.uuid ?? '',
            taskSchedule: {
              success: true,
              scheduleId: device?.schedule ?? '',
              partId: nextDayPart?.partId ?? '',
              timestamp: now.valueOf(),
              activateAt: now.valueOf(),
              dismissUntil: (momentStartTime as Moment)?.valueOf(),
            },
          })
        )
      )
      .then(() =>
        dispatch(
          StoreAPI.util.invalidateTags(['store-actions', 'device-actions'])
        )
      )
      .catch(errorMessage)
      .finally(() => setLoading(false));
  };

  const onClickCancel = () => {
    const now = new Date().getTime();
    dispatch(
      asyncTasksActions.setLastSchedule({
        deviceId: device?.uuid ?? '',
        taskSchedule: {
          skip: true,
          scheduleId: device?.schedule ?? '',
          partId: nextDayPart?.partId ?? '',
          timestamp: now,
          activateAt: now,
          dismissUntil: (momentStartTime as Moment)?.valueOf(),
        },
      })
    );
  };

  const zoneSettingsWithId: ZoneSettingWitIdResponse[] = useMemo(() => {
    return !zoneSettings ? [] : recordToArray(zoneSettings.configured, 'id');
  }, [zoneSettings]);

  const timeToShow = `${nextDayPart?.startTime} (${moment().to(
    momentStartTime
  )})`;

  const showModal = !!composedDevice && !!nextDayPart;

  return (
    <ModalInfo
      open={showModal}
      description={
        <>
          <p>{t('schedule.settings.modal_trigger.description')}</p>
          <p>
            <b>{composedDevice?.device.unitName}</b>
          </p>
          <p>{t('schedule.settings.modal_trigger.description2')}</p>
          <p>
            <Typography>{nextDayPart?.dayPart?.displayName}</Typography>
            <ZonesSettingsBoxes
              zoneSettings={zoneSettingsWithId}
              shelfSettings={nextDayPart?.dayPart?.shelfSettings ?? []}
              loading={zoneSettingsLoading && !zoneSettingsWithId.length}
            />
          </p>
          <p>
            {t('schedule.settings.modal_trigger.description3')}{' '}
            <b> {timeToShow}</b>
          </p>

          <p>{t('schedule.settings.modal_trigger.description_action')}</p>
        </>
      }
      title={t('operator.modal_update.title')}
      buttonActions={
        <>
          <Button
            autoFocus
            variant="text"
            onClick={onClickCancel}
            disabled={loading}
          >
            {t('schedule.settings.modal_trigger.button_cancel')}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={onClickSwitchNow}
            loading={loading}
          >
            {t('schedule.settings.modal_trigger.button_switch_now')}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={onClickSwitchAtScheduledTime}
            loading={loading}
          >
            {t('schedule.settings.modal_trigger.button_switch_at', {
              time: nextDayPart?.startTime,
            })}
          </Button>
        </>
      }
    />
  );
}

export default DeviceScheduleTriggerDetectorModal;
