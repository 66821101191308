import { FeatureToggleState } from './redux/featureToggle.model';
import togglesJson from './featureToggles.json';
import togglesJsonCircleK from '../../customized/circle-k/featureToggles.json';

/** load from json file */
let initialTogglesDictionary: FeatureToggleState = togglesJson;

/** load from venv */
const FEATURE_TOGGLE_PREFIX = 'REACT_APP_FEATURE_TOGGLE_';
const featureKeys = Object.keys(process.env).filter((key) =>
  key.startsWith(FEATURE_TOGGLE_PREFIX)
);
/** clean keys and values */
featureKeys.forEach((key) => {
  let value: string | boolean = process.env[key] || false;
  // parse boolean
  if (value === 'false') value = false;
  if (value === 'true') value = true;
  const keyWithOutPrefix = key.replace(FEATURE_TOGGLE_PREFIX, '');
  initialTogglesDictionary[keyWithOutPrefix] = value;
});

/** load from customized */
const customizedTogglesDictionary: Record<string, FeatureToggleState> = {
  'circle-k': togglesJsonCircleK,
};

const styledComponentsVariants =
  initialTogglesDictionary['StyledComponentsVariants'];
if (styledComponentsVariants && typeof styledComponentsVariants == 'string') {
  initialTogglesDictionary = Object.assign(
    initialTogglesDictionary,
    customizedTogglesDictionary[styledComponentsVariants]
  );
}

export default initialTogglesDictionary;
