import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import Header from '../Header';
import { useAppSelector } from '../../../../redux/store.model';
import Footer from '../Footer';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getTypeUserSelector } from '../../../../redux/auth/selectors';
import { getNetworkIsOnline } from '../../../../redux/network/selectors';
import LocationAPI from '../../../../service/locations/LocationAPI';
import { getStoreNameSelector } from '../../../../service/store/Store.selectors';

interface AuthenticatedLayoutProps {
  children?: React.ReactNode;
}
function AuthenticatedLayout(props: AuthenticatedLayoutProps) {
  const { children } = props;
  const storeName = useAppSelector(getStoreNameSelector);
  const typeUser = useAppSelector(getTypeUserSelector);
  const online = useAppSelector(getNetworkIsOnline);
  const navigate = useNavigate();
  const onClickHeader = () => {
    switch (typeUser) {
      case 'operator':
        return navigate('/operation');
      case 'manager':
        return navigate('/manager/view-locations');
      default:
        return navigate('/login');
    }
  };
  const onClickSettings = () => {
    navigate('/settings');
  };

  return (
    <Stack
      className="AuthenticatedLayout-Root"
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
    >
      {typeUser === 'manager' ? (
        <HeaderManger
          title={storeName ?? ''}
          onClickHome={onClickHeader}
          onClickSettings={onClickSettings}
          offline={!online}
        />
      ) : (
        <Header
          title={storeName ?? ''}
          onClickHome={onClickHeader}
          onClickSettings={onClickSettings}
          offline={!online}
        />
      )}
      <Box
        className="AuthenticatedLayout-Content"
        flexGrow={1}
        overflow={'auto'}
      >
        {children ? children : <Outlet />}
      </Box>
      <Footer />
    </Stack>
  );
}

const HeaderManger = (props: any) => {
  const { deviceId } = useParams();
  const { data: currentData } = LocationAPI.useGetLocationsQuery();
  const device = currentData?.devices.find((items) => items.uuid === deviceId);
  return <Header {...props} title={device?.unitName} />;
};
export default AuthenticatedLayout;
