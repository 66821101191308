export const nameReducer = 'featureToggle';
/**
 * Example:
 * 
 * 
```json
{
  "StyledComponentsVariants": "alan-nuttall-flexeserve-eu",
  "StyledComponentsVariants": "circle-k",
}
```

 */
export interface FeatureToggleState {
  [nameToggle: string]: string | boolean | undefined;
  // know FeatureToggles
  StyledComponentsVariants?: string;
  Schedule?: boolean;
  ScheduleNonConfirmable?: boolean;
}
