import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

interface SettingItemProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  action: React.ReactNode;
}
export function SettingItem(props: SettingItemProps) {
  const { IconComponent, title, action } = props;
  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        marginY: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fill: (theme) => theme.palette.text.primary,
        }}
      >
        <IconComponent height={'2em'} />
        <Typography sx={{ marginLeft: 2 }}>{title}</Typography>
      </Box>
      <Box
        sx={{
          width: '120px',
        }}
      >
        {action}
      </Box>
    </Paper>
  );
}
