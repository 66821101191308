import React, { useEffect, useMemo, useState } from 'react';
import { AnalogChannels } from './AnalogChannels';
import { DigitalChannels } from './DigitalChannels';
import { computeNumberOfUnits } from './computeNumberOfUnits';
import './index.scss';
import { LabelsXAxisTime } from 'stoerk-ui-components';
import { determineAxisYNumber } from './determineAxisYNumber';
import { STTimeLineChartData, TimeFrame } from '../monitoring.model';
import { SensordataSTChartLine } from '../../../../../service/monitoring/monitoring.model';
import { useMetrics } from '../hooks/useMetrics';

type Props = {
  timeFrame: TimeFrame;
  maxWidth?: number;
  timeControls: {
    timeFrame: TimeFrame;
    setTimeFrame: React.Dispatch<React.SetStateAction<TimeFrame>>;
  };
  sensordata: SensordataSTChartLine[];
  digitalChannels: STTimeLineChartData;
  paletteColors: ReturnType<typeof useMetrics>['paletteColors'];
};

export function ComboChartsSimple(props: Props) {
  const {
    maxWidth,
    timeControls: { timeFrame },
    paletteColors: { paletteColorsAnalog, paletteColorsDigital },
  } = props;
  const [internalTimeFrame, setInternalTimeFrame] = useState(timeFrame);
  useEffect(() => {
    setInternalTimeFrame(timeFrame);
  }, [timeFrame]);
  const numberOfUnits = useMemo(
    () => computeNumberOfUnits(props.sensordata),
    [props.sensordata]
  );
  // the number of units is greater than 0
  const maxVAxisToShow = determineAxisYNumber(numberOfUnits, maxWidth ?? 0);
  // 60px is a max size of each Y-axis
  const widthVAxis = 60 * maxVAxisToShow;
  const chartWidth = (maxWidth ?? 600) - widthVAxis;
  const height = window.innerHeight * 0.01 * 50;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const controls = useMemo(
    () => [],
    // https://github.com/rakannimer/react-google-charts/issues/209
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartWidth]
  );
  const labels = useMemo(
    () => ({
      type: 'HTML',
      maxVAxis: maxVAxisToShow,
      widthVAxis: widthVAxis,
      showHAxis: false,
    }),
    [maxVAxisToShow, widthVAxis]
  );

  if (!maxWidth || maxWidth < 0) return null;

  return (
    <div className="ComboChart">
      <AnalogChannels
        data={props.sensordata}
        setInternalTimeFrame={setInternalTimeFrame}
        externalTimeFrame={timeFrame}
        labels={labels}
        width={chartWidth}
        height={height}
        controls={controls}
        paletteColors={paletteColorsAnalog}
      />
      <LabelsXAxisTime
        widthVAxis={widthVAxis}
        width={chartWidth}
        minDatetime={internalTimeFrame.start.unix()}
        maxDatetime={internalTimeFrame.end.unix()}
      />
      <DigitalChannels
        data={props.digitalChannels}
        internalTimeFrame={internalTimeFrame}
        width={chartWidth}
        height={height}
        labels={labels}
        controls={controls}
        paletteColors={paletteColorsDigital}
      />
    </div>
  );
}
export const ComboCharts = React.memo(ComboChartsSimple);
