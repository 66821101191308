import React, { useMemo } from 'react';
import * as yup from 'yup';
import { TwoPanelContent } from '../../../../theme/ui/Layout/TwoPanelContent';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import StoreAPI from '../../../../service/store/StoreAPI';
import { useFormik } from 'formik';
import { LabelIcon } from '../../../../assets';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import Input from '../../../../theme/ui/Atoms/Input/Input';
import Button from '../../../../theme/ui/Atoms/Button/Button';
import useDevicesOperator from '../../../../service/store/hooks/useDevicesOperator';

const validationSchema = yup.object({
  unitName: yup.string().required('initial_setup.unit_name_required'),
});

function UpdateNameDevice() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceId = '' } = useParams<{
    deviceId: string;
  }>();
  const errorMessage = useErrorMessage();
  const { devices } = useDevicesOperator();
  const device = useMemo(
    () => devices?.find((device) => device.uuid === deviceId),
    [devices, deviceId]
  );

  const [updateName] = StoreAPI.useUpdateNameFromDeviceMutation();

  const onClickBack = () => {
    navigate(`../update-name/devices`);
  };
  const formik = useFormik({
    initialValues: {
      unitName: device?.unitName ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) =>
      await updateName({ deviceId, unitName: values.unitName })
        .unwrap()
        .then(onClickBack)
        .catch(errorMessage),
  });

  return (
    <TwoPanelContent
      leftContent={
        <>
          <LabelIcon height={'5em'} />
          <Typography variant="subtitle2">
            {t('initial_setup.title_update')}
          </Typography>
          <Typography>{t('initial_setup.title_update_info')}</Typography>
        </>
      }
      rightContent={
        <Container maxWidth="xs" sx={{ paddingY: 3, width: '900px' }}>
          <Paper elevation={5} sx={{ padding: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Input
                  id={`unitName`}
                  name={`unitName`}
                  label={t('initial_setup.unit_name_label')}
                  placeholder={
                    t('initial_setup.unit_name_placeholder') ?? undefined
                  }
                  value={formik.values?.unitName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.unitName && formik.errors?.unitName
                  )}
                  helperText={
                    formik.touched.unitName && formik.errors?.unitName
                      ? t(formik.errors?.unitName)
                      : undefined
                  }
                  required
                  aria-required
                  fullWidth
                />
              </Box>

              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained-gray"
                    rounded
                    fullWidth
                    onClick={onClickBack}
                    disabled={formik.isSubmitting}
                  >
                    {t('button.back')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    rounded
                    fullWidth
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                  >
                    {t('button.confirm')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      }
    ></TwoPanelContent>
  );
}

export default UpdateNameDevice;
