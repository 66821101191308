import { useTheme } from '@mui/material/styles';
import { ReactComponent as LogoConnectedByIconBlackSVG } from '../../../../assets/connect_by_flexeserve_logo_BLK_Color.svg';
import { ReactComponent as LogoConnectedByIconWhiteSVG } from '../../../../assets/connect_by_flexeserve_logo_WHT_Color.svg';

const LogoConnectedByIcon: typeof LogoConnectedByIconBlackSVG = (props) => {
  const theme = useTheme();
  return theme.palette.mode === 'dark' ? (
    <LogoConnectedByIconWhiteSVG {...props} />
  ) : (
    <LogoConnectedByIconBlackSVG {...props} />
  );
};

export default LogoConnectedByIcon;
