import StoreAPI from '../../StoreAPI';
import { toBase64 } from '../../../../shared/util/base64';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosError } from 'axios';
import StoreService from '../../StoreService';
import newSettingToSetZone from './serialize/newSettingToSetZone';
import { DevicesStoreInfoResponse } from '../../StoreService.model';
import {
  ZoneSetResponse,
  ZoneSettingsResponse,
  ZonesResponse,
} from './ZoneService.model';

const ZoneApi = StoreAPI.injectEndpoints({
  endpoints(build) {
    return {
      // Zones
      /** get zones are inside also in store/v2 so it not need all the time */
      getZones: build.query<ZonesResponse, string>({
        query: (deviceId) => {
          return {
            url: `/${toBase64(deviceId)}/zonesettings`,
            method: 'get',
          };
        },
        providesTags: ['device-actions', 'zone-actions'],
      }),
      getZoneSettings: build.query<ZoneSettingsResponse, string>({
        query: (deviceId) => {
          return {
            url: `/${toBase64(deviceId)}/settings`,
            method: 'get',
          };
        },
        providesTags: ['zone-actions'],
      }),
      /**
       * set Zone with respective setting
       * @param encodedDeviceID device.uuid encoded in base64
       * @param data Object with the timer configuration
       * @param zoneIndex Index of the zone 1-5, 1 is the first zone
       */
      setZoneSettings: build.mutation<
        unknown,
        {
          deviceId: string;
          newSetting: string;
          zoneIndex: keyof DevicesStoreInfoResponse['zones'];
        }
      >({
        queryFn: async (
          { deviceId, newSetting, zoneIndex },
          api,
          extraOptions,
          baseQuery
        ) => {
          const encodedDeviceID = toBase64(deviceId);
          const serializedZone = newSettingToSetZone(newSetting, zoneIndex);
          const response = (await baseQuery({
            url: `/${encodedDeviceID}/zonesettings`,
            method: 'post',
            data: serializedZone,
          })) as QueryReturnValue<ZoneSetResponse, AxiosError, {}>;
          return StoreService.confirmCorrelationIdRecursive(
            encodedDeviceID,
            response?.data?.correlationID ?? ''
          )
            .then(() => response)
            .catch((error) => ({ ...response, error }));
        },

        // Optimistic update
        // async onQueryStarted({ deviceId, newSetting, zoneIndex }, api) {
        //   const { data } = ZoneApi.endpoints.getZoneSettings.select(deviceId)(
        //     api.getState() as RootState
        //   );
        //   const newSettingObj = data?.configured[newSetting];
        //   const patchResult = api.dispatch(
        //     StoreAPI.util.updateQueryData(
        //       'getStoreInfoDetails',
        //       undefined,
        //       (draft) => {
        //         const device = draft.devices.find(
        //           (device) => device.uuid === deviceId
        //         );
        //         if (device) {
        //           device.shelfSettings[zoneIndex].activeSetting = newSetting;
        //           if (newSettingObj) {
        //             device.shelfSettings[
        //               zoneIndex
        //             ].shelfStatus.temperatureSetpoint = newSettingObj.setpoint;
        //             if (newSetting !== 'OFF')
        //               device.shelfSettings[zoneIndex].shelfStatus.deviceOn =
        //                 true;
        //           }
        //         }
        //       }
        //     )
        //   );
        //   try {
        //     await api.queryFulfilled;
        //   } catch {
        //     patchResult.undo();
        //     api.dispatch(StoreAPI.util.invalidateTags(['device-actions']));
        //   }
        // },
        // change value on device, device are in store info
        invalidatesTags: ['store-actions', 'device-actions'],
      }),

      /**
       * Update required with respective setting
       * @param encodedDeviceID device.uuid encoded in base64
       */
      updateRequiredFromSettingsZoneDevice: build.mutation<unknown, string>({
        queryFn: async (deviceId, api, extraOptions, baseQuery) => {
          const encodedDeviceID = toBase64(deviceId);
          const response = (await baseQuery({
            url: `/${encodedDeviceID}/settings`,
            method: 'post',
            data: {
              updateRequired: false,
            },
          })) as QueryReturnValue<ZoneSetResponse, AxiosError, {}>;
          return StoreService.confirmCorrelationIdRecursive(
            encodedDeviceID,
            response?.data?.correlationID ?? ''
          )
            .then(() => response)
            .catch((error) => ({ ...response, error }));
        },
        invalidatesTags: ['store-actions', 'zone-actions'],
      }),
    };
  },
});

export default ZoneApi;
