import { STTimeLineChartData } from '../../pages/AuthenticatedPages/Manager/ManagerPerformances/monitoring.model';
import {
  SensordataSTChartLine,
  UUID,
} from '../../service/monitoring/monitoring.model';

/** based on [ChannelTimeSeries.java](http://gitlab.st.local/stoerk/cloud/sensordata-persistence-service/-/blob/86619659/sensordata-persistence-service/src/main/java/com/stoerk/sensordate/persistency/api/ChannelTimeSeries.java) */
export const nameReducer = 'monitoring';
export interface MonitoringState {
  dicSensordata: Record<UUID, SensordataSTChartLine[]>;
  loading?: boolean;
  dicDigitalChannels: Record<UUID, STTimeLineChartData>;
  error?: any;
}
