import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { merge } from 'lodash';
import { ControlUnitAPIResponse } from '../../../service/controlUnit/ControlUnit.model';
import { ControlUnitTranslationAPIResponse } from '../../../service/CUTranslation/CUTranslation.model';
import { UUID } from '../../../service/device/device.model';
import { ZipDeviceControlUnit } from '../../controlUnit/controlUnit.model';
import {
  fetchControlUnitTranslations,
  fetchDevicesControlUnitsTranslations,
} from '../action/thunks';
import { nameReducer } from '../CUTranslation.model';

export const initialStateCUTranslation: CUTranslationState = {
  dictionaryCUTranslation: {},
  loadingDeviceCUTranslation: {},
  errorDeviceCUTranslation: {},
  errorDevicesCUTranslation: undefined,
};
export interface CUTranslationState {
  dictionaryCUTranslation: Record<
    UUID,
    | Record<ControlUnitAPIResponse['id'], ControlUnitTranslationAPIResponse>
    | undefined
  >;
  loadingDeviceCUTranslation: Record<
    UUID,
    Record<ControlUnitAPIResponse['id'], boolean | undefined> | undefined
  >;
  errorDeviceCUTranslation: Record<
    UUID,
    Record<ControlUnitAPIResponse['id'], AxiosError | undefined> | undefined
  >;
  errorDevicesCUTranslation?: string;
}

export const CUTranslationSlice = createSlice({
  name: nameReducer,
  initialState: initialStateCUTranslation,
  reducers: {},
  extraReducers: (builder) => {
    // fetchControlUnitsTranslations Thunk
    builder.addCase(fetchControlUnitTranslations.pending, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: true } };
      merge(state.loadingDeviceCUTranslation, incomingDataLoading);
      delete state.errorDeviceCUTranslation[deviceId]?.[controlUnitId];
    });
    builder.addCase(fetchControlUnitTranslations.fulfilled, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingData = {
        [deviceId]: { [controlUnitId]: action.payload },
      };
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: false } };
      merge(state.dictionaryCUTranslation, incomingData);
      merge(state.loadingDeviceCUTranslation, incomingDataLoading);
      delete state.errorDeviceCUTranslation[deviceId]?.[controlUnitId];
    });
    builder.addCase(fetchControlUnitTranslations.rejected, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingDataError = {
        [deviceId]: { [controlUnitId]: action.error },
      };
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: false } };
      merge(state.errorDeviceCUTranslation, incomingDataError);
      merge(state.loadingDeviceCUTranslation, incomingDataLoading);
    });
    // fetchDevicesControlUnitsTranslations thunk
    builder.addCase(
      fetchDevicesControlUnitsTranslations.pending,
      (state, action) => {
        const { zipDeviceControlUnits } = action.meta.arg;
        forEachZipDeviceControlUnit(
          zipDeviceControlUnits,
          (zipDeviceControlUnit, controlUnit) =>
            setValue(
              state.loadingDeviceCUTranslation,
              zipDeviceControlUnit.deviceId,
              controlUnit.id,
              true
            )
        );
        state.errorDevicesCUTranslation = undefined;
      }
    );
    builder.addCase(
      fetchDevicesControlUnitsTranslations.fulfilled,
      (state, action) => {
        const { zipDeviceControlUnits } = action.meta.arg;
        forEachZipDeviceControlUnit(
          zipDeviceControlUnits,
          (zipDeviceControlUnit, controlUnit) =>
            setValue(
              state.loadingDeviceCUTranslation,
              zipDeviceControlUnit.deviceId,
              controlUnit.id,
              false
            )
        );
        state.errorDevicesCUTranslation = undefined;
        action.payload.forEach((responseMap) => {
          if (responseMap?.data) {
            const { controlUnitId, data, deviceId } = responseMap;
            setValue(
              state.dictionaryCUTranslation,
              deviceId,
              controlUnitId,
              data
            );
          }
        });
      }
    );
    builder.addCase(
      fetchDevicesControlUnitsTranslations.rejected,
      (state, action) => {
        const { zipDeviceControlUnits } = action.meta.arg;
        state.errorDevicesCUTranslation = action.error.message;
        forEachZipDeviceControlUnit(
          zipDeviceControlUnits,
          (zipDeviceControlUnit, controlUnit) =>
            setValue(
              state.loadingDeviceCUTranslation,
              zipDeviceControlUnit.deviceId,
              controlUnit.id,
              false
            )
        );
      }
    );
  },
});

function forEachZipDeviceControlUnit(
  zipDeviceControlUnits: ZipDeviceControlUnit[],
  callback: (
    zipDeviceControlUnit: ZipDeviceControlUnit,
    controlUnit: ControlUnitAPIResponse
  ) => unknown
) {
  zipDeviceControlUnits.forEach((zipDeviceControlUnit) =>
    zipDeviceControlUnit.controlUnits?.forEach((controlUnit) => {
      callback(zipDeviceControlUnit, controlUnit);
    })
  );
}

function setValue(
  dic: any,
  deviceId: string,
  controlUnitId: string,
  data: any
) {
  dic[deviceId] = merge(dic[deviceId], {
    [controlUnitId]: data,
  });
}

export const {
  actions: actionsCUTranslation,
  reducer: reducerCUTranslation,
  name: nameReducerCUTranslation,
} = CUTranslationSlice;
