import { ZoneSettingResponse } from '../store/modules/zone/ZoneService.model';

/**
 * @example
 * ```json
{
    "id": "25875ecb-520d-4b22-ae3b-3b4b59404db9",
    "displayName": "3-TIER DINNER MENU",
    "confirmable": true,
    "shelfSettings": [
        {
            "zoneId": "1",
            "setting": "OFF"
        },
        {
            "zoneId": "2",
            "setting": "STANDARD_HIGH"
        },
        {
            "zoneId": "3",
            "setting": "OFF"
        }
    ]
}
```    
 */
export interface DayPartAPIResponse {
  id: string;
  displayName: string;
  confirmable: boolean;
  shelfSettings: ShelfSettingDayPartResponse[];
}

export type POSSIBLE_SHELF_ZONE_ID = '1' | '2' | '3' | '4' | '5';
export interface ShelfSettingDayPartResponse {
  zoneId: POSSIBLE_SHELF_ZONE_ID;
  setting: string | null;
}

export interface DayPartEditAPIResponse extends Omit<DayPartAPIResponse, 'id'> {
  id?: string;
}

/**
 * @example
 * ```json
{
    "scheduleId": "9c28d070-8e47-482b-9c01-5caf94c150fa",
    "scheduleName": "3-Tier Regular",
    "zoneCount": 3,
    "schedule": {
        "1": [
            {
                "partId": "0d9fdf58-5de4-4e9f-9b9b-678a92ee4541",
                "startTime": "08:00:00"
            },
            {
                "partId": "1974c0bf-0ce5-467d-b747-0a735e5d948c",
                "startTime": "11:30:00"
            },
            {
                "partId": "25875ecb-520d-4b22-ae3b-3b4b59404db9",
                "startTime": "16:00:00"
            }
        ]
    }
}
```
 */
export interface ScheduleAPIResponse {
  scheduleId: string;
  scheduleName: string;
  /** 1-5 */
  zoneCount: number;
  schedule: Record<
    WeekDaysISO,
    ScheduleDayPartAssignmentAPIResponse[] | undefined
  >;
}

export type WeekDaysISO = '1' | '2' | '3' | '4' | '5' | '6' | '7';

export interface ScheduleZipWithDayParts
  extends Omit<ScheduleAPIResponse, 'schedule'> {
  schedule: Record<
    WeekDaysISO,
    ScheduleDayPartAssignmentZipWithDayParts[] | undefined
  >;
}
export interface ScheduleDayPartAssignmentZipWithDayParts
  extends ScheduleDayPartAssignmentAPIResponse {
  dayPart?: DayPartZipWithZoneSettings;
}

export interface DayPartZipWithZoneSettings
  extends Omit<DayPartAPIResponse, 'shelfSettings'> {
  shelfSettings: ShelfSettingDayPartResponseZipWithZoneSettings[];
}
export interface ShelfSettingDayPartResponseZipWithZoneSettings
  extends ShelfSettingDayPartResponse {
  zoneSettings?: ZoneSettingResponse;
}

export interface ScheduleDayPartAssignmentAPIResponse {
  partId: string;
  /** '08:00:00' */
  startTime: string;
  confirmationType: CONFIRMATION_TYPE;
}

export enum CONFIRMATION_TYPE {
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  CLOUD_CONFIRMATION_REQUIRED = 'CLOUD_CONFIRMATION_REQUIRED',
  COMMANDER_CONFIRMATION_REQUIRED = 'COMMANDER_CONFIRMATION_REQUIRED',
  CU_CONFIRMATION_REQUIRED = 'CU_CONFIRMATION_REQUIRED',
  ANY_CONFIRMATION_REQUIRED = 'ANY_CONFIRMATION_REQUIRED',
  ANY_LOCAL_CONFIRMATION_REQUIRED = 'ANY_LOCAL_CONFIRMATION_REQUIRED',
  NO_CONFIRMATION_REQUIRED = 'NO_CONFIRMATION_REQUIRED',
}
export interface ScheduleEditAPIResponse
  extends Omit<ScheduleAPIResponse, 'scheduleId'> {
  scheduleId?: string;
}

export interface StoreSchedulesUpdateRequiredAPIResponse {
  // ingore this and use store/v2
  dayparts: DayPartAPIResponse[];
  schedules: ScheduleAPIResponse[];

  //Just use this
  devices: {
    [deviceId: string]: {
      schedule: null | string;
      updateRequired: boolean;
    };
  };
}
