import React from 'react';
import { Outlet } from 'react-router-dom';
import { ChangeDetectorOpeningTimesUpdateModal } from './ChangeDetectorOpeningTimesUpdateModal/ChangeDetectorOpeningTimesUpdateModal';
import DeviceSettingsChangeDetectorModal from './DeviceSettingsChangeDetectorModal';
import DeviceScheduleTriggerDetectorModal from './DeviceScheduleTriggerDetectorModal/DeviceScheduleTriggerDetectorModal';
import DeviceScheduleChangeDetectorModal from './DeviceScheduleChangeDetectorModal/DeviceScheduleChangeDetectorModal';
import { useAppSelector } from '../../../../redux/store.model';
import { getFeatureToggle } from '../../../../shared/featureToggle';

export default function OperatorAsyncTaskRouteElement() {
  const { Schedule } = useAppSelector(getFeatureToggle);
  return (
    <>
      <DeviceSettingsChangeDetectorModal />
      <ChangeDetectorOpeningTimesUpdateModal />
      {!!Schedule && (
        <>
          <DeviceScheduleTriggerDetectorModal />
          <DeviceScheduleChangeDetectorModal />
        </>
      )}
      <Outlet />
    </>
  );
}
