import { arrayToRecord } from '../../../shared/ArrayUtil';
import {
  MenuItemEditRequestMutation,
  MenuItemEditResponse,
} from '../TimerSettingsAPI.model';

export default function menuItemListToDic(
  menuList: MenuItemEditResponse[]
): MenuItemEditRequestMutation {
  return arrayToRecord(menuList, 'itemName');
}
