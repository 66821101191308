/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Buffer } from 'buffer';
import moment from 'moment';
// import 'semantic-ui-css/semantic.min.css';
import './semantic-ui/semantic.less';
import 'stoerk-ui-components/build/index.css';
import './polyfills.css';
// import momentDurationFormatSetup from 'moment-duration-format';

// @ts-ignore Fix
window.Buffer = window.Buffer || Buffer;
window.moment = moment;
