/** Storage facade from LocalStorage or SessionStorage */
const Storage: Storage =
  (window && window.localStorage) || (window && window.sessionStorage);

class StorageJSON {
  /** Returns the number of key/value pairs. */
  static getLength(): number {
    return Storage.length;
  }
  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  static setItem(key: string, value: object): void {
    Storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes all key/value pairs, if there are any.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  static clear(): void {
    Storage.clear();
  }
  /** Returns the current value associated with the given key, or null if the given key does not exist. */
  static getItem<T = any>(key: string): T | null {
    const item = Storage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
  /** Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs. */
  static key(index: number): string | null {
    return Storage.key(index);
  }
  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  static removeItem(key: string): void {
    Storage.removeItem(key);
  }
}
export default StorageJSON;
