import React from 'react';
import { InputProps } from '../../../../../../../theme/ui/Atoms/Input/Input';
import { StyledInputSelect } from '../../../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import { DayPartAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import ZonesSettingsBoxes from '../ZoneSettingsSmall/ZonesSettingsBoxes';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { ModalInfo } from '../../../../../../../theme/ui/Atoms/Modal';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon } from '../../../../../../../assets';
import { Link as RouterLink, useParams } from 'react-router-dom';

interface SelectDisplayControlProps {
  InputProps: InputProps;
  /** id displayPart */
  value: string;
  dayParts: DayPartAPIResponse[];
  zoneSettings: ZoneSettingWitIdResponse[];
  onBlur?: () => void;
  onChange?: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    v: string
  ) => void;
  loading?: boolean;
}

function SelectDisplayControl(props: SelectDisplayControlProps) {
  const {
    InputProps,
    value,
    dayParts,
    zoneSettings,
    onBlur,
    onChange,
    loading,
  } = props;
  const { t } = useTranslation();
  const dayPart = dayParts.find((dp) => dp.id === value);
  const valueString = dayPart?.displayName;
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
    onBlur && onBlur();
  };

  const onClickItem = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    v: string
  ) => {
    setOpen(false);
    onBlur && onBlur();
    onChange && onChange(e, v);
  };

  const { regionId } = useParams<{ regionId: string }>();
  const dayPartNewLink = `/manager/region/${regionId}/schedule-settings/day-part/new`;

  if (loading) return <CircularProgress />;

  return (
    <>
      <StyledInputSelect
        {...InputProps}
        onClick={() => {
          setOpen(true);
        }}
      >
        {valueString}
      </StyledInputSelect>
      <ModalInfo
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={open}
        onClose={onClose}
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>{t('schedule.settings.schedule.dayPart_placeholder')}</div>
            <Button
              variant="contained"
              rounded
              sx={{ m: 2 }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore forward params not working in TS
              to={dayPartNewLink}
              component={RouterLink}
            >
              {t('schedule.settings.day_part.new')}
            </Button>
          </Box>
        }
        description={
          <List>
            {dayParts.map((dayPart, index) => {
              return (
                <ListItem
                  disablePadding
                  divider={index !== dayParts.length - 1}
                  key={dayPart.id}
                  onClick={(e) => onClickItem(e, dayPart.id)}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={dayPart.displayName}
                      secondaryTypographyProps={{ component: 'div' }}
                      secondary={
                        <ZonesSettingsBoxes
                          zoneSettings={zoneSettings}
                          shelfSettings={dayPart.shelfSettings}
                        />
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        }
        buttonActions={
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClose}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        }
      />
    </>
  );
}

export default SelectDisplayControl;
