/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import moment from 'moment';
import { getI18n } from 'react-i18next';
import RoutesDefinition from './RoutesDefinition';

export function App() {
  const I18n = getI18n();
  useEffect(() => {
    import('../shared/i18n/moment/initMomentLocales').then(() => {
      moment.locale(I18n.language);
    });
  }, []);
  return <RoutesDefinition />;
}

export default App;
