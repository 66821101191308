import { OptionUnstyled } from '@mui/base';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemResponse } from '../../../../service/store/modules/zone/ZoneService.model';
import { shouldForwardProp } from '../../util/shouldForwardProps';

import ButtonSelect from '../ButtonSelect';
import { ButtonSelectProps } from '../ButtonSelect/ButtonSelect';
import TimerButton, { TimerButtonProps } from '../TimerButton/TimerButton';

const StyledButton = styled(TimerButton, {
  shouldForwardProp,
  name: 'FSZoneButton',
  slot: 'Root',
})``;

const ButtonClear = styled(OptionUnstyled, {
  shouldForwardProp,
  name: 'FSButtonClear',
})(({ theme }) => ({
  borderRadius: '1rem',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: theme.spacing(0.8),
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.common.white,
    backgroundImage: `${theme.palette.gradientColor.short}`,
  },
}));

export type TimerSelectProps = ButtonSelectProps<MenuItemResponse> &
  TimerButtonProps & {
    options: { text: string; value: MenuItemResponse }[];
    onClickClear: () => void;
  };

export default React.forwardRef(function TimerSelect(
  props: TimerSelectProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { t } = useTranslation();
  const slots = {
    root: StyledButton,
    ...props.slots,
  };

  const value = props.options.find(
    (itemOption) => itemOption.value.itemName === props.timer?.menuItemId
  )?.value;

  const startListItem = (
    <Box
      sx={{ margin: 1, marginBottom: props.options.length ? 2 : undefined }}
      onClick={() => {
        props.onClickClear();
      }}
    >
      <ButtonClear value={null}>{t('operator.timer.clear_action')}</ButtonClear>
    </Box>
  );
  return (
    <ButtonSelect
      {...props}
      ref={ref}
      slots={slots}
      value={value}
      startListItem={startListItem}
    />
  );
});
