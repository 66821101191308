import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { loadSession, logout } from '../../redux/auth/action/thunks';
import { isAuthenticatedSelector } from '../../redux/auth/selectors';
import { useAppDispatch, useAppSelector } from '../../redux/store.model';
import useErrorMessage from '../../shared/hooks/useErrorMessage';
import useMessage from '../../shared/hooks/useMessage';
import { useTranslation } from 'react-i18next';

interface AuthenticatedPagesProps {
  children: React.ReactNode;
}
export default function AuthenticatedPages(props: AuthenticatedPagesProps) {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const errorMessage = useErrorMessage();
  const showMessage = useMessage();
  const { t } = useTranslation();

  const onSessionFinishedByOpeningTimes = () => {
    showMessage(t('opening_times.session_finished'), { variant: 'warning' });
    dispatch(logout());
  };
  // just want to run once
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadSession(onSessionFinishedByOpeningTimes))
        .catch(errorMessage)
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const content = loading ? (
    <Box textAlign={'center'} margin={2}>
      <CircularProgress />
    </Box>
  ) : (
    props.children
  );

  return <>{content}</>;
}
