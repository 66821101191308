import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nameReducer } from '../Features.model';

export const initialStateFeatures: FeaturesState = {
  theme: 'dark',
  OPERATOR_DEVICE_SIMPLE_VIEW: 'HORIZONTAL',
  filters: {},
};
export interface FeaturesState {
  theme: string;
  OPERATOR_DEVICE_SIMPLE_VIEW: 'HORIZONTAL' | 'VERTICAL';
  filters: Record<string, any>;
}

export const FeaturesSlice = createSlice({
  name: nameReducer,
  initialState: initialStateFeatures,
  reducers: {
    changeFeaturesTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    toggleOperatorView: (state) => {
      state.OPERATOR_DEVICE_SIMPLE_VIEW =
        state.OPERATOR_DEVICE_SIMPLE_VIEW === 'HORIZONTAL'
          ? 'VERTICAL'
          : 'HORIZONTAL';
    },
    updateFilter(state, action: PayloadAction<{ path: string; filter: any }>) {
      const { path, filter } = action.payload;
      state.filters[path] = filter;
    },
  },
});

export const {
  actions: FeaturesActions,
  reducer: FeaturesReducer,
  name: FeaturesNameReducer,
} = FeaturesSlice;
