import React from 'react';
import { useParams } from 'react-router-dom';
import ManagerTemperaturePreference from '../../ManagerTemperaturePreference';

function ManagerTemperaturePreferenceRegion() {
  const params = useParams<{ regionId: string }>();
  const { regionId: groupId } = params;
  return (
    <ManagerTemperaturePreference
      groupId={groupId}
      title="settings.temperature_preference.title_region"
      hintText="settings.temperature_preference.manager_region_hint"
      recursive
    />
  );
}

export default ManagerTemperaturePreferenceRegion;
