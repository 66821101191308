import React from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import GuardedRoute from '../pages/AuthenticatedPages/GuardRoute';
import Login from '../pages/Login';
import HomeRedirection from '../pages/Home';
import TimerOperation from '../pages/AuthenticatedPages/Operator/TimerOperation';
import NotFound from '../pages/NotFound';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/store.model';
import { isAuthenticatedSelector } from '../redux/auth/selectors';
import OperatorDeviceOverviewLayout from '../pages/AuthenticatedPages/Operator';
import DeviceOperation from '../pages/AuthenticatedPages/Operator/DeviceOperation';
import Settings from '../pages/AuthenticatedPages/Settings';
import OpeningTimes from '../pages/AuthenticatedPages/Settings/OpeningTimes';
import LoginManager from '../pages/LoginManager';
import ManagerViewLocations from '../pages/AuthenticatedPages/Manager/ManagerViewLocations';
import ManagerActions from '../pages/AuthenticatedPages/Manager/ManagerActions';
import ManagerZoneSettings from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerZoneSettings';
import ManagerTimerSettings from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerTimerSettings';
import ManagerRegionActions from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerRegionActions';
import ManagerChangePassword from '../pages/AuthenticatedPages/Manager/ManagerActions/Store/ManagerChangePassword/ManagerChangePassword';
import ManagerPerformances from '../pages/AuthenticatedPages/Manager/ManagerPerformances';
import InitialSetup, {
  FillInformation,
} from '../pages/AuthenticatedPages/Settings/InitialSetup';
import ContactUs from '../pages/InfoPages/ContactUs';
import Terms from '../pages/InfoPages/Terms';
import Legal from '../pages/InfoPages/Legal';
import AuthenticatedLayout from '../theme/ui/Layout/AuthenticatedLayout/AuthenticatedLayout';
import OperatorAsyncTaskRouteElement from '../pages/AuthenticatedPages/Operator/AsyncTasks/OperatorAsyncTaskRouteElement';
import ManagerScheduleSettingsLayout from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerScheduleSettings/ManagerScheduleSettingsLayout';
import DayPartListPage from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerScheduleSettings/DayPartListPage';
import DayPartNewPage from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerScheduleSettings/DayPartNewPage';
import ScheduleListPage from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerScheduleSettings/ScheduleListPage/ScheduleListPage';
import ScheduleNewPage from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerScheduleSettings/ScheduleNewPage/ScheduleNewPage';
import ManagerAssignSchedulePage from '../pages/AuthenticatedPages/Manager/ManagerActions/Device/ManagerAssignSchedule/ManagerAssignSchedulePage';
import ManagerDeviceUpdateSetup from '../pages/AuthenticatedPages/Manager/ManagerActions/Device/ManagerDeviceUpdateSetup/ManagerDeviceUpdateSetup';
import UpdateNameDevices from '../pages/AuthenticatedPages/Settings/UpdateName/UpdateNameDevices';
import UpdateNameDevice from '../pages/AuthenticatedPages/Settings/UpdateName/UpdateNameDevice';
import ManagerTemperaturePreferenceRegion from '../pages/AuthenticatedPages/Manager/ManagerActions/Region/ManagerTemperaturePreferenceRegion/ManagerTemperaturePreferenceRegion';
import ManagerTemperaturePreferenceStore from '../pages/AuthenticatedPages/Manager/ManagerActions/Store/ManagerTemperaturePreferenceStore/ManagerTemperaturePreferenceStore';

interface RoutesDefinitionProps extends ConnectedComponentProps {}
function RoutesDefinition(props: RoutesDefinitionProps) {
  return (
    <Router>
      <Routes>
        <Route element={<GuardedRoute isAllowed={props.isAuthenticated} />}>
          <Route element={<AuthenticatedLayout />}>
            <Route path="/settings">
              <Route path="initial-setup" element={<InitialSetup />}></Route>
              <Route
                path="initial-setup/fill-information/:flexeserveType"
                element={<FillInformation />}
              ></Route>

              <Route path="" element={<Settings />}></Route>
            </Route>
            <Route element={<OperatorAsyncTaskRouteElement />}>
              <Route path="/settings">
                <Route path="opening-times" element={<OpeningTimes />}></Route>
                <Route
                  path="update-name/devices"
                  element={<UpdateNameDevices />}
                ></Route>
                <Route
                  path="update-name/device/:deviceId"
                  element={<UpdateNameDevice />}
                ></Route>
              </Route>
              <Route
                path="/operation"
                element={<OperatorDeviceOverviewLayout />}
              >
                <Route path="timers/:deviceId" element={<TimerOperation />} />
                <Route path="" element={<DeviceOperation />} />
              </Route>
            </Route>

            <Route path="/manager">
              <Route path="view-locations" element={<ManagerViewLocations />} />
              <Route path="region/:regionId/store/:storeId/device/:deviceId">
                <Route path="actions" element={<ManagerActions />} />
                <Route path="performances" element={<ManagerPerformances />} />
                <Route
                  path="change-password"
                  element={<ManagerChangePassword />}
                />
                <Route
                  path="update-setup"
                  element={<ManagerDeviceUpdateSetup />}
                />
                <Route
                  path="schedule"
                  element={<ManagerAssignSchedulePage />}
                />
                <Route
                  path="temperature-preference"
                  element={<ManagerTemperaturePreferenceStore />}
                />
              </Route>
              <Route path="region/:regionId">
                <Route path="actions" element={<ManagerRegionActions />} />
                <Route
                  path="timer-settings"
                  element={<ManagerTimerSettings />}
                />
                <Route path="zone-settings" element={<ManagerZoneSettings />} />
                <Route
                  path="schedule-settings"
                  element={<ManagerScheduleSettingsLayout />}
                >
                  <Route path="" element={<Navigate to={'./schedule'} />} />
                  <Route path="day-part" element={<DayPartListPage />} />
                  <Route path="day-part/new" element={<DayPartNewPage />} />
                  <Route
                    path="day-part/:dayPartId"
                    element={<DayPartNewPage />}
                  />
                  <Route path="schedule" element={<ScheduleListPage />} />
                  <Route path="schedule/new" element={<ScheduleNewPage />} />
                  <Route
                    path="schedule/:scheduleId"
                    element={<ScheduleNewPage />}
                  />
                </Route>
                <Route
                  path="temperature-preference"
                  element={<ManagerTemperaturePreferenceRegion />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/login-manager" element={<LoginManager />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/" element={<HomeRedirection />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: isAuthenticatedSelector(),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(RoutesDefinition);
