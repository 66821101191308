import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../theme/ui/Atoms/Button';
import { Theme, useTheme } from '@mui/material/styles';
import useDevicesOperator from '../../../service/store/hooks/useDevicesOperator';
import { DevicesStoreInfoResponse } from '../../../service/store/StoreService.model';

function getColor(
  deviceId: string | undefined,
  device: DevicesStoreInfoResponse,
  theme: Theme
) {
  if (!deviceId) return 'inherit';
  if (deviceId === device.uuid) return theme.palette.primary.main;
  return 'gray';
}
export default function OperatorDeviceOverviewLayout() {
  const { devices, isLoading, isFetching } = useDevicesOperator();
  const theme = useTheme();
  const navigate = useNavigate();
  const { deviceId } = useParams();

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
      <Stack direction={'row'} spacing={3} minHeight={'100%'}>
        <Paper
          className="left-bar"
          sx={{
            width: '15vw',
            height: 'auto',
          }}
        >
          <Typography
            variant="subtitle2"
            color={'primary'}
            textTransform={'uppercase'}
            textAlign={'center'}
            sx={{ marginBottom: 2, marginTop: 3 }}
          >
            {isLoading || isFetching ? <CircularProgress size={26} /> : <br />}
          </Typography>
          <Stack direction={'column'} spacing={1} sx={{ marginTop: 2 }}>
            {devices?.map((device) => (
              <Box
                key={device.uuid}
                sx={{
                  height: 90,
                  padding: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: getColor(deviceId, device, theme),
                }}
              >
                <Button
                  variant="text"
                  size="large"
                  color="inherit"
                  sx={{ fontSize: 16, width: '100%' }}
                  onClick={() => navigate(`/operation/timers/${device.uuid}`)}
                >
                  {device.unitName}
                </Button>
              </Box>
            ))}
          </Stack>
        </Paper>
        <Box
          className="right-content"
          flexGrow={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
}
