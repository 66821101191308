import { TFunction } from 'i18next';

const MapError: Record<string, string> = {
  ERROR_NOT_UNIQUE: 'error.not_unique',
  ERROR_CLOUDCONNECTID_INVALID: 'error.cloudconnectid_invalid',
  ERROR_MISSING_FIELDS: 'error.missing_fields',
  ERROR_INVALID_FIELDS: 'error.invalid_fields',
  ERROR_NOT_A_FLEXESERVE_DEVICE: 'error.not_a_flexeserve_device',
  ERROR_GROUP_NOT_FOUND: 'error.group_not_found',
  ERROR_DEVICE_NOT_FOUND: 'error.device_not_found',
  ERROR_DEVICE_ALREADY_REGISTERED: 'error.device_already_registered',
};

/**
 * fund if the error has a message and translate and concatenate with translation ´initial_setup.error´
 * else return translation ´initial_setup.error´
 * @param error
 * @param t
 * @returns
 */
const serializeErrorInitialSetup = (t: TFunction, error: any) => {
  if (typeof error?.data == 'string') {
    return t('initial_setup.error', {
      message: t(MapError[error?.data] ?? ''),
    });
  }
  if (error instanceof Error) {
    return t('initial_setup.error', { message: error.message });
  }
  return t('initial_setup.error', { message: '' });
};

export default serializeErrorInitialSetup;
