import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactComponent as ViewLocationIcon } from '../../../../assets/view_location.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DeviceWithIncidents,
  Region,
} from '../../../../service/locations/Location.model';
import PaperButton from '../../../../theme/ui/Atoms/PaperButton';
import StatusOverviewByDevice from './StatusOverviewByDevice';
import Button from '../../../../theme/ui/Atoms/Button';
import InputSelect from '../../../../theme/ui/Atoms/InputSelect/InputSelect';
import ScheduleAPI from '../../../../service/schedule/ScheduleAPI';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { CircularProgress } from '@mui/material';
import Table from '../../../../theme/ui/Atoms/Table/Table';
import { useAppSelector } from '../../../../redux/store.model';
import { getFeatureToggle } from '../../../../shared/featureToggle';

interface ManagerViewRegionProps {
  region: Region;
  devices: DeviceWithIncidents[];
}
export default function ManagerViewRegion(props: ManagerViewRegionProps) {
  const { devices, region } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickDevice = (device: DeviceWithIncidents) => {
    navigate(
      `/manager/region/${region.groupId}/store/${device.storeId}/device/${device.uuid}/actions`
    );
  };

  const onClickRegion = () => {
    navigate(`/manager/region/${region.groupId}/actions`);
  };

  const {
    error: errorSchedules,
    isLoading: isLoadingSchedules,
    isFetching: isFetchingSchedules,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByRegionQuery(region.groupId ?? '');
  const optionsSchedule = useMemo(
    () =>
      schedules?.map((schedule) => ({
        text: schedule.scheduleName,
        value: schedule.scheduleId,
      })) ?? [],
    [schedules]
  );

  useWatchError(errorSchedules);

  const devicesInRegion = useMemo(
    () => devices.filter((device) => region.devicesIds.includes(device.uuid!)),
    [devices, region.devicesIds]
  );

  const { Schedule } = useAppSelector(getFeatureToggle);

  return (
    <Table
      sx={{
        '& th.header_store': {
          minWidth: 400,
        },
      }}
    >
      <caption className="view-location-header">
        <Button
          variant="text"
          color="inherit"
          fullWidth
          onClick={onClickRegion}
        >
          <Box
            className="view-location-header"
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginLeft: 2,
              fill: (theme) => theme.palette.text.primary,
            }}
          >
            <ViewLocationIcon height={'2em'} />
            <Typography
              variant="subtitle2"
              marginY={2}
              sx={{
                display: 'flex',
                marginLeft: 3,
                alignItems: 'center',
              }}
            >
              {region.name}
            </Typography>
          </Box>
        </Button>
      </caption>

      <thead style={{ border: 'none' }}>
        <tr>
          <Typography
            component={'th'}
            color="primary"
            variant="subtitle2"
            sx={{ textAlign: 'left!important' }}
          >
            {t('view_locations.header_store')}
          </Typography>
          <Typography
            component={'th'}
            className="header_store"
            color="primary"
            variant="subtitle2"
            sx={{ textAlign: 'left!important' }}
          >
            {t('view_locations.header_device_name')}
          </Typography>
          <Typography component={'th'} color="primary" variant="subtitle2">
            {t('view_locations.header_model')}
          </Typography>
          {!!Schedule && (
            <Typography component={'th'} color="primary" variant="subtitle2">
              {t('schedule.settings.view_locations.schedule_header')}
            </Typography>
          )}
          <Typography component={'th'} color="primary" variant="subtitle2">
            {t('view_locations.header_status')}
          </Typography>
          <Typography component={'th'} color="primary" variant="subtitle2">
            {t('view_locations.header_alarms')}
          </Typography>
        </tr>
      </thead>
      <tbody>
        {devicesInRegion?.map((device) => {
          return (
            <PaperButton
              component={'tr'}
              key={device.uuid}
              elevation={undefined}
              onClick={() => onClickDevice(device)}
            >
              <td>
                <Typography variant="body1" textAlign={'left'}>
                  {device.storeName}
                </Typography>
              </td>
              <td>
                <Typography variant="body1" textAlign={'left'}>
                  {device.unitName}
                </Typography>
              </td>
              <td>
                <Typography variant="body1" textAlign={'center'}>
                  {device.model}
                </Typography>
              </td>
              {!!Schedule && (
                <td>
                  <Typography variant="body1" textAlign={'center'}>
                    {isLoadingSchedules || isFetchingSchedules ? (
                      <CircularProgress size={'1em'} />
                    ) : (
                      <InputSelect
                        id={`${device.uuid}-schedule`}
                        name={`${device.uuid}-schedule`}
                        options={optionsSchedule}
                        value={device.schedule}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation();
                          navigate(
                            `/manager/region/${region.groupId}/store/${device.storeId}/device/${device.uuid}/schedule`
                          );
                        }}
                        fullWidth
                      />
                    )}
                  </Typography>
                </td>
              )}
              <td>
                <StatusOverviewByDevice device={device} />
              </td>
              <td>{device.alerts}</td>
            </PaperButton>
          );
        })}
      </tbody>
    </Table>
  );
}
