import React from 'react';
import Box from '@mui/material/Box';
import LogoConnectedByIcon from '../../theme/ui/Atoms/Icons/LogoConnectedByIcon';

export default function LoginHeaderLogo() {
  return (
    <Box textAlign={'center'} marginBottom={(theme) => theme.spacing(8)}>
      <LogoConnectedByIcon height={100} />
    </Box>
  );
}
