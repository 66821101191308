import React from 'react';
import { useUID } from 'react-uid';
import Radio from '@mui/material/Radio';
import MUIRadioGroup, {
  RadioGroupProps as MUIRadioGroupProps,
} from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface RadioGroupProps extends MUIRadioGroupProps {
  id?: string;
  label?: React.ReactNode;
  options: { value: unknown; label: string }[];
}
const RadioGroup = React.forwardRef(function CustomRadioGroup(
  inProps: RadioGroupProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { id: idProp, options, label, ...propsRadioGroup } = inProps;
  const uniqueId = useUID();
  const id = idProp || `FSRadioGroup-${uniqueId}`;
  return (
    <FormControl>
      <FormLabel id={id}>{label}</FormLabel>
      <MUIRadioGroup
        aria-labelledby={id}
        name={id}
        {...propsRadioGroup}
        ref={ref}
      >
        {options.map((option) => (
          <FormControlLabel
            key={`${option.value}`}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  );
});

export default RadioGroup;
