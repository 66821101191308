import { useEffect, useRef } from 'react';
import useErrorMessage from './useErrorMessage';

/** save the initial state in a ref hook, if it change show a error with errorMessage */
export default function useWatchError(
  error: Parameters<ReturnType<typeof useErrorMessage>>[0]
) {
  const initialError = useRef(error);
  const errorMessage = useErrorMessage();
  useEffect(() => {
    if (error && error !== initialError.current) {
      errorMessage(error);
      initialError.current = error;
    }
  }, [error, errorMessage]);
}
