import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import {
  DevicesStoreInfoResponseV2,
  OpeningTimesStoreResponse,
  OpeningTimesWeekSetting,
  SetOpeningTimesResponse,
} from './StoreService.model';
import { toBase64 } from '../../shared/util/base64';
import { TEMPERATURE_UNIT_TYPES } from '../temperaturePreference';
import { RootState } from '../../redux/store.model';
import StoreService from './StoreService';
import { AxiosError } from 'axios';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  MenuItemsResponse,
  TimerItemRequest,
  TimersResponse,
} from './modules/zone/ZoneService.model';
import { setWith } from 'lodash';

const StoreAPI = createApi({
  refetchOnMountOrArgChange: true,
  reducerPath: 'operator-store',
  tagTypes: [
    // used to invalidate queries to pull data frequently
    'store-actions',
    'device-actions',
    'opening-times-actions',
    'zone-actions',
    'schedules-actions',
    'timer-actions',
  ],
  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/operator' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      // not used yet
      getStoreInfoDetails: build.query<
        DevicesStoreInfoResponseV2,
        void | undefined
      >({
        query: () => {
          return {
            url: `/store/v2`,
            method: 'get',
          };
        },
        providesTags: [
          'store-actions',
          'device-actions',
          'zone-actions',
          'opening-times-actions',
          'schedules-actions',
        ],
      }),
      getOpeningTimesByStore: build.query<
        OpeningTimesStoreResponse,
        void | undefined
      >({
        query: () => {
          return {
            url: `/store/openingtimes`,
            method: 'get',
          };
        },
        providesTags: ['store-actions', 'opening-times-actions'],
      }),
      setOpeningTimesByStore: build.mutation<
        SetOpeningTimesResponse,
        OpeningTimesWeekSetting
      >({
        async queryFn(openingTimes, api, extraOptions, baseQuery) {
          const { data, error, meta } = (await baseQuery({
            url: `/store/openingtimes`,
            method: 'post',
            data: openingTimes,
          })) as QueryReturnValue<SetOpeningTimesResponse, AxiosError, {}>;
          const setOpeningTimesResponse = data as SetOpeningTimesResponse;
          const promisesCheckCorrelationForConnectedDevices = Object.entries(
            setOpeningTimesResponse
          )
            /// filter devices connected
            .filter(([deviceId]) =>
              StoreAPI.endpoints.getStoreInfoDetails
                .select()(api.getState() as RootState)
                .data?.devices.find(
                  (device) =>
                    device.uuid === deviceId && device.deviceState.mqttConnected
                )
            )
            .map(([deviceId, value]) =>
              api
                .dispatch(
                  StoreAPI.endpoints.setOpeningTimesByDevice.initiate(
                    {
                      deviceId,
                      correlationID: value.correlationID,
                    },
                    {
                      fixedCacheKey: `setOpeningTimesByDevice/${deviceId}`,
                    }
                  )
                )
                .unwrap()
            );
          try {
            await Promise.all(promisesCheckCorrelationForConnectedDevices);
          } catch (error) {
            return { data, error, meta } as any;
          }

          return { data, error, meta };
        },
        invalidatesTags: ['opening-times-actions'],
      }),

      setOpeningTimesByDevice: build.mutation<
        unknown,
        { deviceId: string; correlationID: string }
      >({
        async queryFn({ deviceId, correlationID }) {
          const meta = { correlationID, deviceId };
          try {
            const data = await StoreService.confirmCorrelationIdRecursive(
              toBase64(deviceId),
              correlationID
            );
            return { data, meta };
          } catch (error) {
            return { error, meta };
          }
        },
      }),

      updateNameFromDevice: build.mutation<
        void,
        {
          deviceId: string;
          unitName: string;
        }
      >({
        query: ({ unitName, deviceId }) => {
          return {
            url: `/${toBase64(deviceId)}/name`,
            method: 'post',
            data: unitName,
            // request in text/plain but with json body
            transformRequest: (data) => data,
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        invalidatesTags: ['store-actions', 'device-actions'],
      }),

      updateTemperaturePreference: build.mutation<
        unknown,
        TEMPERATURE_UNIT_TYPES
      >({
        query: (temperatureUnit) => {
          return {
            url: `/store/unit`,
            method: 'put',
            data: temperatureUnit,
            // request in text/plain but with json body
            transformRequest: (data) => data,
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        invalidatesTags: ['store-actions'],
      }),

      // Zone: extended in modules/zone/ZoneApi.ts

      getMenuItems: build.query<MenuItemsResponse, string>({
        query: (deviceId) => {
          return {
            url: `/${toBase64(deviceId)}/menuitems`,
            method: 'get',
          };
        },
        providesTags: ['device-actions', 'timer-actions'],
      }),
      getTimers: build.query<TimersResponse, string>({
        query: (deviceId) => {
          return {
            url: `/${toBase64(deviceId)}/timers`,
            method: 'get',
          };
        },
        providesTags: ['timer-actions'],
      }),
      /**
       * set Timer with respective configuration and times
       * @param encodedDeviceID device.uuid encoded in base64
       * @param data Object with the timer configuration
       * @param zoneIndex Index of the zone 1-5, 1 is the first zone
       * @param timerIndex Index of the timer 0-n, 0 is the first timer
       */

      setTimers: build.mutation<
        '',
        {
          deviceId: string;
          data: TimerItemRequest | null;
          zoneIndex: string;
          timerIndex: number;
        }
      >({
        query: ({ deviceId, data, zoneIndex, timerIndex }) => {
          const encodedDeviceID = toBase64(deviceId);
          const url = `/${encodedDeviceID}/timers/${zoneIndex}/${timerIndex}`;
          return {
            url,
            method: 'put',
            data: data === null ? 'null' : data,
            // need header to avoid 415 error
            headers: { 'Content-Type': 'application/json' },
          };
        },
        // optimistic update
        async onQueryStarted({ data, deviceId, timerIndex, zoneIndex }, api) {
          api.dispatch(
            StoreAPI.util.updateQueryData('getTimers', deviceId, (draft) => {
              setWith(draft, [zoneIndex, timerIndex], data, Object);
            })
          );
          try {
            await api.queryFulfilled;
          } catch {
            // do nothing, there is a message to explain the error
          }
        },
      }),
    };
  },
});

export default StoreAPI;
