import { AxiosError, AxiosResponse } from 'axios';
import { getNetworkIsOnline } from '../../../redux/network/selectors';
import { actionsNetwork } from '../../../redux/network/slice/NetworkSlice';

/**
 * Check if the user is online or offline,
 * if offline check if the response has any statusCode, so app is not offline anymore
 *
 * @param response <AxiosResponse>
 * @returns
 */
export const networkInterceptorsResponse = async (response: AxiosResponse) => {
  const store = (await import('./../../../redux/Store')).default;
  if (!store) throw new Error('store not initialized');

  const isOnline = getNetworkIsOnline(store.getState());
  if (isOnline) return response;

  // check if the request has any statusCode, so app is not offline anymore
  if (response.status) {
    store.dispatch(actionsNetwork.setNetworkState('online'));
  }
  return response;
};

/**
 * Check if the error is a network error, if so set the network state to offline
 * @param error <any | AxiosError>
 */
export const networkInterceptorsError = async (error: any | AxiosError) => {
  const store = (await import('./../../../redux/Store')).default;
  if (!store) throw new Error('store not initialized');

  const isOnline = getNetworkIsOnline(store.getState());
  if (!isOnline) return Promise.reject(error);

  if (error.code === 'ERR_NETWORK') {
    await store.dispatch(actionsNetwork.setNetworkState('offline'));
  }
  return Promise.reject(error);
};
