import { Box, CircularProgress, Typography } from '@mui/material';
import { ShelfSettingDayPartResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { emptyArray } from '../../../../../../../shared/ArrayUtil';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ZoneSettingContainer } from '../../../../../../../theme/ui/Atoms/ZoneSettingButton/ZoneSettingButton';

interface ZonesSettingsBoxesProps {
  shelfSettings: ShelfSettingDayPartResponse[];
  zoneSettings?: ZoneSettingWitIdResponse[];

  loading?: boolean;
}

/**
 * to boxes of ShelfStettings per day part
 */

export function ZonesSettingsBoxes(props: ZonesSettingsBoxesProps) {
  const {
    shelfSettings,
    loading,
    zoneSettings = emptyArray as ZoneSettingWitIdResponse[],
  } = props;

  if (loading) return <CircularProgress />;
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: 1,
        backgroundColor: 'background.paper',
        borderRadius: '4px',
        padding: 1,
        mt: 1,
      }}
    >
      {shelfSettings.map((shelfSetting, index) => (
        <ZonesSettingBox
          key={index}
          idSetting={shelfSetting.setting}
          zoneId={shelfSetting.zoneId}
          zoneSettings={zoneSettings}
        />
      ))}
    </Box>
  );
}

interface ZonesSettingBoxProps {
  idSetting?: string | null;
  zoneId?: string | null;
  zoneSettings: ZoneSettingWitIdResponse[];
}
function ZonesSettingBox(props: ZonesSettingBoxProps) {
  const { idSetting, zoneId, zoneSettings } = props;
  const { t } = useTranslation();
  const zoneSetting = useMemo(
    () => zoneSettings.find((zoneSetting) => zoneSetting.id === idSetting),
    [zoneSettings, idSetting]
  );

  return (
    <Box
      sx={{
        fontSize: { xs: '3vw', md: '2.2rem' },
        maxWidth: '120px',
      }}
    >
      <Typography
        variant="body2"
        color={'text.disabled'}
        textAlign={'center'}
        fontSize={{ xs: '0.7rem', md: '0.85rem' }}
      >
        {`${t('schedule.settings.schedule.zone')} ${zoneId}`}
      </Typography>
      <ZoneSettingContainer
        zoneSettingId={idSetting}
        zoneSetting={zoneSetting}
      />
    </Box>
  );
}

export default ZonesSettingsBoxes;
