import React, { useRef } from 'react';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { MenuItemResponse } from '../../../../service/store/modules/zone/ZoneService.model';
import { useWidthRef } from '../../util/useWidthRef';
import ButtonSelect from '../ButtonSelect';
import { ButtonSelectProps } from '../ButtonSelect/ButtonSelect';
import Input, { InputProps } from '../Input/Input';

export const StyledInputSelect = React.forwardRef(function StyledInputRef(
  inProps: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { children, ...props } = inProps;
  return (
    <Input
      {...props}
      value={children}
      ref={ref}
      endAdornment={<UnfoldMoreRoundedIcon />}
    />
  );
});

export type InputSelectProps = ButtonSelectProps<MenuItemResponse> &
  InputProps & {
    options: { text: string; value: MenuItemResponse }[];
  };

export default React.forwardRef(function InputSelect(
  props: InputSelectProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: StyledInputSelect,
    ...props.slots,
  };
  const internalRef = useRef<HTMLButtonElement>(null);
  const widthModal = useWidthRef(internalRef);

  return (
    <ButtonSelect
      {...props}
      slotProps={{
        root: { ...(props.slotProps?.root as any), ref: internalRef },
        listbox: { ...props.slotProps?.listbox, width: widthModal } as any,
      }}
      ref={ref}
      slots={slots}
    />
  );
});
