import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from '../../../assets/settings.svg';
import { ReactComponent as ChangeLayoutIcon } from '../../../assets/operator_device_layout.svg';
import { ReactComponent as StoreIcon } from '../../../assets/store.svg';
import { ReactComponent as ThemeIcon } from '../../../assets/theme.svg';
import { ReactComponent as AccountIcon } from '../../../assets/account.svg';
import { useTranslation } from 'react-i18next';
import Button from '../../../theme/ui/Atoms/Button';
import Switch from '../../../theme/ui/Atoms/Switch';
import { FeaturesActions } from '../../../redux/features/slice/FeaturesSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store.model';
import {
  getFeatureToggleOperatorView,
  getTheme,
} from '../../../redux/features/selectors';
import { logout } from '../../../redux/auth/action/thunks';
import { getTypeUserSelector } from '../../../redux/auth/selectors';
import packageJson from '../../../../package.json';
import { LabelIcon } from '../../../assets';
import { SettingItem } from './SettingItem';
import TemperatureSettingItem from './TemperatureSettingItem';

const ThemeIconStyled = styled(ThemeIcon)(
  ({ theme }) => `
stroke: ${theme.palette.text.primary};`
);

const AccountIconStyled = styled(AccountIcon)(
  ({ theme }) => `
  stroke: ${theme.palette.text.primary};`
);

const SettingsIconStyled = styled(SettingsIcon)(
  ({ theme }) => `
  fill: ${theme.palette.text.primary};`
);

const ChangeLayoutIconStyled = styled(ChangeLayoutIcon)(
  ({ theme }) => `
  stroke: ${theme.palette.text.primary};`
);

export default function Settings() {
  const { t } = useTranslation();
  const themeMode = useAppSelector(getTheme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const typeUser = useAppSelector(getTypeUserSelector);
  const operatorView = useAppSelector(getFeatureToggleOperatorView);
  const changeTheme = () => {
    themeMode === 'light'
      ? dispatch(FeaturesActions.changeFeaturesTheme('dark'))
      : dispatch(FeaturesActions.changeFeaturesTheme('light'));
  };

  const toggleOperatorView = () => {
    dispatch(FeaturesActions.toggleOperatorView());
  };

  const onClickOpeningTimes = () => {
    navigate('/settings/opening-times');
  };

  const onClickInitialSetup = () => {
    navigate('/settings/initial-setup');
  };

  const onClickLogout = async () => {
    await dispatch(logout());
    navigate('/login');
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="subtitle2"
        color={'primary'}
        marginY={2}
        sx={{
          fill: (theme) => theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box component={'span'} marginRight={4}>
          <SettingsIcon height={'2em'} />
        </Box>
        {t('settings.title')}
      </Typography>
      {typeUser === 'operator' ? (
        <SettingItem
          IconComponent={StoreIcon}
          title={t('opening_times.title')}
          action={
            <Button
              variant="contained"
              rounded
              onClick={onClickOpeningTimes}
              fullWidth
            >
              {t('opening_times.configure')}
            </Button>
          }
        />
      ) : null}

      {typeUser === 'manager' ? (
        <SettingItem
          IconComponent={SettingsIconStyled}
          title={t('initial_setup.title')}
          action={
            <Button
              variant="contained"
              rounded
              onClick={onClickInitialSetup}
              fullWidth
            >
              {t('initial_setup.configure')}
            </Button>
          }
        />
      ) : null}

      {typeUser === 'operator' ? (
        <SettingItem
          IconComponent={LabelIcon}
          title={t('initial_setup.title_update')}
          action={
            <Link to={'./update-name/devices'}>
              <Button variant="contained" rounded fullWidth>
                {t('initial_setup.configure')}
              </Button>
            </Link>
          }
        />
      ) : null}

      <SettingItem
        IconComponent={ThemeIconStyled}
        title={t('settings.theme.title')}
        action={
          <Switch
            checked={themeMode === 'dark'}
            onChange={changeTheme}
            label={t(`settings.theme.${themeMode}`)}
          />
        }
      />

      {typeUser === 'operator' ? (
        <>
          <SettingItem
            IconComponent={ChangeLayoutIconStyled}
            title={t('settings.feature.OPERATOR_DEVICE_SIMPLE_VIEW')}
            action={
              <Switch
                checked={operatorView === 'HORIZONTAL'}
                onChange={toggleOperatorView}
                label={t(
                  `settings.feature.OPERATOR_DEVICE_SIMPLE_VIEW_${
                    operatorView === 'HORIZONTAL' ? 'HORIZONTAL' : 'VERTICAL'
                  }`
                )}
              />
            }
          />
          <TemperatureSettingItem />
        </>
      ) : null}

      <SettingItem
        IconComponent={AccountIconStyled}
        title={t('settings.session')}
        action={
          <Button variant="contained" rounded onClick={onClickLogout} fullWidth>
            {t('login.logout')}
          </Button>
        }
      />
      <Typography
        variant="body2"
        color={'GrayText'}
      >{`v${packageJson.version}`}</Typography>
    </Container>
  );
}
