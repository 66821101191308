import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AsyncTaskSchedule,
  AsyncTaskScheduleDevice,
  AsyncTaskScheduleUpdateRequiredDictionary,
  AsyncTaskDeviceUpdateRequiredDictionary,
  nameReducer,
} from './AsyncTasksSlice.model';
import { setScheduleRequireUpdateAsyncThunk } from '../actions/asyncThunks';
import { set } from 'lodash';
import moment from 'moment';
import { POST_REHYDRATE } from '../../../../../../redux/middleware/postRehydrate';

const initialState = {
  /** like `{'deviceId-1': {"lastSchedule":{skip: true, success: false, timestamp: 1690285490220}}}` */
  schedule: {} as AsyncTaskSchedule,
  scheduleUpdateRequired: {} as AsyncTaskScheduleUpdateRequiredDictionary,
  zoneSettingsUpdateRequiredSkip: {} as AsyncTaskDeviceUpdateRequiredDictionary,
  openingTimesUpdateRequiredSkip: {} as AsyncTaskDeviceUpdateRequiredDictionary,
};
/**
 * Create Slice for AsyncTasks
 * to save if there are any async tasks dismissed or preformed
 */
const AsyncTasksSlice = createSlice({
  name: nameReducer,
  initialState,

  reducers: {
    /**
     * Set last schedule for device
     * @param state
     * @param action
     * @param action.payload
     * @param action.payload.deviceId
     * @param action.payload.lastSchedule
     * @returns void
     * @example
     * dispatch(activateLastSchedule({deviceId: 'deviceId-1', lastSchedule: {skip: true, success: false, timestamp: 1690285490220, activateAt: 1690285490, scheduleId: 'scheduleId-1', partId: 'partId-1'}}))
     * // state.schedule = {'schedule': {"deviceId-1":{skip: false, success: true, timestamp: 1690285490220, activateAt: 1690285490, scheduleId: 'scheduleId-1', partId: 'partId-1'}}}
     *
     */
    setLastSchedule: (
      state,
      action: PayloadAction<{
        deviceId: string;
        taskSchedule: AsyncTaskScheduleDevice;
      }>
    ) => {
      const { deviceId, taskSchedule } = action.payload;
      if (!state.schedule) state.schedule = {};
      if (!state.schedule[deviceId]) state.schedule[deviceId] = [];
      state.schedule[deviceId].push(taskSchedule);
      /** remove elements more than a week */
      state.schedule[deviceId] = state.schedule[deviceId].filter(
        (task) => task.timestamp > Date.now() - 7 * 24 * 60 * 60 * 1000
      );
    },
    skipScheduleRequireUpdate(
      state,
      action: PayloadAction<
        {
          deviceId: string;
          scheduleId: string | null;
        }[]
      >
    ) {
      const now = moment();
      const timestamp = now.valueOf();
      const dismissUntil = now.clone().add(1, 'day').startOf('day').valueOf();
      action.payload.forEach((task) => {
        const { deviceId, scheduleId } = task;
        set(state, ['scheduleUpdateRequired', deviceId], {
          timestamp,
          scheduleId,
          skip: true,
          dismissUntil,
        });
      });
    },
    skipZoneSettingsUpdateRequired(state, action: PayloadAction<string[]>) {
      const now = moment();
      const timestamp = now.valueOf();
      const dismissUntil = now.clone().add(1, 'day').startOf('day').valueOf();
      action.payload.forEach((deviceId) => {
        set(state, ['zoneSettingsUpdateRequiredSkip', deviceId], {
          timestamp,
          skip: true,
          dismissUntil,
        });
      });
    },
    skipOpeningTimeUpdateRequired(state, action: PayloadAction<string[]>) {
      const now = moment();
      const timestamp = now.valueOf();
      const dismissUntil = now.clone().add(1, 'day').startOf('day').valueOf();
      action.payload.forEach((deviceId) => {
        set(state, ['openingTimesUpdateRequiredSkip', deviceId], {
          timestamp,
          skip: true,
          dismissUntil,
        });
      });
    },
  },

  extraReducers: (builder) => {
    // setScheduleRequireUpdateAsyncThunk
    builder.addCase(
      setScheduleRequireUpdateAsyncThunk.fulfilled,
      (state, action) => {
        const { deviceId, scheduleId } = action.meta.arg;
        const now = moment();
        const timestamp = now.valueOf();

        set(state, ['scheduleUpdateRequired', deviceId], {
          timestamp,
          scheduleId,
          success: true,
          dismissUntil: undefined,
          loading: false,
        });
      }
    );
    builder.addCase(
      setScheduleRequireUpdateAsyncThunk.pending,
      (state, action) => {
        const { deviceId, scheduleId } = action.meta.arg;
        const now = moment();
        const timestamp = now.valueOf();

        set(state, ['scheduleUpdateRequired', deviceId], {
          timestamp,
          scheduleId,
          success: undefined,
          dismissUntil: undefined,
          loading: true,
        });
      }
    );
    builder.addCase(
      setScheduleRequireUpdateAsyncThunk.rejected,
      (state, action) => {
        const { deviceId, scheduleId } = action.meta.arg;
        const now = moment();
        const timestamp = now.valueOf();

        set(state, ['scheduleUpdateRequired', deviceId], {
          timestamp,
          scheduleId,
          success: false,
          dismissUntil: undefined,
          loading: false,
          error: action.error,
        });
      }
    );

    builder.addCase(POST_REHYDRATE, (state, action) => {
      Object.values(state.scheduleUpdateRequired ?? {}).forEach((task) => {
        delete task.loading;
        delete task.error;
      });
    });
  },
});

export default AsyncTasksSlice;
export const { actions: asyncTasksActions } = AsyncTasksSlice;
