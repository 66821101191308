/**
 * NetworkSlice is a redux slice that contains the network state
 *
 * @see https://redux-toolkit.js.org/api/createSlice
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NetworkState {
  networkState: 'online' | 'offline';
}
const initialState: NetworkState = {
  networkState: 'online',
};

export const NetworkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetworkState: (
      state,
      action: PayloadAction<NetworkState['networkState']>
    ) => {
      state.networkState = action.payload;
    },
  },
});

export const { actions: actionsNetwork } = NetworkSlice;
