import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import HttpApiBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import deTranslation from './locales/de/translation.json';
import enTranslation from './locales/en/translation.json';
import esTranslation from './locales/es/translation.json';
import frTranslation from './locales/fr/translation.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  // avoid use HttpApiBackend to cache easily the translations
  // .use(HttpApiBackend)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      de: {
        translation: deTranslation,
      },
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      fr: {
        translation: frTranslation,
      },
    },
  });
