import React from 'react';
import Box from '@mui/material/Box';
import Container, { ContainerTypeMap } from '@mui/material/Container';

export interface ContainerFloatCenterProps {
  children: React.ReactNode;
  /**
   * Determine the max-width of the container.
   * The container width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   * @default 'sm'
   */
  maxWidth?: ContainerTypeMap['props']['maxWidth'];
}
export default function ContainerFloatCenter(props: ContainerFloatCenterProps) {
  const { maxWidth = 'sm', children } = props;
  return (
    <Box
      className="FlexWrap"
      display="grid"
      justifyContent="center"
      alignItems="center"
      height={'100%'}
      gridAutoColumns="1fr"
      overflow="auto"
      paddingY={4}
    >
      <Container maxWidth={maxWidth}>{children}</Container>
    </Box>
  );
}
