import React from 'react';
import { useUID } from 'react-uid';
import InputUnstyled, { InputUnstyledProps } from '@mui/base/InputUnstyled';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import { alpha } from '@mui/system';
import Box, { BoxProps } from '@mui/system/Box';
import { styled, useThemeProps } from '@mui/material/styles';
import composeClasses from '@mui/base/composeClasses';
import { shouldForwardProp } from '../../util/shouldForwardProps';

const useUtilityClasses = (props: InputProps) => {
  const { error, classes } = props;
  const slots = {
    root: ['root', error && 'error'],
    label: ['label', error && 'error'],
    input: ['input', error && 'error'],
    helperText: ['helperText', error && 'error'],
  };

  const composedClasses = composeClasses(
    slots,
    (slot) => `FSInput-${slot}`,
    classes
  );

  return {
    ...classes, // forward the focused, disabled, etc. classes to the ButtonBase
    ...composedClasses,
  };
};

const StyledInputElement = styled('input')(
  ({ theme }) => `
  line-height: 1.5;
  flex-grow: 1;
  font-weight: inherit;
  background: inherit;
  color: inherit;
  border: none;
  border-radius: inherit;
  margin: 0;
  outline: 0;
  width: 100%;
`
);

interface OwnInputProps {
  label?: string | null;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  slots?: InputUnstyledProps['slots'] & { label?: React.ElementType };
  slotProps?: InputUnstyledProps['slotProps'] & { label?: any };
  classes?: Record<keyof InputUnstyledProps['slots'], string>;
  sx?: BoxProps['sx'];
  sxInput?: BoxProps['sx'];
  ownerState?: InputUnstyledProps | any;
  InputProps?: InputUnstyledProps;
  inputProps?: InputUnstyledProps;
  placeholder?: InputUnstyledProps['placeholder'];
  value?: InputUnstyledProps['value'];
  defaultValue?: InputUnstyledProps['defaultValue'];
  required?: boolean;
  id?: string;
  inputRef?: InputUnstyledProps['inputRef'];
  [string: string]: any;
}
export type InputProps = OwnInputProps;

const Input = React.forwardRef(function CustomInput(
  inProps: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const props = useThemeProps({ props: inProps, name: 'FSInput' });
  const uniqueId = useUID();
  const id = props.id || `FSInput-${uniqueId}`;
  const name = props.name || id;
  const { input = StyledInputElement, label: Label = FormLabel } =
    props.slots || {};
  const slotsInput = { input, root: StyledInputRoot };
  const classes = useUtilityClasses(props);

  return (
    <StyledContainerRoot
      className={classes.root}
      sx={props.sx}
      fullWidth={props.fullWidth}
    >
      {props.label && (
        <Label htmlFor={id} className={classes.label} required={props.required}>
          {props.label}
        </Label>
      )}
      <InputUnstyled
        id={id}
        name={name}
        slots={slotsInput}
        ref={props.inputRef || ref}
        slotProps={{
          input: {
            className: classes.input,
            ...(props as any),
            ...props.inputProps,
            ...props.InputProps,
            sx: props.sxInput,
            ownerState: props,
          },
        }}
        {...(props as any)}
        {...props.InputProps}
      />
      {props.helperText && (
        <Typography
          color={props.error ? 'error' : undefined}
          sx={{ lineHeight: '2rem' }}
          className={classes.helperText}
        >
          {props.helperText}
        </Typography>
      )}
    </StyledContainerRoot>
  );
});

export default Input;

const StyledContainerRoot = styled(Box, {
  shouldForwardProp,
})<InputProps>(({ theme, ...props }) => ({
  ...(props.fullWidth && {
    width: '100%',
  }),
}));
const StyledInputRoot = styled('div')<InputProps>(({ theme, ...props }) => {
  const styles = `
  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid transparent;
  font-weight: 800;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  padding: 6px;
  border-radius: 12px;
  color: ${
    theme.palette.mode === 'dark'
      ? theme.palette.grey[200]
      : theme.palette.grey[900]
  };
  font-weight: 600;

  background: transparent;
  border: ${theme.palette.mode === 'dark' ? '3px' : '2px'} solid ${
    theme.palette.mode === 'dark' ? theme.palette.grey[200] : '#C6C6C6'
  };

  & input::placeholder {
    color:  ${
      theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#C6C6C6'
    };
  }


  &:hover {
    border-color: ${alpha(
      theme.palette.primary.main,
      1 - theme.palette.action.hoverOpacity
    )};
  }

  &:focus {
    border-color:  ${theme.palette.primary.main}!important;
  }

  /** FIXME: focused **/
  & .Mui-focused {
    border-color:  ${theme.palette.primary.main}!important;
  }
  ${
    props.ownerState?.slotProps?.input.value && theme.palette.mode === 'dark'
      ? `
    background: ${theme.palette.grey[200]};
    color: ${theme.palette.grey[900]};
    svg {
      color: ${theme.palette.grey[900]};
    }`
      : ''
  }
  ${
    props.ownerState?.slotProps?.input.disabled
      ? `
    border-color: #9E9E9E!important;
    color: ${theme.palette.grey[300]};
    -webkit-text-fill-color: ${theme.palette.grey[300]};
    background: #9E9E9E;
  `
      : ''
  }
  ${
    props.ownerState?.slotProps?.input.error
      ? `border-color: ${theme.palette.error.main};`
      : ''
  }

  ${props.fullWidth ? `width: 100%;` : ''}


`;

  return styles;
});
