import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';

/**
 * The AppBar elevates on scroll to communicate that the user is not at the top of the page.
 * So AppBar need know when is scrolled se more here:
 * <https://material-ui.com/components/app-bar/#elevate-app-bar>
 *
 * @param target DOM to add scroll listener
 * @returns wrapped object
 */
export default function ElevationScroll(props: {
  children: React.ReactElement;
  target: any;
}) {
  const { children, target } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: target ? target : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
