import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../../service/auth/AuthService';
import { nameReducer } from '../Auth.model';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const loginAThunk = createAsyncThunk(
  `${nameReducer}/authenticate`,
  async (props: { storeId: string; password: string }) => {
    const { password, storeId } = props;
    const response = await AuthService.login(storeId, password);

    return response;
  }
);

/** use the same name because will replace the values of login */
export const refreshTokenAThunk = createAsyncThunk(
  `${nameReducer}/authenticate`,
  async (props: { refreshToken: string }) => {
    const { refreshToken } = props;
    const response = await AuthService.refreshToken(refreshToken);

    return response;
  }
);
