import { Box, styled } from '@mui/material';
import React from 'react';

// in desktop, we want to have two panels, one for the image and one for the content
// in mobile, we want to have one panel, with the image on top and the content on the bottom
export const TwoPanelContentContatiner = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: '"left right"',
  height: '100%',
  minWidth: '100%',

  [theme.breakpoints.down('md')]: {
    gridTemplateAreas: '"left" "right"',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    height: 'initial',
  },
}));

// In the left content, we want an image centered inte middle of the content
export const TwoPanelContainerLeft = styled(Box)({
  gridArea: 'left',
  height: '100%',
  width: '100%',
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TwoPanelContainerRight = styled(Box)(({ theme }) => ({
  gridArea: 'right',
  overflow: 'auto',
  width: '100%',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    overflow: 'initial',
  },
}));

export const TwoPanelContentLeft = styled(Box)(({ theme }) => ({
  minWidth: '260px',
  textAlign: 'center',
  padding: theme.spacing(3),
}));

export const TwoPanelContentRight = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(3),
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
export interface TwoPanelContentProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}
export function TwoPanelContent(props: TwoPanelContentProps) {
  const { leftContent, rightContent } = props;
  return (
    <TwoPanelContentContatiner>
      <TwoPanelContainerLeft>
        <TwoPanelContentLeft>{leftContent}</TwoPanelContentLeft>
      </TwoPanelContainerLeft>
      <TwoPanelContainerRight>
        <TwoPanelContentRight>{rightContent}</TwoPanelContentRight>
      </TwoPanelContainerRight>
    </TwoPanelContentContatiner>
  );
}

export default TwoPanelContent;
