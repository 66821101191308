import React from 'react';
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// UI Libs
import './index.css';
import { Provider } from 'react-redux';
import ThemeProviderWithTheme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './shared/i18n/init';

// Libs
import './polyfills';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './redux/Store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <Box textAlign={'center'} margin={2}>
          <CircularProgress />
        </Box>
      }
      persistor={persistor}
    >
      <ThemeProviderWithTheme>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </ThemeProviderWithTheme>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Silent update of the service worker, so the app doesn't have to reload the page
    // to get the latest version of the app (if the service worker is updated) the user needs to reload the page.
    // at the moment we don't have/want a way to show a message to the user to reload the page
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  },
});
