import { createSlice } from '@reduxjs/toolkit';
import { loginAThunk } from '../action/thunksAsync';
import { nameReducer } from '../Auth.model';
import { IAuthInfo } from '../../../service/auth/AuthService.model';
import Storage from '../../../shared/Storage';
import Environment from '../../../shared/constants/Environment';

export const initialStateAuth: AuthState = {
  authInfo: null,
};
export interface AuthState {
  authInfo?: IAuthInfo | null;
}

export const AuthSlice = createSlice({
  name: nameReducer,
  initialState: initialStateAuth,
  reducers: {},
  extraReducers: (builder) => {
    // loginAThunk Thunk
    builder.addCase(loginAThunk.fulfilled, (state, action) => {
      state.authInfo = action.payload;
      /**
       * Use Storage to avoid refreshToken invalid and token expiration when the app is used in different tabs
       */
      Storage.setItem(Environment.authInfoKey, action.payload);
    });
  },
});

export const {
  actions: authActions,
  reducer: authReducer,
  name: authNameReducer,
} = AuthSlice;
