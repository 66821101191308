import React from 'react';
import Paper from '@mui/material/Paper';
import ContainerFloatCenter from '../ContainerFloatCenter';
import { ContainerFloatCenterProps } from '../ContainerFloatCenter/ContainerFloatCenter';

export interface PaperFloatCenterProps extends ContainerFloatCenterProps {}
export default function PaperFloatCenter(props: PaperFloatCenterProps) {
  return (
    <ContainerFloatCenter {...props}>
      <Paper elevation={5} sx={{ padding: 3 }}>
        {props.children}
      </Paper>
    </ContainerFloatCenter>
  );
}
