import StoreAPI from '../../StoreAPI';

export const getOpeningTimesByDeviceMutation =
  StoreAPI.endpoints.setOpeningTimesByDevice.select;

export const getOpeningTimesByStoreMutation =
  StoreAPI.endpoints.setOpeningTimesByDevice.select;

export const getOpeningTimesByStoreSelector =
  StoreAPI.endpoints.getOpeningTimesByStore.select;
