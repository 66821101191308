import moment from 'moment';
import 'moment-duration-format';

/**
 * getMiliseconds and return the string to show how many time left
 * @param timeInMilliseconds
 * @returns <string> "23:45:22" or "00:04:02"
 */
export default function getDuration(timeInMilliseconds: number) {
  return (moment.duration(timeInMilliseconds) as any).format('hh:mm:ss', {
    trim: false,
  });
}
