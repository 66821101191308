import {
  withWidthRefResize,
  WithWidthRefResizeProps,
} from '../HOC/withWidthRefResize';

interface WidthRefResizeProps extends WithWidthRefResizeProps {
  children?: (width: number) => any;
  render?: (width: number) => any;
}
function WidthRefResize(props: WidthRefResizeProps) {
  const render = props.render || props.children;
  if (!render)
    throw new Error(
      'You should define render prop or use children as a render prop'
    );
  return render(props.innerRefWidth);
}

export default withWidthRefResize(WidthRefResize);
