import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthCloudService from '../../../service/auth-cloud/AuthCloudService';
import { nameReducer } from '../AuthManager.model';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const loginManagerAThunk = createAsyncThunk(
  `${nameReducer}/authenticate`,
  async (props: { email: string; password: string }) => {
    const { password, email } = props;
    const response = await AuthCloudService.login(email, password);

    return response;
  }
);

/** use the same name because will replace the values of login */
export const refreshManagerTokenAThunk = createAsyncThunk(
  `${nameReducer}/authenticate`,
  async (props: { refreshToken: string }) => {
    const { refreshToken } = props;
    const response = await AuthCloudService.refreshToken(refreshToken);

    return response;
  }
);
