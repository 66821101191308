import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../theme/ui/Atoms/Button';
import RadioGroup from '../../../../theme/ui/Atoms/RadioGroup';

export default function InitialSetup() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options = [
    { value: 'zone', label: t('initial_setup.flexerserve_zone') },
    { value: 'hub', label: t('initial_setup.flexerserve_hub') },
  ];

  const [option, setOption] = useState('zone');

  const onClickBack = () => {
    navigate('/settings');
  };
  const onClickNext = () => {
    navigate(`/settings/initial-setup/fill-information/${option}`);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
    >
      <Container maxWidth="xs">
        <Paper elevation={5} sx={{ padding: 3 }}>
          <Typography variant="h1">{t('initial_setup.title')}</Typography>

          <Typography variant="subtitle2" sx={{ mt: 4 }} textAlign="center">
            {t('initial_setup.description')}
          </Typography>
          <Box textAlign={'center'} mt={3}>
            <RadioGroup
              label={t('initial_setup.select_flexeserve')}
              onChange={(e, v) => setOption(v)}
              value={option}
              options={options}
            />
          </Box>

          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained-gray"
                rounded
                fullWidth
                onClick={onClickBack}
              >
                {t('button.back')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                rounded
                fullWidth
                onClick={onClickNext}
              >
                {t('button.next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
