/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

/**
 * function to get string error message from error object or string
 *
 * @returns `parseErrorMessage` function => string
 * @usage
 * ```ts
 * const parseErrorMessage = useParseErrorMessage()
 * parseErrorMessage(axiosError)
 * parseErrorMessage('Ups, something went wrong')
 * ```
 */
export function useParseErrorMessage() {
  const { t } = useTranslation();
  return useCallback(
    (error: AxiosError | string | unknown) => {
      const message =
        typeof error === 'string' ? error : (error as AxiosError)?.message;
      return t(message);
    },
    [t]
  );
}

export default useParseErrorMessage;
