import { AxiosInstance } from 'axios';
import Environment from '../../shared/constants/Environment';
import { AuthInfo } from '../auth/AuthService.model';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import { objectToFormURLEncoded } from '../shared/objectToFormURLEncoded';
import {
  TokenAPIResponse,
  AuthorizeRedirectionAPIResponse,
} from './AuthCloudService.model';

/**
 * @description This service is responsible for all the authentication related
 * operations.
 */
class AuthCloudService {
  private readonly baseUrl = 'auth';
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  async refreshToken(refreshToken: string) {
    const tokenURL = `${this.baseUrl}/token?grant_type=refresh_token&refresh_token=${refreshToken}&redirect_uri=/&client_id=${Environment.clientID}&client_secret=${Environment.clientSecret}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.axios
      .post<TokenAPIResponse>(tokenURL, objectToFormURLEncoded({}), {
        headers,
      })
      .then((response) => new AuthInfo(response.data).getPayload());
  }

  async getToken(authCode: string) {
    const tokenURL = `${this.baseUrl}/token?grant_type=authorization_code&code=${authCode}&redirect_uri=/&client_id=${Environment.clientID}&client_secret=${Environment.clientSecret}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.axios
      .post<TokenAPIResponse>(tokenURL, objectToFormURLEncoded({}), {
        headers,
      })
      .then((response) => new AuthInfo(response.data).getPayload());
  }

  async login(email: string, password: string) {
    const redirectUri = `${Environment.backendURL}/api/${this.baseUrl}/redirect`;
    const authorizeUrl = `${this.baseUrl}/authorize?response_type=code&client_id=${Environment.clientID}&redirect_uri=${redirectUri}`;
    const body = {
      email,
      password,
    };

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.axios
      .post<AuthorizeRedirectionAPIResponse>(
        authorizeUrl,
        objectToFormURLEncoded(body),
        { headers }
      )
      .then((response) => response?.data?.Authcode)
      .then((authCode) => this.getToken(authCode));
  }
}

export default new AuthCloudService();
