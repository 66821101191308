import { useMemo } from 'react';
import { useAppSelector } from '../../../../redux/store.model';
import { TIME_REFRESH_NOW } from '../../Operator/AsyncTasks/constants';
import moment from 'moment';
import { useTick } from '../../../../shared/hooks/useTick';
import { getOpeningTimesUpdateRequiredState } from '../../Operator/AsyncTasks/redux/selectors';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../../../../service/store/Store.constants';
import StoreAPI from '../../../../service/store/StoreAPI';
import useWatchError from '../../../../shared/hooks/useWatchError';
import useDevicesOperator from '../../../../service/store/hooks/useDevicesOperator';

export default function useDeviceWithRequireUpdateOpeningTimes() {
  // const errorMessage = useErrorMessage();
  // const dispatch = useAppDispatch();
  const {
    data: remoteOpeningTimes,
    isLoading: openingTimesLoading,
    error: openingTimesError,
  } = StoreAPI.useGetOpeningTimesByStoreQuery(undefined, {
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
  });
  const [
    updateOpiningTimesStore,
    { error: updateOpiningTimesStoreError, isLoading: openingTimesLoadingSet },
  ] = StoreAPI.useSetOpeningTimesByStoreMutation();
  useWatchError(openingTimesError || updateOpiningTimesStoreError);

  const { devices, isFetching: isFetchingDevices } = useDevicesOperator();

  const devicesWithUpdateRequired = useMemo(
    () =>
      Object.entries(remoteOpeningTimes?.devices || {}).map(
        ([deviceId, value]) => ({
          device: devices.find((device) => device.uuid === deviceId),
          deviceId,
          ...value,
        })
      ),
    [devices, remoteOpeningTimes]
  );

  const devicesWithUpdateRequiredConnected = useMemo(
    () =>
      devicesWithUpdateRequired.filter(
        ({ device }) => device?.deviceState?.mqttConnected
      ),
    [devicesWithUpdateRequired]
  );
  const hasSomeDeviceDisconnected = !!(
    devicesWithUpdateRequired.length - devicesWithUpdateRequiredConnected.length
  );

  const now = useTick(() => moment().valueOf(), TIME_REFRESH_NOW);
  const zoneSettingsUpdateRequiredState = useAppSelector(
    getOpeningTimesUpdateRequiredState
  );
  const devicesNeedRequired = useMemo(
    () =>
      devicesWithUpdateRequiredConnected

        // filter device required update
        .filter(({ updateRequired }) => updateRequired === true)
        // filter device not skipped
        .filter(({ deviceId }) => {
          const taskAsyncState = zoneSettingsUpdateRequiredState[deviceId];
          const isSkipped =
            taskAsyncState?.skip &&
            taskAsyncState?.dismissUntil &&
            taskAsyncState?.dismissUntil > now;
          return !isSkipped;
        }),
    [devicesWithUpdateRequiredConnected, now, zoneSettingsUpdateRequiredState]
  );

  return {
    loading: isFetchingDevices || openingTimesLoading || openingTimesLoadingSet,
    openingTimesLoadingSet,
    devicesNeedRequired,
    hasSomeDeviceDisconnected,
    devicesWithUpdateRequiredConnected,
    devicesWithUpdateRequired,
    remoteOpeningTimes,
    updateOpiningTimesStore,
  } as const;
}
