import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../controlUnit.model';
import { MonitoringCalls } from '../../../service/monitoring/MonitoringCalls';
import ControlUnitCalls from '../../../service/controlUnit/ControlUnitCalls';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchDeviceControlUnits = createAsyncThunk(
  `${nameReducer}/fetchDeviceControlUnits`,
  async (deviceId: string) => {
    const controlUnitCalls = new ControlUnitCalls();

    return await controlUnitCalls.getControlUnitsByDeviceId(deviceId);
  }
);

export const fetchCurrentEventsControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentEventsControlUnits`,
  async (deviceId: string) => {
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentEventsByID(deviceId);
    return response;
  }
);

export const fetchCurrentSensorDataControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentSensorDataControlUnits`,
  async (deviceId: string) => {
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentSensorDataByID(deviceId);
    return response;
  }
);
