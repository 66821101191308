import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import { MenuItemsResponse } from '../store/modules/zone/ZoneService.model';
import { MenuItemEditResponse } from './TimerSettingsAPI.model';
import menuItemListToDic from './serialize/menuItemListToDic';

const TimerSettingsAPI = createApi({
  reducerPath: 'menuItems',
  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getMenuItems: build.query<MenuItemsResponse, string>({
        query: (regionId: string) => {
          return {
            url: `group/${regionId}/menuitems`,
            method: 'get',
          };
        },
      }),

      setMenuItems: build.mutation<
        any,
        {
          menuItems: MenuItemEditResponse[];
          regionId: string;
        }
      >({
        query: ({ menuItems, regionId }) => {
          const menuItemsToSet = menuItemListToDic(menuItems);
          return {
            url: `group/${regionId}/menuitems`,
            method: 'put',
            data: menuItemsToSet,
          };
        },
      }),
    };
  },
});

export default TimerSettingsAPI;

export const { useGetMenuItemsQuery, useSetMenuItemsMutation } =
  TimerSettingsAPI;
