import {
  MenuItemResponse,
  MenuItemsResponse,
  ZoneResponse,
} from '../../zone/ZoneService.model';

export default function getTimerOptions(
  menuItems?: MenuItemsResponse,
  zone?: ZoneResponse
): { text: string; value: MenuItemResponse }[] {
  if (menuItems) {
    if (zone)
      menuItems = menuItems?.filter(
        (item) => item.setting === zone.activeSetting
      );
    return menuItems.map((menuItem) => ({
      value: menuItem,
      text: menuItem.displayName || menuItem.itemName,
    }));
  }
  return [];
}
