import { TEMPERATURE_UNIT_TYPES } from '../../../temperaturePreference';

export type POSSIBLE_ZONES = '1' | '2' | '3' | '4' | '5';

/**
 * Example: 
```json
{
    "1": {
        "activeSetting": "OFF",
        "shelfStatus": {
            "operatingMode": "OFF",
            "deviceOn": false,
            "doorState": "CLOSED",
            "temperatureSetpoint": 0
        }
    },
    "2": {
        "activeSetting": "LIGHTS_ONLY",
        "shelfStatus": {
            "operatingMode": "OK",
            "deviceOn": true,
            "doorState": "CLOSED",
            "temperatureSetpoint": 0
        }
    },
    "3": {
        "activeSetting": "STANDARD_LOW",
        "shelfStatus": {
            "operatingMode": "TRANSITIONING",
            "deviceOn": true,
            "doorState": "CLOSED",
            "temperatureSetpoint": 40
        }
    },
    "4": {
        "shelfStatus": {
            "operatingMode": "UNUSED",
            "deviceOn": false,
            "doorState": "CLOSED",
            "temperatureSetpoint": 0
        }
    },
    "5": {
        "shelfStatus": {
            "operatingMode": "UNUSED",
            "deviceOn": false,
            "doorState": "CLOSED",
            "temperatureSetpoint": 0
        }
    }
}
```
 */
export interface ZonesResponse {
  '1': ZoneResponse;
  '2': ZoneResponse;
  '3': ZoneResponse;
  '4': ZoneResponse;
  '5': ZoneResponse;
}

export interface ZoneResponse {
  activeSetting: POSSIBLE_SETTINGS;

  shelfStatus: {
    operatingMode:
      | 'OFF'
      /*  (treat as if zone would not be present; usually not reported at all)*/
      | 'UNUSED'
      /*  (zone is in target range of its setting specs)*/
      | 'OK'
      /*  (zone is not in target range of its setting specs, i.e. current temperature value at setpoint, accounting for hysteresis also contained in recipe)*/
      | 'TRANSITIONING'
      /*  (zone has an error; to further investigate, use error bits as reported via normal cloud messages or history message)*/
      | 'ERROR'
      /*  (treat same as error; currently not set)*/
      | 'ERROR_CU_MISMATCH';
    deviceOn: boolean;
    /**
     * Usually, only one CU in a cabinet has the door input activated at all(?),
     * so if this should be visualized (I don't think this is the case),
     * it would be based on the predicate "any zone has door error"  */
    doorState:
      | 'CLOSED'
      | 'OPEN'
      /**  (door has been open for longer than a parameterizable CU parameter) */
      | 'ERROR';
    /**
     * always in degrees Celsius; convert in UI if required
     * under normal operation, should match what is specified in the settings
     * but might have been temporarily overwritten:
     * either via direct parameter manipulation
     * or by changing the USER1/USER2 recipe locally */
    temperatureSetpoint: number;
    /**  (If missing -> same as empty) This is an array containing string IDs of (a subset of)
     * alarms present for this zone; known entries that are relevant in the context of temperature
     * errors (as requested by the customer) are "overtemp", "undertemp" and "SetpointNotReached"
     * (i.e. presence of one of these indicate that the frontend should display the error condition
     * as a temperature error and not a general error). Further alarms known at this time, not related
     * to temperature, are "Service" (indicating required service/maintenance) and "_disconn" (indicating
     *  controller disconnection / F90). */
    alarms: POSSIBLE_ALARMS[];
  };
}

export enum POSSIBLE_ALARMS {
  SETPOINT_NOT_REACHED = 'SetpointNotReached',
  OVERTEMP = 'overtemp',
  UNDERTEMP = 'undertemp',
  SERVICE = 'Service',
  DISCONN = '_disconn',
}
export type POSSIBLE_SETTINGS =
  | 'OFF'
  | 'LIGHTS_ONLY'
  | 'STANDARD_LOW'
  | 'STANDARD_MEDIUM'
  | 'STANDARD_HIGH'
  | 'USER1'
  | 'USER2'
  /** (indicating that this zone has a non-cloud controlled setting active; only device -> cloud comm, NOOP for cloud -> device) */
  | 'CUSTOM'
  /** Could be any setting  http://jira.st.local/jira/browse/NUTOLED-439 */
  | string;

/**
 * Example: 
```json
{
  "configured": {
      "STANDARD_LOW": {
          "setpoint": 25,
          "displayName": "STANDARD LOW",
          "visible": true
      },
      "USER1": {
          "setpoint": 25,
          "displayName": "USER1",
          "visible": false
      },
      "USER2": {
          "setpoint": 25,
          "displayName": "USER2",
          "visible": false
      },
      "STANDARD_MEDIUM": {
          "setpoint": 65,
          "displayName": "STANDARD MEDIUM",
          "visible": true
      },
      "STANDARD_HIGH": {
          "setpoint": 85,
          "displayName": "STANDARD HIGH",
          "visible": true
      }
  },
  "updateRequired": true,
  "reported": {}
}
```
 */
export interface ZoneSettingsResponse {
  configured: Record<
    | 'STANDARD_LOW'
    | 'USER1'
    | 'USER2'
    | 'STANDARD_MEDIUM'
    | 'STANDARD_HIGH'
    | string,
    ZoneSettingResponse | undefined
  >;
  updateRequired: boolean;
  reported: unknown;
}

export interface ZoneSettingResponse {
  /** value of setPoint */
  setpoint: number;
  /** displayName used to indicated who is configured in zone, is like enum */
  displayName: string;
  visible: boolean;
  temperatureUnit: TEMPERATURE_UNIT_TYPES;
  canDelete: boolean;
  canEditName: boolean;
  canEditSetpoint: boolean;
}

/**
 * Example: 
```json
[
    {
        "uuid": "0319338b-a124-48cf-8541-5a06d2cbe9ea",
        "parent": "device:9b013d40-3297-4ba7-adba-2f9890846002",
        "itemName": "TEST MENU ITEM 2",
        "displayName": "Test Menu Item 2",
        "holdTime": 2700,
        "prepTime": 300,
        "setting": "STANDARD_LOW"
    },
    {
        "uuid": "94c081b7-b4ae-4942-931f-dddcdb21af1f",
        "parent": "group:2b4e71c6-de44-4383-91ae-d58519246ff5",
        "itemName": "TEST MENU ITEM 3",
        "displayName": "Test Menu Item 3",
        "holdTime": 2700,
        "prepTime": 300,
        "setting": "STANDARD_LOW"
    }
]
```
 */
export type MenuItemsResponse = MenuItemResponse[];
export interface MenuItemResponse {
  /** cloud be a device or group. e.g. `group:2b4e71c6-de44-4383-91ae-d58519246ff5` `device:9b013d40-3297-4ba7-adba-2f9890846002` */
  parent: string;
  itemName: string;
  displayName: string;
  holdTime: number;
  prepTime: number;
  setting: ZoneSettingResponse['displayName'];
}

/**
 * Example:
 *  
```json
 {
    "1": [
        {
            "menuItemId": "TEST0002",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        {
            "menuItemId": "TEST0002",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        {
            "menuItemId": "TEST0001",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        null,
        null
    ],
    "2": [
        null,
        null,
        {
            "menuItemId": "TEST0001",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        null,
        null
    ],
    "3": [
        null,
        null,
        {
            "menuItemId": "TEST0001",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        null,
        {
            "menuItemId": "TEST0002",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        }
    ],
    "4": [
        null,
        null,
        {
            "menuItemId": "TEST0001",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        null,
        null
    ],
    "5": [
        null,
        null,
        {
            "menuItemId": "TEST0001",
            "timeStart": 1667815200,
            "timeEnd": 1667833200,
            "timePrepAlarm": 1667831400
        },
        null,
        null
    ]
}
```
 * 
 */
export type TimersResponse = Record<
  POSSIBLE_ZONES | string,
  (TimerItemResponse | null)[]
>;

/**
 * Example:
```json	
{
    "correlationID": "1669820198532-miuso6exVQjnUzmL"
}
```
 */
export interface ZoneSetResponse {
  correlationID: string;
}

/**
 * Example:
 * 
 ```json
 {
    "menuItemId":"TEST0002",
    "timeStart":1667815200,
    "timeEnd":1667833200,
    "timePrepAlarm":1667831400
}
```
 */
export interface TimerItemRequest {
  menuItemId: string;
  /** unix time */
  timeStart: number;
  /** unix time */
  timeEnd: number;
  /** unix time */
  timePrepAlarm: number;
}

/**
 * Could be one or multiple zones
 * Example:
```json
{
    "3":{
        "activeSetting":"LIGHTS_ONLY"
    },
    "4":{
        "activeSetting":"OFF"
    },
}
```
 */
export interface ZoneItemSetRequest {
  [zoneIndex: string]: {
    activeSetting: POSSIBLE_SETTINGS;
  };
}

export interface TimerItemResponse {
  menuItemId: string;
  // time in seconds since 1970
  timeStart: number;
  // time in seconds since 1970
  timeEnd: number;
  // time in seconds since 1970
  timePrepAlarm: number;
}
