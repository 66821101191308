import React, { useMemo } from 'react';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import Input from '../../../../theme/ui/Atoms/Input';
import { useFormik } from 'formik';
import GroupAPI from '../../../../service/group/GroupAPI';
import getOptionsFromGroupList from '../../../../service/group/serialize/getOptionsFromGroupList';
import InputSelect from '../../../../theme/ui/Atoms/InputSelect';
import LocationAPI from '../../../../service/locations/LocationAPI';
import useMessage from '../../../../shared/hooks/useMessage';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import serializeErrorInitialSetup from '../../../../service/locations/serialize/serializeErrorInitialSetup';
import { FormLabel } from '@mui/material';

export function FillInformation() {
  const { flexeserveType } = useParams();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const errorMessage = useErrorMessage();
  const { data } = GroupAPI.useGetGroupsListQuery();
  const optionsGroup = useMemo(
    () => getOptionsFromGroupList(data ?? []),
    [data]
  );

  const { t } = useTranslation();
  const onClickBack = () => {
    navigate('/settings');
  };
  const [initialSetup, { isLoading: isLoadingInitialSetup }] =
    LocationAPI.useInitialSetupMutation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        unitId_1: yup
          .string()
          .required(t('initial_setup.unit_id_required') ?? ''),
        unitId_2: yup
          .string()
          .required(t('initial_setup.unit_id_required') ?? ''),
        unitId_3: yup
          .string()
          .required(t('initial_setup.unit_id_required') ?? ''),
        unitId_4: yup
          .string()
          .required(t('initial_setup.unit_id_required') ?? ''),
        storeEncodedId: yup
          .string()
          .required(t('initial_setup.store_required') ?? ''),
        cloudId: yup
          .string()
          .required(t('initial_setup.cloud_id_required') ?? ''),
        unitName: yup
          .string()
          .required(t('initial_setup.unit_name_required') ?? ''),
        serialnumber: yup
          .string()
          .required(t('initial_setup.serial_number_required') ?? ''),
        model: yup.string().required(t('initial_setup.model_required') ?? ''),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      unitId_1: '',
      unitId_2: '',
      unitId_3: '',
      unitId_4: '',
      storeEncodedId: '',
      cloudId: '',
      unitName: '',
      serialnumber: '',
      model: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { unitId_1, unitId_2, unitId_3, unitId_4, ...otherValues } = values;
      const data = {
        unitId: `${unitId_1}-${unitId_2}-${unitId_3}-${unitId_4}`,
        ...otherValues,
      };
      return initialSetup(data)
        .unwrap()
        .then(() => showMessage(t('initial_setup.success')))
        .then(onClickBack)
        .catch((e) => errorMessage(serializeErrorInitialSetup(t, e)));
    },
  });
  const unitId_errors =
    ((formik.touched.unitId_1 ||
      formik.touched.unitId_2 ||
      formik.touched.unitId_3 ||
      formik.touched.unitId_4) &&
      (formik.errors?.unitId_1 ||
        formik.errors?.unitId_2 ||
        formik.errors?.unitId_3 ||
        formik.errors?.unitId_4)) ||
    null;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
    >
      <Container maxWidth="xs" sx={{ paddingY: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper elevation={5} sx={{ padding: 3 }}>
            <Typography variant="h1">{t('initial_setup.title')}</Typography>

            <Typography variant="subtitle2" sx={{ mt: 4 }} textAlign="center">
              {t(`initial_setup.flexerserve_${flexeserveType}`)}
            </Typography>
            <Box mt={3}>
              {/* inputs unitId */}
              <FormLabel className={unitId_errors ? 'FSInput-error' : ''}>
                {t('initial_setup.unit_id_label')}
              </FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Input
                    id={`unitId_1`}
                    name={`unitId_1`}
                    value={formik.values?.unitId_1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.unitId_1 && formik.errors?.unitId_1
                    )}
                    required
                    aria-required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <Input
                    id={`unitId_2`}
                    name={`unitId_2`}
                    value={formik.values?.unitId_2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.unitId_2 && formik.errors?.unitId_2
                    )}
                    required
                    aria-required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    id={`unitId_3`}
                    name={`unitId_3`}
                    value={formik.values?.unitId_3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.unitId_3 && formik.errors?.unitId_3
                    )}
                    required
                    aria-required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    id={`unitId_4`}
                    name={`unitId_4`}
                    value={formik.values?.unitId_4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.unitId_4 && formik.errors?.unitId_4
                    )}
                    required
                    aria-required
                    fullWidth
                  />
                </Grid>
              </Grid>
              {unitId_errors && (
                <Typography color={'error'} sx={{ lineHeight: '2rem' }}>
                  {unitId_errors}
                </Typography>
              )}

              <InputSelect
                id={`storeEncodedId`}
                name={`storeEncodedId`}
                label={t('initial_setup.store_label')}
                placeholder={t('initial_setup.store_placeholder')}
                options={optionsGroup}
                value={formik.values?.storeEncodedId}
                onChange={(_e: any, value: string) => {
                  formik.setFieldValue(`storeEncodedId`, value);
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched?.storeEncodedId &&
                    formik.errors?.storeEncodedId
                )}
                helperText={
                  (formik.touched?.storeEncodedId &&
                    formik.errors?.storeEncodedId) ||
                  undefined
                }
                required
                aria-required
                fullWidth
              />
              <Input
                id={`cloudId`}
                name={`cloudId`}
                label={t('initial_setup.cloud_id_label')}
                placeholder={
                  t('initial_setup.cloud_id_placeholder') ?? undefined
                }
                value={formik.values?.cloudId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.cloudId && formik.errors?.cloudId
                )}
                helperText={
                  (formik.touched.cloudId && formik.errors?.cloudId) ||
                  undefined
                }
                required
                aria-required
                fullWidth
              />
              <Input
                id={`unitName`}
                name={`unitName`}
                label={t('initial_setup.unit_name_label')}
                placeholder={
                  t('initial_setup.unit_name_placeholder') ?? undefined
                }
                value={formik.values?.unitName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.unitName && formik.errors?.unitName
                )}
                helperText={
                  (formik.touched.unitName && formik.errors?.unitName) ||
                  undefined
                }
                required
                aria-required
                fullWidth
              />
              <Input
                id={`model`}
                name={`model`}
                label={t('initial_setup.model_label')}
                placeholder={t('initial_setup.model_placeholder') ?? undefined}
                value={formik.values?.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.model && formik.errors?.model)}
                helperText={
                  (formik.touched.model && formik.errors?.model) || undefined
                }
                required
                aria-required
                fullWidth
              />
              <Input
                id={`serialnumber`}
                name={`serialnumber`}
                label={t('initial_setup.serial_number_label')}
                placeholder={
                  t('initial_setup.serial_number_placeholder') ?? undefined
                }
                value={formik.values?.serialnumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.serialnumber && formik.errors?.serialnumber
                )}
                helperText={
                  (formik.touched.serialnumber &&
                    formik.errors?.serialnumber) ||
                  undefined
                }
                required
                aria-required
                fullWidth
              />
            </Box>

            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained-gray"
                  rounded
                  fullWidth
                  onClick={onClickBack}
                  disabled={formik.isSubmitting}
                >
                  {t('button.back')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  rounded
                  fullWidth
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isLoadingInitialSetup
                  }
                  loading={isLoadingInitialSetup}
                >
                  {t('button.confirm')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Container>
    </Box>
  );
}
