import React, { useMemo } from 'react';
import {
  MenuItemResponse,
  TimerItemResponse,
} from '../../../../service/store/modules/zone/ZoneService.model';
import Button, { ButtonProps } from '../Button';
import Typography from '@mui/material/Typography';
import { useTick } from '../../../../shared/hooks/useTick';
import moment from 'moment';
import getDuration from '../../../../shared/util/moment/getDuration/getDuration';
import TextTransition from '../TextTransition';

export type TimerButtonProps = Omit<ButtonProps, 'variant'> & {
  timer?: TimerItemResponse | null;
  // animation
  showTime?: boolean;
  options?: {
    text: string;
    value: MenuItemResponse;
  }[];
};

const getBackgroundColor = (props: TimerButtonProps) => {
  const { timer } = props;
  const state = getStateFromTimer(timer);
  if (!timer) return 'transparent';
  if (state === 'alert') return '#E50E0E';
  if (state === 'warn') return '#FFA000';

  return '#64DD17';
};
const getBackgroundColorHover = (props: TimerButtonProps) => {
  const { timer } = props;
  const state = getStateFromTimer(timer);
  if (!timer) return '#DEDEDE';
  if (state === 'alert') return '#CE0F0F';
  if (state === 'warn') return '#FF6F00';
  return '#00C853';
};

export const getStateFromTimer = (timer?: TimerItemResponse | null) => {
  if (!timer) return 'idle';
  const alert = getDiffFromTimer(timer?.timeEnd) <= 0;
  if (alert) return 'alert';
  const warn = getDiffFromTimer(timer?.timePrepAlarm) <= 0;
  if (warn) return 'warn';
  return 'active';
};

const getColor = (props: TimerButtonProps) => {
  const { timer } = props;
  if (!timer) return 'inherit';
  const alert = getDiffFromTimer(timer?.timeEnd) <= 0;
  if (alert) return 'white';
  return 'black';
};

const getDiffFromTimer = (unixTime: number) => {
  let diff = moment.unix(unixTime).diff(moment());
  if (diff < 0) diff = 0;
  return diff;
};

const TextTick = ({ timer }: { timer: TimerItemResponse }) => {
  const diff = useTick(() => getDiffFromTimer(timer.timeEnd), 1000, [timer]);
  const text = getDuration(diff);
  return <>{text}</>;
};

const TimerButton = React.forwardRef(function ButtonRef(
  inProps: TimerButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { timer, options, sx, showTime, ...props } = inProps;
  const variant = 'contained-gray';
  const menuDisplayName = useMemo(
    () =>
      options?.find((option) => option.value.itemName === timer?.menuItemId)
        ?.text,
    [timer, options]
  );

  // getContent
  const content: React.ReactNode = useMemo(() => {
    let content: React.ReactNode = (
      <Typography
        fontSize={20}
        lineHeight={1}
        fontWeight={600}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        width={'100%'}
        component={'span'}
      >
        {(timer?.menuItemId || menuDisplayName) ?? ''}
      </Typography>
    );
    if (!timer) content = '';
    else if (showTime) content = <TextTick timer={timer} />;
    return content;
  }, [showTime, timer, menuDisplayName]);
  const backgroundColor = useTick(() => getBackgroundColor(inProps), 1000, [
    timer,
  ]);
  const backgroundColorHover = useTick(
    () => getBackgroundColorHover(inProps),
    1000,
    [timer]
  );
  const color = useTick(() => getColor(inProps), 1000, [timer]);

  return (
    <Button
      {...props}
      ref={ref}
      variant={variant}
      sx={{
        height: 90,
        width: 130,
        borderRadius: 3,
        padding: 1.5,
        color: color,
        border: (theme) =>
          `3px solid ${
            timer
              ? 'transparent'
              : theme.palette.mode === 'dark'
              ? '#303030'
              : 'white'
          }`,
        backgroundColor: backgroundColor,
        '&:hover': {
          backgroundColor: backgroundColorHover,
          color: color,
        },
        ...sx,
      }}
    >
      <Typography
        fontSize={22}
        lineHeight={1}
        fontWeight={600}
        sx={{
          '& div.text-transition_inner div': {
            width: '100%',
            overflow: 'hidden',
          },
        }}
        overflow={'hidden'}
        width={'100%'}
        component={'div'}
      >
        <TextTransition text={content} />
      </Typography>
    </Button>
  );
});

export default TimerButton;
