import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceWithRequireUpdateOpeningTimes from './useDeviceWithRequireUpdateOpeningTimes';
import { useAppSelector } from '../../../../redux/store.model';
import { getOpeningTimesByDeviceMutation } from '../../../../service/store/modules/openingTimes/openingTimesSelectors';

interface DeviceOpeningTimeItemProps {
  device: ReturnType<
    typeof useDeviceWithRequireUpdateOpeningTimes
  >['devicesWithUpdateRequired'][number]['device'];
}
export function DeviceOpeningTimeItem(props: DeviceOpeningTimeItemProps) {
  const { device } = props;
  const { t } = useTranslation();
  const { error, isLoading } = useAppSelector((state) =>
    getOpeningTimesByDeviceMutation(device?.uuid ?? '')(state)
  );

  return (
    <li key={device?.uuid}>
      {device?.storeName}: {device?.unitName}{' '}
      {!device?.deviceState?.mqttConnected
        ? t('operator.modal_update.device_disconnected')
        : ''}
      {isLoading ? `(${t('general.loading')})` : ''}
      {error ? `(Error: ${error})` : ''}
    </li>
  );
}

export default DeviceOpeningTimeItem;
