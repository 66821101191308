import React from 'react';
import SelectUnstyled, { SelectUnstyledProps } from '@mui/base/SelectUnstyled';
import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/material/styles';
import Button from '../Button/Button';

const StyledButton = styled(Button)(
  ({ theme }) => `
  min-width: 130px;
  `
);

export const StyledListbox = styled('ul')<{ width: number }>(
  ({ theme, ...otherProps }) => {
    return `
  box-sizing: border-box;
  padding: 0;
  margin: 0.5rem 0;
  max-height: 300px;
  min-width: 130px;
  border-radius: 6px;
  overflow: auto;
  background: ${
    theme.palette.mode === 'dark'
      ? theme.palette.grey[300]
      : theme.palette.grey[200]
  };
  color:black;
  `;
  },
  ({ theme, ...otherProps }) => {
    const maxWidth = otherProps?.width >= 130 ? otherProps?.width : 130;

    return {
      maxWidth,
      ...(otherProps.width && { width: otherProps.width }),
    };
  }
);

export const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  cursor: default;
  font-weight: 600;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-image: ${theme.palette.gradientColor.short};
    color: ${theme.palette.common.white};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[100]
    };
    color: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[100]
        : theme.palette.grey[900]
    };
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-image: ${theme.palette.gradientColor.short};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[400]
    };
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[200]
    };
    color: ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[100]
        : theme.palette.grey[900]
    };
  }
  &.${optionUnstyledClasses.selected}:hover:not(.${
    optionUnstyledClasses.disabled
  }) {
    color: ${theme.palette.grey[900]};
  }
  `
);

export const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect<TValue extends {}>(
  props: SelectUnstyledProps<TValue>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
});
export interface ButtonSelectProps<TValue extends {}>
  extends SelectUnstyledProps<TValue> {
  options: { value: TValue; text: React.ReactNode }[];
  /** use `onClickItem`  instead `onChange` when you want show the select item, but you want to chain flow when select the same item 2 or more times*/
  onClickItem?: (value: TValue) => void;
  startListItem?: React.ReactNode;
  [keyOtherComponents: string]: any;
}
export default function ButtonSelect<TValue extends {}>(
  props: ButtonSelectProps<TValue>
) {
  const { options, onClickItem, startListItem, ...otherProps } = props;
  const Component = CustomSelect as React.ComponentType<
    ButtonSelectProps<TValue>
  >;
  return (
    <Component {...otherProps} options={options}>
      {startListItem}
      {options?.map((option) => (
        <div
          key={JSON.stringify(option.value)}
          onClick={() => {
            onClickItem && onClickItem(option.value);
          }}
        >
          <StyledOption {...option}>{option.text}</StyledOption>
        </div>
      ))}
    </Component>
  );
}
