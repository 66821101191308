import { useEffect, useMemo } from 'react';
import { useDevicesControlUnits } from '../../../redux/controlUnit/hooks/useDevicesControlUnits';
import { warmUpDevicesControlUnitsTranslations } from '../../../redux/CUTranslation/action/thunks';
import {
  getLoadingCUTranslationFromDevices,
  getNeedFetchCUTranslationFromDevices,
  getTranslationState,
} from '../../../redux/CUTranslation/selectors';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../redux/store.model';
import { getTranslateManyDevicesFunction } from '../getTranslateFunction';

interface Props {
  deviceId?: string;
  groupId?: string;
  controlUnitId?: string;
}
export const useCUsTranslate = (props: Props) => {
  const { devicesControlUnits, loading: loadingDevicesControlUnits } =
    useDevicesControlUnits(props);

  const translations = useAppSelector((state: RootState) =>
    getTranslationState(state)
  );

  const needFetch = useAppSelector((state: RootState) =>
    getNeedFetchCUTranslationFromDevices(state, {
      zipDeviceControlUnits: devicesControlUnits,
    })
  );
  const loading = useAppSelector((state: RootState) =>
    getLoadingCUTranslationFromDevices(state, {
      zipDeviceControlUnits: devicesControlUnits,
    })
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loadingDevicesControlUnits && needFetch) {
      dispatch(
        warmUpDevicesControlUnitsTranslations({
          zipDeviceControlUnits: devicesControlUnits,
        })
      );
    }
  }, [devicesControlUnits, dispatch, loadingDevicesControlUnits, needFetch]);

  const CUsTranslate = useMemo(() => {
    getTranslateManyDevicesFunction(translations);
  }, [translations]);
  return [CUsTranslate, loading || needFetch || loadingDevicesControlUnits];
};
