import _ from 'lodash';
import { GroupAPIResponse } from '../../group/group.model';

export default function getAllDevicesIdFromGroups(
  groups: GroupAPIResponse[],
  individualGroup?: GroupAPIResponse | undefined
): string[] {
  let devicesIds: string[] = [];

  // add ids from individualGroup
  if (individualGroup) {
    const devicesChild = getAllDevicesIdFromGroups([individualGroup]);
    devicesIds = [...devicesIds, ...devicesChild.flatMap((id) => id)];
  }

  // add ids from nested groups
  devicesIds = [
    ...devicesIds,
    ...groups.flatMap((group) => group.devices ?? []),
  ];

  // add ids from child groups
  const devicesChild = groups.map((group) =>
    getAllDevicesIdFromGroups(group.childGroups ?? [])
  );
  devicesIds = [...devicesIds, ...devicesChild.flatMap((id) => id)];

  // remove duplicates
  devicesIds = _.uniq(devicesIds);

  return devicesIds;
}
