import { AxiosInstance } from 'axios';
import { TimerItemRequest } from './ZoneService.model';
import AxiosFlexeServeInstance from '../../../shared/AxiosFlexeServe';
import StoreService from '../../StoreService';

/**
 * @description This service is responsible for all the authentication related
 * operations.
 */
class ZoneService {
  private readonly baseUrl = 'flexeserve/operator';
  public constructor(
    private axios: AxiosInstance = AxiosFlexeServeInstance,
    private storeService = StoreService
  ) {}

  /**
   * set Timer with respective configuration and times
   * @param encodedDeviceID device.uuid encoded in base64
   * @param data Object with the timer configuration
   * @param zoneIndex Index of the zone 1-5, 1 is the first zone
   * @param timerIndex Index of the timer 0-n, 0 is the first timer
   */
  public async setTimers(
    encodedDeviceID: string,
    data: TimerItemRequest | null,
    zoneIndex: number | string,
    timerIndex: number
  ): Promise<''> {
    const url = `${this.baseUrl}/${encodedDeviceID}/timers/${zoneIndex}/${timerIndex}`;
    const response = await this.axios.put<''>(
      url,
      data === null ? 'null' : data,
      // need header to avoid 415 error
      { headers: { 'Content-Type': 'application/json' } }
    );

    return response.data;
  }
}

export default new ZoneService();
