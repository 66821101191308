import { useParams } from 'react-router-dom';
import LocationAPI from '../../locations/LocationAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { DEFAULT_TEMPERATURE_UNIT, TEMPERATURE_UNIT_TYPES } from '..';
import StoreAPI from '../../store/StoreAPI';

export function useTemperaturePreference() {
  // Manager by region
  const params = useParams<{ regionId: string }>();
  const { regionId: groupId } = params;
  const {
    error: errorTemperaturePreferenceByRegion,
    data: temperaturePreferenceByRegion,
  } = LocationAPI.useGetTemperaturePreferenceByGroupQuery(groupId ?? '', {
    skip: !groupId,
  });
  useWatchError(errorTemperaturePreferenceByRegion);

  // Operator by store
  const { data: storeInfoDetails } = StoreAPI.useGetStoreInfoDetailsQuery(
    undefined,
    {
      skip: !!groupId,
    }
  );
  const temperaturePreferenceStore = storeInfoDetails?.preferredUnit;
  // merge
  const temperaturePreference =
    temperaturePreferenceByRegion ??
    temperaturePreferenceStore ??
    DEFAULT_TEMPERATURE_UNIT;

  // support DONT_CARE
  if (temperaturePreference === TEMPERATURE_UNIT_TYPES.DONT_CARE)
    return DEFAULT_TEMPERATURE_UNIT;

  return temperaturePreference;
}
