import { createAsyncThunk } from '@reduxjs/toolkit';
import { TimerItemRequest } from '../../../service/store/modules/zone/ZoneService.model';
import { toBase64 } from '../../../shared/util/base64';
import { nameReducer } from '../Zone.model';
import ZoneService from '../../../service/store/modules/zone/ZoneService';

export const updateTimerAThunk = createAsyncThunk(
  `${nameReducer}/updateTimer`,
  async (props: {
    deviceId: string;
    data: TimerItemRequest | null;
    zoneIndex: string;
    timerIndex: number;
  }) => {
    const encodedDeviceID = toBase64(props.deviceId);
    return ZoneService.setTimers(
      encodedDeviceID,
      props.data,
      props.zoneIndex,
      props.timerIndex
    );
  }
);
