import React, { useMemo } from 'react';
import {
  MenuItemResponse,
  TimerItemResponse,
} from '../../../../service/store/modules/zone/ZoneService.model';
import TimerSelect from '../../../../theme/ui/Atoms/TimerSelect';
import StoreAPI from '../../../../service/store/StoreAPI';
import serializeMenuItemToTimer from '../../../../service/store/modules/zone/serialize/menuItemToTimer';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import useMessage from '../../../../shared/hooks/useMessage';
import { useTranslation } from 'react-i18next';

interface TimerSelectConnectedProps {
  deviceId: string;
  zoneIndex: string;
  indexTimer: number;
  timer: TimerItemResponse | null;
  showTime: boolean;
  options: {
    text: string;
    value: MenuItemResponse;
  }[];
}
function TimerSelectConnected(props: TimerSelectConnectedProps) {
  const { deviceId, indexTimer, zoneIndex, timer } = props;
  const { t } = useTranslation();
  const errorMessage = useErrorMessage();
  const showMessage = useMessage();

  const [setTimerAPI, { isLoading: setTimerLoading, originalArgs }] =
    StoreAPI.useSetTimersMutation();
  const onChangeTimer = (
    newMenu: MenuItemResponse | null,
    zoneIndex: string,
    timerIndex: number
  ) => {
    const serializedTimer = newMenu && serializeMenuItemToTimer(newMenu);

    setTimerAPI({
      data: serializedTimer,
      deviceId,
      zoneIndex,
      timerIndex,
    })
      .unwrap()
      .then(() => showMessage(t('timers.timer_set_success')))
      .catch((error) =>
        errorMessage(
          `${t('timers.timer_set_error')} ${t(
            typeof error === 'string' ? error : error.message
          )}`,
          { variant: 'warning' }
        )
      );
  };

  const timerPatched = useMemo(() => {
    if (originalArgs && setTimerLoading) return originalArgs.data;
    return timer;
  }, [originalArgs, setTimerLoading, timer]);

  return (
    <TimerSelect
      {...props}
      timer={timerPatched}
      onClickItem={(newMenu: MenuItemResponse) => {
        onChangeTimer(newMenu, zoneIndex, indexTimer);
      }}
      onClickClear={() => {
        onChangeTimer(null, zoneIndex, indexTimer);
      }}
    />
  );
}

export default TimerSelectConnected;
