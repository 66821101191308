import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TYPE_CHART_LINECHART,
  Chart,
  UiMessage,
  MODE_FLEXESERVE,
  MODE_CLOUD_LIGHT,
} from 'stoerk-ui-components';
import { SensordataSTChartLine } from '../../../../../../service/monitoring/monitoring.model';
import { TimeFrame } from '../../monitoring.model';

interface AnalogChannelsProps {
  data: SensordataSTChartLine[];
  externalTimeFrame: TimeFrame;
  setInternalTimeFrame: (timeFrame: TimeFrame) => void;
  width: number;
  height: number;
  labels: any;
  controls: any[];
  paletteColors: string[];
}

const AnalogChannelsSimple = ({
  data,
  setInternalTimeFrame,
  externalTimeFrame,
  width,
  labels,
  height,
  paletteColors,
}: AnalogChannelsProps) => {
  const dataEmpty = !data?.length;
  const i18n = useTranslation();
  const theme = useTheme();
  const mode =
    theme.palette.mode === 'dark' ? MODE_FLEXESERVE : MODE_CLOUD_LIGHT;
  const getColorPalette = useCallback(() => paletteColors, [paletteColors]);

  if (dataEmpty)
    return (
      <UiMessage
        attached
        icon="warning"
        header=""
        content={i18n.t('device.monitoring.not_data_to_show_analog_channels')}
      />
    );

  return (
    <Chart
      data={data}
      type={TYPE_CHART_LINECHART}
      height={height}
      width={width}
      labels={labels}
      minDatetime={externalTimeFrame.start.unix()}
      maxDatetime={externalTimeFrame.end.unix()}
      callBackZoom={(min: TimeFrame['start'], max: TimeFrame['end']) => {
        setInternalTimeFrame({ start: min, end: max });
      }}
      zoom={true}
      language={i18n.i18n.language}
      mode={mode}
      getColorPalette={getColorPalette}
    />
  );
};
const AnalogChannelsMemoized = React.memo(AnalogChannelsSimple);
export const AnalogChannels = AnalogChannelsMemoized;
