import { styled } from '@mui/material/styles';
import { ReactComponent as LogoFlexeserveSVG } from '../../../../assets/flexeserve-logo.svg';

const LogoFlexeserve = styled(LogoFlexeserveSVG)(
  ({ theme }) => `
    & .cls-flexeserve-logo-1 {
      fill: ${theme.palette.text.primary};
    }
    `
);

export default LogoFlexeserve;
