import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set, setWith } from 'lodash';
import { DevicesStoreInfoResponse } from '../../../service/store/StoreService.model';
import {
  TimerItemResponse,
  TimersResponse,
} from '../../../service/store/modules/zone/ZoneService.model';
import { POST_REHYDRATE } from '../../middleware/postRehydrate';
import { updateTimerAThunk } from '../actions/thunksAsync';
import { nameReducer } from '../Zone.model';

export const initialStateZone: ZoneState = {};
type DicDevice<T> = Record<DevicesStoreInfoResponse['uuid'], T>;
export interface DeviceZoneSettings {
  timers?: TimersResponse;
  timersLoading?: boolean;

  updateTimerLoading?: boolean;
}
export type ZoneState = DicDevice<DeviceZoneSettings>;

export const ZoneSlice = createSlice({
  name: nameReducer,
  initialState: initialStateZone,
  reducers: {
    setTimer(
      state,
      action: PayloadAction<{
        timer: TimerItemResponse | null;
        deviceId: string;
        zoneIndex: string;
        timerIndex: number;
      }>
    ) {
      const { timer, deviceId, zoneIndex, timerIndex } = action.payload;
      setWith(
        state,
        [deviceId, 'timers', zoneIndex, timerIndex],
        timer,
        Object
      );
    },
  },
  extraReducers: (builder) => {
    // fetchTimersAThunk Thunk
    builder.addCase(updateTimerAThunk.pending, (state, action) => {
      set(state, [action.meta.arg.deviceId, 'updateTimerLoading'], true);
    });

    builder.addCase(updateTimerAThunk.fulfilled, (state, action) => {
      set(state, [action.meta.arg.deviceId, 'updateTimerLoading'], false);
    });

    builder.addCase(updateTimerAThunk.rejected, (state, action) => {
      set(state, [action.meta.arg.deviceId, 'updateTimerLoading'], false);
    });

    builder.addCase(POST_REHYDRATE, (state, action) => {
      for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
          const element = state[key];
          element.updateTimerLoading = false;
        }
      }
    });
  },
});

export const {
  actions: zoneActions,
  reducer: zoneReducer,
  name: zoneNameReducer,
} = ZoneSlice;
