import { logout } from '../../auth/action/thunks';
import { AppDispatch } from '../../store.model';
import { loginManagerAThunk } from './thunksAsync';

/**
 * Action to load all the data needed to initialize the app.
 *
 * fetch the store info first and not in parallel to avoid multiple refreshTokens, then opening times and devices in store
 */
export const loadSessionManager = () => async (dispatch: AppDispatch) => {};

/** Clear session, login, loadSession */
export const loginManager =
  (props: { email: string; password: string }) =>
  async (dispatch: AppDispatch) => {
    await dispatch(logout());
    await dispatch(loginManagerAThunk(props)).unwrap();
  };
