import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import PaperFloatCenter from '../../theme/ui/Layout/PaperFloatCenter/PaperFloatCenter';

export default function ContactUs() {
  return (
    <PaperFloatCenter>
      <Typography variant="h1" sx={{ marginBottom: 4 }}>
        Contact us
      </Typography>
      <Typography>
        Whether by phone, email or in person, we are here for you.
      </Typography>
      <Typography>
        You can always reach our offices from Monday to Friday, 08:30 to 16:30.
      </Typography>
      <br />
      <Typography variant="subtitle2">Address</Typography>
      <Typography>Orchard House, Dodwells Road, Hinckley LE10 3BZ</Typography>
      <Typography variant="subtitle2">Phone</Typography>
      <Link href="tel:+44 (0) 1455 638300">+44 (0) 1455 638300</Link>
      <Typography variant="subtitle2">Email</Typography>
      <Link href="mailto:<Info Flexeserve>info@flexeserve.com">
        info@flexeserve.com
      </Link>
      <Typography variant="subtitle2">Web page</Typography>
      <Link href="https://www.flexeserve.com/">www.flexeserve.com</Link>
    </PaperFloatCenter>
  );
}
