import React from 'react';

import { useParams } from 'react-router-dom';
import useToggle from '../../../../shared/hooks/useToggle';
import { useTick } from '../../../../shared/hooks/useTick';
import { useAppSelector } from '../../../../redux/store.model';
import { getFeatureToggleOperatorView } from '../../../../redux/features/selectors';
import TimerOperationHorizontal from './TimerOperationHorizontal';
import TimerOperationVertical from './TimerOperationVertical';
import useDeviceWithSettingsTimers, {
  DeviceWithSettingsTimers,
} from '../../../../service/store/hooks/useDeviceWithSettingsTimers';

export interface TimerOperationViewProps {
  composedDevice: DeviceWithSettingsTimers;
  deviceId?: string;
  showTime: boolean;
}
/**
 * Part of AuthenticatedPages
 * @returns
 */
function TimerOperation() {
  const { deviceId = '' } = useParams();
  const composedDevice = useDeviceWithSettingsTimers(deviceId);
  const [showTime, toggleShowTime] = useToggle(false);
  useTick(() => toggleShowTime, 5 * 1000);

  const operatorView = useAppSelector(getFeatureToggleOperatorView);
  return operatorView === 'HORIZONTAL' ? (
    <TimerOperationHorizontal
      composedDevice={composedDevice}
      deviceId={deviceId}
      showTime={showTime}
    />
  ) : (
    <TimerOperationVertical
      composedDevice={composedDevice}
      deviceId={deviceId}
      showTime={showTime}
    />
  );
}

export default TimerOperation;
