import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaperButton from '../../../../theme/ui/Atoms/PaperButton/PaperButton';
import { useNavigate } from 'react-router-dom';
import { TwoPanelContent } from '../../../../theme/ui/Layout/TwoPanelContent';
import { LabelIcon } from '../../../../assets';
import Table from '../../../../theme/ui/Atoms/Table/Table';
import useDevicesOperator from '../../../../service/store/hooks/useDevicesOperator';

function UpdateNameDevices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { devices } = useDevicesOperator();
  const onClickDevice = (device: (typeof devices)[number]) => {
    navigate(`../update-name/device/${device.uuid}`);
  };

  return (
    <TwoPanelContent
      leftContent={
        <>
          <LabelIcon height={'5em'} />
          <Typography variant="subtitle2">
            {t('initial_setup.title_update')}
          </Typography>
          <Typography>{t('initial_setup.title_update_info')}</Typography>
        </>
      }
      rightContent={
        <Table>
          <thead style={{ border: 'none' }}>
            <tr>
              <Typography
                component={'th'}
                color="primary"
                variant="subtitle2"
                sx={{ textAlign: 'left!important' }}
              >
                {t('view_locations.header_device_name')}
              </Typography>
              <Typography component={'th'} color="primary" variant="subtitle2">
                {t('view_locations.header_model')}
              </Typography>
            </tr>
          </thead>
          <tbody>
            {devices?.map((device) => {
              return (
                <PaperButton
                  component={'tr'}
                  elevation={undefined}
                  key={device.uuid}
                  onClick={() => onClickDevice(device)}
                >
                  <td>
                    <Typography variant="body1" textAlign={'left'}>
                      {device.unitName}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" textAlign={'center'}>
                      {device.model}
                    </Typography>
                  </td>
                </PaperButton>
              );
            })}
          </tbody>
        </Table>
      }
    ></TwoPanelContent>
  );
}

export default UpdateNameDevices;
