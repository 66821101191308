import moment, { Moment } from 'moment';
import { timeToMoment } from '../../../../../shared/util/moment/parseTime';
import { OpeningTimesWeekSetting } from '../../../StoreService.model';
/**
 * Get the next day that has close hours (could be today), return this day with the closes close hours as time.
 * @param openingTimes
 */
export default function getClosesCloseHours(
  openingTimes: OpeningTimesWeekSetting,
  now: Moment = moment()
): Moment | undefined {
  const days = 7;
  for (let i = 0; i < days; i++) {
    // 1 is monday, 7 is sunday
    const todayDay = (now.day() + 6) % 7;
    const currentDay = ((todayDay + i) % 7) + 1;
    const dayOpeningTime =
      openingTimes[(currentDay + '') as keyof OpeningTimesWeekSetting];

    if (!dayOpeningTime?.closingTime) {
      continue;
    }

    // set dayOpeningTime.closingTime to today

    const closeHours = timeToMoment(dayOpeningTime.closingTime);

    if (!closeHours?.isValid()) continue;
    const iNDaysCloseHours = closeHours.add(i, 'days');

    if (iNDaysCloseHours.isAfter(now)) {
      return closeHours;
    }
  }
  return undefined;
}
