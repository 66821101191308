import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { MonitoringState, nameReducer } from '../monitoring.model';
import { fetchMetrics } from '../thunk';

export const initialStateMonitoring: MonitoringState = {
  dicSensordata: {},
  loading: undefined,
  dicDigitalChannels: {},
};

export const MonitoringSlice = createSlice({
  name: nameReducer,
  initialState: initialStateMonitoring,
  reducers: {
    clean() {
      return initialStateMonitoring;
    },
  },
  extraReducers: (builder) => {
    // fetchMetrics Thunk
    builder.addCase(fetchMetrics.pending, (state, action) => {
      return { ...initialStateMonitoring, loading: true };
    });
    builder.addCase(fetchMetrics.fulfilled, (state, action) => {
      const [dicSensordata, dicDigitalChannel] = action.payload;
      state.loading = false;
      merge(state.dicDigitalChannels, ...dicDigitalChannel);
      merge(state.dicSensordata, ...dicSensordata);
    });
    builder.addCase(fetchMetrics.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    });
  },
});

export const {
  actions: actionsMonitoring,
  reducer: reducerMonitoring,
  name: nameReducerMonitoring,
} = MonitoringSlice;
