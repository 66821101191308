import { AxiosError } from 'axios';

/**
 * Add message from generic error if the response is a string
 * @param request
 * @returns
 */
const enhanceErrorInterceptor = async (error: any | AxiosError) => {
  if (typeof error?.response?.data == 'string' && error?.response?.data) {
    return Promise.reject({
      ...error,
      message: `${error?.message} with message: "${error?.response?.data}"`,
    });
  }

  if (
    typeof error?.response?.data?.message == 'string' &&
    error?.response?.data?.message
  ) {
    return Promise.reject({
      ...error,
      message: `${error?.message} with message: "${error?.response?.data?.message}"`,
    });
  }
  return Promise.reject(error);
};

export default enhanceErrorInterceptor;
