import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import { toBase64 } from '../../shared/util/base64';

const deviceChangePasswordAPI = createApi({
  reducerPath: 'deviceChangePassword',
  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      setNewPassword: build.mutation<
        any,
        {
          newPassword: string;
          groupId: string;
        }
      >({
        query: ({ groupId, newPassword }) => {
          const EncodedGroupID = toBase64(groupId);
          const data = newPassword;
          return {
            url: `group/${EncodedGroupID}/password/`,
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
            },
            transformRequest: (res) => {
              // if you send a simple string doesn't works because is parsed as json string with "
              // so it change the default transformer
              return res;
            },
            data,
          };
        },
      }),
    };
  },
});

export default deviceChangePasswordAPI;

export const { useSetNewPasswordMutation } = deviceChangePasswordAPI;
