import { AxiosInstance } from 'axios';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import StoreService from '../store/StoreService';
import { ConfirmCorrelationResponse } from '../store/StoreService.model';

/**
 * @description This service has calls for Locations for manager that not need saved in redux
 * operations.
 */
class LocationService {
  private readonly baseUrl = 'flexeserve';
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  /** confirm call wit correlationId */
  public getStatusByCorrelationId = async (
    encodedDeviceID: string,
    correlationID: string
  ): Promise<ConfirmCorrelationResponse> => {
    const url = `${this.baseUrl}/device/${encodedDeviceID}/response/${correlationID}/v2`;
    const response = await this.axios.get<ConfirmCorrelationResponse>(url);

    return response.data;
  };

  /**
   * confirm call with correlationId, with exponential backOff
   * throw error if passed more seconds than `SECONDS_TIMEOUT`
   * throw error if passed more tries than `NUM_TRIES`
   * throw error if not found correlationId
   * throw error if not case founded
   */
  public async confirmCorrelationIdRecursive(
    encodedDeviceID: string,
    correlationID: string
  ): Promise<boolean> {
    return StoreService.confirmCorrelationIdRecursive(
      encodedDeviceID,
      correlationID,
      undefined,
      undefined,
      this.getStatusByCorrelationId
    );
  }
}

export default new LocationService();
