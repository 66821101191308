import { createTheme, ThemeOptions } from '@mui/material/styles';
import _ from 'lodash';
import sharedThemeOptions, { polyfillTheme } from './sharedThemeOptions';

const lightThemeOptions: ThemeOptions = {};
const themeOptions = _.merge({}, sharedThemeOptions, lightThemeOptions);
export const themeLight = createTheme(themeOptions);
polyfillTheme(themeLight);

export default themeLight;
