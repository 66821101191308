import React from 'react';
import Typography from '@mui/material/Typography';
import PaperFloatCenter from '../../theme/ui/Layout/PaperFloatCenter/PaperFloatCenter';

export default function Legal() {
  return (
    <PaperFloatCenter>
      <Typography variant="h1">Legal</Typography>
      <Typography>
        <p>
          ©THE ALAN NUTTALL PARTNERSHIP LTD. All rights reserved.
          <br />
          THE ALAN NUTTALL PARTNERSHIP LTD | FOODSERVICE SOLUTIONS
        </p>
        <p>
          Orchard House, Dodwells Road, Hinckley, Leicestershire, United Kingdom
          LE10 3BZ
        </p>

        <p>
          [t] +44 (0) 1455 638300
          <br />
          [e] info@flexeserve.com
          <br />
          Group website: www.nuttalls.co.uk
        </p>
      </Typography>
      <br />
    </PaperFloatCenter>
  );
}
