import { DEFAULT_NUMBER_DECIMALS_SHOW } from './Temperature.model';

export abstract class Temperature {
  static convertCelsiusToFahrenheit(celsius: number): number {
    return celsius * 1.8 + 32;
  }
  static convertFahrenheitToCelsius(fahrenheit: number): number {
    return (fahrenheit - 32) / 1.8;
  }

  static round(value: number, fixed: number = 0): number {
    const valueFixed = value.toFixed(fixed);
    // convert 1.00 to 1
    const valueTrimed = parseFloat(valueFixed);
    return valueTrimed;
  }

  static convertCelsiusToFahrenheitRound(
    celsius: number,
    fixed: number = DEFAULT_NUMBER_DECIMALS_SHOW
  ) {
    const value = this.convertCelsiusToFahrenheit(celsius);
    return this.round(value, fixed);
  }

  static convertFahrenheitToCelsiusRound(
    fahrenheit: number,
    fixed: number = 0
  ) {
    const value = this.convertFahrenheitToCelsius(fahrenheit);
    return this.round(value, fixed);
  }
}
