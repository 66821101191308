import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  Link as RouterLink,
  Outlet,
  useParams,
  useMatch,
  Navigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../theme/ui/Atoms/Button/Button';
import {
  ArrowBackIcon,
  DayPartIcon,
  ScheduleIcon,
} from '../../../../../../assets';
import { Paper, Stack } from '@mui/material';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../../shared/featureToggle';

const SubHeader = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2),

  ...theme.components?.MuiPaper,
}));

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, to } = props;
  const active = useMatch(props.to);
  return (
    <ListItem disablePadding sx={{ minWidth: { sx: undefined, md: '200px' } }}>
      <ListItemButton
        selected={!!active}
        sx={{
          borderRadius: 12,
        }}
        component={RouterLink}
        to={to}
      >
        <ListItemIcon
          sx={{
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          sx={{ display: { xs: 'none', md: 'initial' } }}
        />
      </ListItemButton>
    </ListItem>
  );
};
function ManagerScheduleSettingsLayout() {
  const { t } = useTranslation();
  const { regionId } = useParams<{ regionId: string }>();
  const dayPartListLink = `/manager/region/${regionId}/schedule-settings/day-part`;
  const scheduleListLink = `/manager/region/${regionId}/schedule-settings/schedule`;

  const { Schedule } = useAppSelector(getFeatureToggle);
  if (!Schedule) return <Navigate to={'../actions'} replace={true} />;

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <SubHeader elevation={4}>
        <div>
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore forward params not working in TS
            to="../actions"
            component={RouterLink}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        </div>
        <Stack direction={'row'}>
          <ListItemLink
            to={scheduleListLink}
            primary={t('schedule.settings.schedule.list')}
            icon={<ScheduleIcon />}
          />
          <ListItemLink
            to={dayPartListLink}
            primary={t('schedule.settings.day_part.list')}
            icon={<DayPartIcon />}
          />
        </Stack>
        <div></div>
      </SubHeader>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default ManagerScheduleSettingsLayout;
