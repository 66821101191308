export function shouldForwardProp(prop: any) {
  return (
    prop !== 'ownerState' &&
    prop !== 'theme' &&
    prop !== 'sx' &&
    prop !== 'as' &&
    prop !== 'styleProps' &&
    prop !== 'helperText' &&
    prop !== 'fullWidth' &&
    prop !== 'rounded' &&
    prop !== 'onClickItem' &&
    prop !== 'onClickClear' &&
    prop !== 'options' &&
    prop !== 'alert'
  );
}
