import { GroupAPIResponse } from '../group.model';

export default function getOptionsFromGroupList(
  groups: GroupAPIResponse[]
): { value: string; text: string }[] {
  return (
    groups
      // Only show groups that not has childGroups, those groups are candidates to be a store
      ?.filter((group) => !group.childGroups)
      .map((group) => ({
        value: group.id,
        text: group.name,
      })) ?? []
  );
}
