import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../../service/locations/LocationAPI';
import { fromBase64 } from '../../shared/util/base64';

export const getAllLocationSelector =
  LocationAPI.endpoints.getLocations.select();

export const getDeviceByBase64DeviceIdSelector = createSelector(
  [
    getAllLocationSelector,
    (_state, props: { deviceId?: string }) => props.deviceId,
  ],
  (locations, deviceBase64Id) => {
    if (!deviceBase64Id) return undefined;
    const deviceId = fromBase64(deviceBase64Id);
    const location = locations.data?.devices.find(
      (device) => device.uuid === deviceId
    );
    return location;
  }
);
