import { loadSessionManager } from '../../auth-manager/action/thunks';
import { GLOBAL_DESTROY_SESSION } from '../../reducers/types';
import { AppDispatch, AppStore } from '../../store.model';
import { getTypeUserSelector } from '../selectors';
import { loginAThunk } from './thunksAsync';
import moment from 'moment';
import getClosesCloseHours from '../../../service/store/modules/openingTimes/getClosesCloseHours';
import Storage from '../../../shared/Storage';
import Environment from '../../../shared/constants/Environment';
import StoreAPI from '../../../service/store/StoreAPI';
import { getOpeningTimesByStoreSelector } from '../../../service/store/modules/openingTimes/openingTimesSelectors';

/**
 * Action to load all the data needed to initialize the app.
 *
 * fetch the store info first and not in parallel to avoid multiple refreshTokens, then opening times and devices in store
 */
export const loadSession =
  (onOutsideOpeningTimes: Function) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const typeUser = getTypeUserSelector(getState());
    switch (typeUser) {
      case 'operator':
        return dispatch(loadSessionOperator(onOutsideOpeningTimes));
      case 'manager':
        return dispatch(loadSessionManager());
      default:
      // do nothing
    }
  };

export const loadSessionOperator =
  (onOutsideOpeningTimes: Function) => async (dispatch: AppDispatch) => {
    return Promise.all([
      dispatch(StoreAPI.endpoints.getStoreInfoDetails.initiate())
        .unwrap()
        .then(() =>
          dispatch(
            StoreAPI.endpoints.getOpeningTimesByStore.initiate()
          ).unwrap()
        )
        .then(() => dispatch(validateOpeningTimes(onOutsideOpeningTimes))),
    ]);
  };

/** get of close day of today in opening times
 * then create a setTimeout to execute the callBack
 * to logout and throw error "You can only log in within the established opening hours"
 * */
export const validateOpeningTimes =
  (onOutsideOpeningTimes: Function) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    // getOpeningTimesSelector
    const { data: openingTimes } = getOpeningTimesByStoreSelector()(getState());
    if (!openingTimes) return;
    const whenClose = getClosesCloseHours(openingTimes.storeOpeningTimes);

    if (!whenClose) return;
    /** create setTimeout when close the store */
    const timeout = moment(whenClose).diff(moment());
    setTimeout(() => {
      onOutsideOpeningTimes();
    }, timeout);
  };

/** destroy all objects in Redux */
export const logout = () => async (dispatch: AppDispatch) => {
  /**
   * Use Storage to avoid refreshToken invalid and token expiration when the app is used in different tabs
   */
  Storage.removeItem(Environment.authInfoKey);
  Storage.removeItem(Environment.authInfoManagerKey);
  return dispatch({ type: GLOBAL_DESTROY_SESSION });
};

/** Clear session, login, loadSession */
export const login =
  (props: { storeId: string; password: string }) =>
  async (dispatch: AppDispatch) => {
    await dispatch(logout());
    await dispatch(loginAThunk(props)).unwrap();
  };
