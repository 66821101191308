import produce from 'immer';
import {
  DEFAULT_EVENT_ON,
  SENSOR_F01,
  SETPOINT_I_S01,
} from '../../../../../service/controlUnit/util/constants';
import { CommanderMonitoringFilterLogicalObject } from './filter.model';

const DEFAULT_CHANNELS = [SENSOR_F01, SETPOINT_I_S01, DEFAULT_EVENT_ON];

export function checkDefaultChannels(
  commanderMonitoringFilterLogicalObject: CommanderMonitoringFilterLogicalObject
) {
  return produce(
    commanderMonitoringFilterLogicalObject,
    (commanderMonitoringFilterLogicalObjectDraft) => {
      for (const deviceId in commanderMonitoringFilterLogicalObjectDraft) {
        if (
          Object.prototype.hasOwnProperty.call(
            commanderMonitoringFilterLogicalObjectDraft,
            deviceId
          )
        ) {
          const deviceObjectFilter =
            commanderMonitoringFilterLogicalObjectDraft[deviceId];

          for (const controlUnitId in deviceObjectFilter) {
            if (
              Object.prototype.hasOwnProperty.call(
                deviceObjectFilter,
                controlUnitId
              )
            ) {
              const controlUnitObjectFilter = deviceObjectFilter[controlUnitId];
              for (const eventId in controlUnitObjectFilter) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    controlUnitObjectFilter,
                    eventId
                  )
                ) {
                  if (DEFAULT_CHANNELS.includes(eventId)) {
                    controlUnitObjectFilter[eventId] = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  );
}
