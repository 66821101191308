import { fromBase64 } from '../../../shared/util/base64';
import { GroupAPIResponse } from '../../group/group.model';
import getAllDevicesIdFromGroups from './getAllDevicesIdFromGroups';

export default function getRegionsFromGroups(groups: GroupAPIResponse[]) {
  return groups.map((group) => {
    return {
      devicesIds: getAllDevicesIdFromGroups([group]).map(fromBase64),
      groupId: group.id,
      name: group.name,
    };
  });
}
