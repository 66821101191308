import { AxiosError } from 'axios';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import { ControlUnitTranslationAPIResponse } from './CUTranslation.model';
/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class CUTranslationCalls {
  readonly backendTranslationServiceURL = 'translation';
  constructor(private axios = AxiosFlexeServeInstance) {}

  /**
   * Get events by devices id
   * @param array devicesIds
   */
  async getTranslationByControlUnit(
    deviceId: string,
    controlUnitId: number | string
  ) {
    const url = `${this.backendTranslationServiceURL}/${deviceId}/${controlUnitId}`;

    return this.axios
      .get<ControlUnitTranslationAPIResponse>(url)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          return undefined;
        } else {
          return Promise.reject(error);
        }
      });
  }
}

export default CUTranslationCalls;
