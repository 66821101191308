import { AxiosInstance } from 'axios';
import Environment from '../../shared/constants/Environment';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import { AuthInfo, IAuthInfo, LoginResponse } from './AuthService.model';

/**
 * @description This service is responsible for all the authentication related
 * operations.
 */
class AuthService {
  private readonly baseUrl = 'flexeserve/operator/token';
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  public async login(username: string, password: string): Promise<IAuthInfo> {
    // const data = objectToFormURLEncoded({
    //   username,
    //   password,
    // });
    const response = await this.axios.post<LoginResponse>(this.baseUrl, null, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params: {
        grant_type: 'password',
        response_type: 'code',
        client_id: Environment.clientID,
        client_secret: Environment.clientSecret,
        username,
        password,
      },
    });

    return new AuthInfo(response.data).getPayload();
  }

  public async refreshToken(refresh_token: string): Promise<IAuthInfo> {
    const response = await this.axios.post<LoginResponse>(this.baseUrl, null, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params: {
        grant_type: 'refresh_token',
        response_type: 'code',
        client_id: Environment.clientID,
        client_secret: Environment.clientSecret,
        refresh_token,
      },
    });

    return new AuthInfo(response.data).getPayload();
  }
}

export default new AuthService();
