import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ModalInfo } from '../../../../theme/ui/Atoms/Modal';
import useDeviceWithRequireUpdateOpeningTimes from './useDeviceWithRequireUpdateOpeningTimes';
import DeviceOpeningTimeItem from './DeviceOpeningTimeItem';

export function OpeningTimesUpdateModal() {
  const { t } = useTranslation();
  const {
    openingTimesLoadingSet,
    devicesWithUpdateRequired,
    hasSomeDeviceDisconnected,
    devicesNeedRequired,
  } = useDeviceWithRequireUpdateOpeningTimes();

  return (
    <ModalInfo
      open={!!openingTimesLoadingSet && !!devicesNeedRequired.length}
      description={
        <>
          <p>{t('opening_times.modal_update.description')}</p>
          <ul>
            {devicesWithUpdateRequired.map(({ device }) => (
              <DeviceOpeningTimeItem key={device?.uuid} device={device} />
            ))}
          </ul>
          {hasSomeDeviceDisconnected ? (
            <p>
              {t('opening_times.modal_update.description_action_disconnected')}
            </p>
          ) : null}
          <Box sx={{ textAlign: 'right' }}>
            <CircularProgress color="inherit" size={27} />
          </Box>
        </>
      }
    ></ModalInfo>
  );
}
