import { RootState } from '../../../../../../redux/store.model';

export const getLastScheduleTaskStateSelector = (state: RootState) => {
  return state.asyncTasks.schedule;
};

export const getLastScheduleTaskFromDeviceSelector = (
  state: RootState,
  props: { deviceId: string }
) => {
  const { deviceId } = props;
  return state.asyncTasks.schedule[deviceId];
};

export function getSchedulesUpdateRequiredState(state: RootState) {
  return state.asyncTasks.scheduleUpdateRequired ?? {};
}

export function getZoneSettingsUpdateRequiredState(state: RootState) {
  return state.asyncTasks.zoneSettingsUpdateRequiredSkip ?? {};
}

export function getOpeningTimesUpdateRequiredState(state: RootState) {
  return state.asyncTasks.openingTimesUpdateRequiredSkip ?? {};
}
