import { createApi } from '@reduxjs/toolkit/query/react';
import { GroupAPIResponse } from '../group/group.model';
import getAllDevicesIdFromGroups from './serialize/getAllDevicesIdFromGroups';
import {
  DevicesStoreInfoResponse,
  StoreInfoInRegionResponse,
} from '../store/StoreService.model';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  DeviceWithIncidents,
  Region,
  SetScheduleToADeviceResponseAPI,
} from './Location.model';
import { REHYDRATE } from 'redux-persist';
import { toBase64 } from '../../shared/util/base64';
import { PublicIncidentAPIResponse } from '../incidentManager/incident.model';
import getRegionsFromGroups from './serialize/getRegionsFromGroups';
import { TEMPERATURE_UNIT_TYPES } from '../temperaturePreference';

const LocationAPI = createApi({
  reducerPath: 'location',
  tagTypes: ['locations-actions', 'group-actions'],
  baseQuery: axiosBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getLocations: build.query<
        { devices: DeviceWithIncidents[]; regions: Region[] },
        void
      >({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          // get group three
          const {
            error: errorGroup,
            data: dataGroups,
            meta: metaGroups,
          } = await fetchWithBQ({ url: 'groups/' });
          const dataGroupsT = dataGroups as GroupAPIResponse[];
          if (!dataGroups) return { error: errorGroup, meta: metaGroups };

          const deviceIds = getAllDevicesIdFromGroups(dataGroupsT);
          const regions = getRegionsFromGroups(dataGroupsT);
          const responsesIncidents = (await fetchWithBQ({
            url: `incidents/device/public`,
            method: 'post',
            data: deviceIds,
          })) as QueryReturnValue<Record<string, PublicIncidentAPIResponse[]>>;
          const responses = await Promise.all(
            deviceIds.map((id) =>
              Promise.all([
                fetchWithBQ({
                  url: `flexeserve/device/${id}/`,
                }) as QueryReturnValue<DevicesStoreInfoResponse>,
                {
                  data: responsesIncidents?.data?.[id]?.length,
                  error: responsesIncidents.error,
                },
              ]).then((responses) => {
                const { data: dataDevice, error: errorDevice } = responses[0];
                const {
                  data: numberOfIncidents,
                  error: incidentResponseError,
                } = responses[1];
                return {
                  data: { ...dataDevice, alerts: numberOfIncidents ?? 0 },
                  error: errorDevice || incidentResponseError,
                };
              })
            )
          );

          const devices = responses
            .map((res) => res?.data)
            // Some times are not flexeserve devices in groups, hide them
            .filter((data) => data?.uuid);

          if (!devices.length) {
            const devicesError = responses.find((res) => res.error);
            if (devicesError) return { error: devicesError.error };
          }

          return { data: { devices, regions }, meta: metaGroups };
        },
        providesTags: ['locations-actions'],
      }),
      setScheduleToADevice: build.mutation<
        SetScheduleToADeviceResponseAPI,
        {
          deviceId: string;
          scheduleId: string;
        }
      >({
        query: ({ deviceId, scheduleId }) => {
          const encodedDeviceId = toBase64(deviceId);
          return {
            url: `flexeserve/device/${encodedDeviceId}/schedule`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: scheduleId,
            // Need a string plain, not a json
            transformRequest: (data) => data,
          };
        },
        invalidatesTags: ['locations-actions'],
      }),
      updateSetupDevice: build.mutation<void, DeviceWithIncidents>({
        query: (device) => {
          const encodedDeviceId = toBase64(device?.uuid ?? '');
          return {
            url: `flexeserve/device/${encodedDeviceId}`,
            method: 'put',
            data: device,
          };
        },
        invalidatesTags: ['locations-actions'],
      }),
      initialSetup: build.mutation<
        any,
        {
          storeEncodedId: string;
          cloudId: string;
          unitName: string;
          serialnumber: string;
          model: string;
          unitId: string;
        }
      >({
        query: ({ storeEncodedId, cloudId, ...otherData }) => {
          const encodedCloudId = toBase64(cloudId);

          return {
            url: `flexeserve/register/${storeEncodedId}/cloudConnectID/${encodedCloudId}`,
            method: 'post',
            data: otherData,
          };
        },
        invalidatesTags: ['locations-actions'],
      }),
      getTemperaturePreferenceByGroup: build.query<
        TEMPERATURE_UNIT_TYPES | undefined,
        string
      >({
        query: (encodedRegionId) => {
          return {
            url: `/flexeserve/group/${encodedRegionId}`,
            method: 'get',
          };
        },
        transformResponse: (res: StoreInfoInRegionResponse) =>
          res.preferredUnit,
        providesTags: ['group-actions'],
      }),

      setTemperaturePreferenceByGroup: build.mutation<
        void,
        {
          encodedRegionId: string;
          temperaturePreference: TEMPERATURE_UNIT_TYPES;
          recursive?: boolean;
        }
      >({
        query: ({ encodedRegionId, temperaturePreference, recursive }) => {
          return {
            url: `flexeserve/group/${encodedRegionId}/unit?recursive=${recursive}`,
            method: 'put',
            data: temperaturePreference,
            headers: {
              'Content-Type': 'application/json',
            },
            transformRequest: (res) => {
              // if you send a simple string doesn't works because is parsed as json string with "
              // so it change the default transformer
              return res;
            },
          };
        },
        invalidatesTags: ['group-actions'],
      }),
    };
  },
});

export default LocationAPI;

export const { useGetLocationsQuery } = LocationAPI;
