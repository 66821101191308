import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as LogoImg } from '../../../../../assets/circleK-logo.svg';

function HeaderLogoCircleK() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        fontSize: isMobile ? '0.6rem' : undefined,
        marginLeft: { xs: 2, md: 4 },
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <LogoImg height="3em" />
    </Box>
  );
}

export default HeaderLogoCircleK;
