import { createSelector } from '@reduxjs/toolkit';
import { getControlUnitById } from '.';
import { mergeEventAndMetadata } from '../../../service/controlUnit/util/event/mergeEventAndMetadata';
import { RootState } from '../../store.model';
import { ControlUnitState } from '../slice/controlUnitSlice';

export const getControlUnitsEventsById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.dictionaryCurrentEventsControlUnit[props.deviceId];

export const getControlUnitCurrentEventsById = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) =>
  state.controlUnit.dictionaryCurrentEventsControlUnit[props.deviceId]?.[
    props.controlUnitId
  ];

export const getControlUnitsEventsLoadingById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.loadingCurrentEventsControlUnit[props.deviceId];

export const getControlUnitsEventsErrorById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.errorCurrentEventsControlUnit[props.deviceId];

export const getEventMetadataFromControlUnitState = (
  dictionaryControlUnitState: ControlUnitState['dictionaryControlUnit'],
  deviceId: string,
  controlUnitId: string,
  eventId: string
) => {
  const controlUnit = dictionaryControlUnitState[deviceId]?.[controlUnitId];
  const metadata = controlUnit?.events.find((event) => event.id === eventId);
  return metadata;
};

export const getMetadataControlUnitEventsFromId = createSelector(
  [getControlUnitById],
  (controlUnit) => controlUnit?.events
);
export const getMixedCurrentEventsFromId = createSelector(
  [getControlUnitCurrentEventsById, getMetadataControlUnitEventsFromId],
  mergeEventAndMetadata
);
