import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { produce } from 'immer';
import { ReactComponent as StoreIcon } from '../../../../assets/store.svg';
import { useTranslation } from 'react-i18next';
import {
  OpeningTimeDaySetting,
  OpeningTimesWeekSetting,
} from '../../../../service/store/StoreService.model';
import Switch from '../../../../theme/ui/Atoms/Switch';
import {
  momentToTime,
  timeToMoment,
} from '../../../../shared/util/moment/parseTime';
import TimerPicker from '../../../../theme/ui/Atoms/TimerPicker/TimerPicker';
import { setWith } from 'lodash';
import Button from '../../../../theme/ui/Atoms/Button';
import { OpeningTimesUpdateModal } from './OpeningTimesUpdateModal';
import { useNavigate } from 'react-router-dom';
import TwoPanelContent from '../../../../theme/ui/Layout/TwoPanelContent/TwoPanelContent';
import useDeviceWithRequireUpdateOpeningTimes from './useDeviceWithRequireUpdateOpeningTimes';

const StoreIconStyled = styled(StoreIcon)(
  ({ theme }) => `
  fill: ${theme.palette.text.primary};`
);

const openingTimesArray = [
  {
    day: 'opening_times.monday',
    indexDay: '1',
  },
  {
    day: 'opening_times.tuesday',
    indexDay: '2',
  },
  {
    day: 'opening_times.wednesday',
    indexDay: '3',
  },
  {
    day: 'opening_times.thursday',
    indexDay: '4',
  },
  {
    day: 'opening_times.friday',
    indexDay: '5',
  },
  {
    day: 'opening_times.saturday',
    indexDay: '6',
  },
  {
    day: 'opening_times.sunday',
    indexDay: '7',
  },
];

export default function OpeningTimes() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    openingTimesLoadingSet,
    remoteOpeningTimes,
    updateOpiningTimesStore,
  } = useDeviceWithRequireUpdateOpeningTimes();

  const [localOpeningTimes, setLocalOpeningTimes] = useState(
    remoteOpeningTimes?.storeOpeningTimes
  );
  const onChangeDayActive = (
    indexDay: keyof OpeningTimesWeekSetting,
    remove: boolean
  ) => {
    setLocalOpeningTimes(
      produce((draft) => {
        if (!draft) return;
        const openingTimeDayValue: OpeningTimeDaySetting | undefined = !remove
          ? undefined
          : { closingTime: '', openingTime: '' };
        setWith(draft, indexDay, openingTimeDayValue, Object);
      })
    );
  };

  const onChangeDayOpeningTime = (
    indexDay: keyof OpeningTimesWeekSetting,
    value: moment.Moment | null | undefined
  ) => {
    setLocalOpeningTimes(
      produce((draft) => {
        if (!draft) return;
        setWith(draft, [indexDay, 'openingTime'], momentToTime(value), Object);
      })
    );
  };

  const onChangeDayClosingTime = (
    indexDay: keyof OpeningTimesWeekSetting,
    value: moment.Moment | null | undefined
  ) => {
    setLocalOpeningTimes(
      produce((draft) => {
        if (!draft) return;
        setWith(draft, [indexDay, 'closingTime'], momentToTime(value), Object);
      })
    );
  };

  const getAllValid = () => {
    return Object.values(localOpeningTimes ?? {}).every((openingTime) => {
      return (
        (openingTime && openingTime.openingTime && openingTime.closingTime) ||
        // if not exist it's ok
        !openingTime
      );
    });
  };

  const onSave = () => {
    if (getAllValid() && localOpeningTimes) {
      updateOpiningTimesStore(localOpeningTimes)
        .unwrap()
        .then(() => navigate('../'));
    }
  };

  const onRestartAll = () => {
    setLocalOpeningTimes(remoteOpeningTimes?.storeOpeningTimes);
  };

  return (
    <TwoPanelContent
      leftContent={
        <>
          <StoreIconStyled height={'5em'} />
          <Typography variant="subtitle2">
            {t('opening_times.title')}
          </Typography>
          <Typography variant="body1">
            {t('opening_times.description')}
          </Typography>
        </>
      }
      rightContent={
        <Box>
          <OpeningTimesUpdateModal />

          <Box
            sx={{ borderCollapse: 'separate', borderSpacing: '1em' }}
            component="table"
          >
            <thead>
              <tr>
                <th></th>
                <Typography component={'th'} color="darkgrey">
                  {t('opening_times.open')}
                </Typography>
                <th></th>
                <Typography component={'th'} color="darkgrey">
                  {t('opening_times.close')}
                </Typography>
              </tr>
            </thead>
            <tbody>
              {openingTimesArray.map((openingTime) => {
                const indexDay =
                  openingTime.indexDay as keyof OpeningTimesWeekSetting;
                const openingTimeDay = localOpeningTimes?.[indexDay];
                const openingTimeMoment = timeToMoment(
                  localOpeningTimes?.[indexDay]?.openingTime
                );
                const closingTimeMoment = timeToMoment(
                  localOpeningTimes?.[indexDay]?.closingTime
                );
                return (
                  <tr key={indexDay}>
                    <td>
                      <Switch
                        onChange={(event) =>
                          onChangeDayActive(indexDay, event.target.checked)
                        }
                        label={t(openingTime.day)}
                        checked={!!openingTimeDay}
                      />
                    </td>
                    <td>
                      <TimerPicker
                        value={openingTimeMoment}
                        onChange={(value) =>
                          onChangeDayOpeningTime(indexDay, value)
                        }
                        InputProps={{
                          required: true,
                          error: !openingTimeMoment?.isValid(),
                          placeholder: 'hh:mm',
                          sx: {
                            '.MuiInput-input': { width: '75px' },
                          },
                        }}
                        disabled={!openingTimeDay}
                      />
                    </td>
                    <td>{t('opening_times.to')}</td>
                    <td>
                      <TimerPicker
                        value={closingTimeMoment}
                        onChange={(value) =>
                          onChangeDayClosingTime(indexDay, value)
                        }
                        InputProps={{
                          required: true,
                          error: !closingTimeMoment?.isValid(),
                          placeholder: 'hh:mm',
                          sx: {
                            '.MuiInput-input': { width: '75px' },
                          },
                        }}
                        disabled={!openingTimeDay}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
          <Box
            sx={{
              width: '100%',
              textAlign: 'right',
              marginTop: 5,
              marginBottom: 3,
              paddingX: 2,
            }}
          >
            <Button
              variant="text"
              size="large"
              sx={{ marginRight: 2 }}
              onClick={onRestartAll}
            >
              {t('button.reset_all')}
            </Button>
            <Button
              variant="contained"
              size="large"
              rounded
              onClick={onSave}
              disabled={!getAllValid()}
              loading={openingTimesLoadingSet}
            >
              {t('button.save')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
}
