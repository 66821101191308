/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getZipControlUnitsByDeviceIds } from '../selectors';
import { isEqual } from 'lodash';
import LocationAPI from '../../../service/locations/LocationAPI';
import { fetchDeviceControlUnitsThunk } from '../actions/thunk';
// import { getAllDevicesIdsByMode } from '../../groups/selectors';
// import { useDevices } from '../../devices/hooks/useDevices';

interface Props {
  deviceId?: string;
  groupId?: string;
  controlUnitId?: string;
}
/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [controlUnits, loading, error]
 * 
 * @usage
´´´ts
const [controlUnits, loading, error] = useControlUnitsByIds("UUUID_DEVICE")
´´´
 */
export function useControlUnitsByIds(
  props: Props,
  onError?: (error: unknown) => unknown
) {
  // const devicesIds: string[] = useAppSelector((state) =>
  //   getAllDevicesIdsByMode(state, props)
  // );
  const devicesIds: string[] = props.deviceId ? [props.deviceId] : [];
  const { isFetching, isLoading } = LocationAPI.useGetLocationsQuery();
  // const [, devicesLoading] = useDevices(devicesIds);
  const devicesLoading = isFetching || isLoading;
  const devicesControlUnits = useAppSelector(
    (state: RootState) => getZipControlUnitsByDeviceIds(state, { devicesIds }),
    isEqual
  );
  const needFetch = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.loading === undefined
  );
  const [fetch, setFetch] = useState(needFetch);
  const refetch = useCallback(() => {
    setFetch(true);
  }, []);

  const loading = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.loading
  );
  const error = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.error
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fetch) {
      Promise.all(
        devicesControlUnits.map((deviceControlUnits) =>
          dispatch<any>(
            fetchDeviceControlUnitsThunk(deviceControlUnits.deviceId)
          )
        )
      )
        .catch(onError)
        .finally(() => setFetch(false));
      setFetch(false);
    }
  }, [fetch]);
  return [
    devicesControlUnits,
    loading || fetch || devicesLoading,
    error,
    refetch,
  ] as const;
}
