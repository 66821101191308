import { SerializedError } from '@reduxjs/toolkit';

export const nameReducer = 'asyncTasks';
export interface AsyncTaskSchedule {
  [idDevice: string]: AsyncTaskScheduleDevice[];
}
export interface AsyncTaskScheduleDevice {
  skip?: boolean;
  success?: boolean;
  /** UTC time 1690285490220 */
  timestamp: number;
  /** Linux time 1690285490 */
  activateAt?: number;
  /** UTC time 1690285490220
   * usually is the same as `activateAt`
   * used to no show again the modal
   */
  dismissUntil: number;
  scheduleId: string;
  partId: string;
}

export interface AsyncTaskScheduleUpdateRequiredDictionary {
  [idDevice: string]: AsyncTaskScheduleUpdateRequired;
}
export interface AsyncTaskScheduleUpdateRequired {
  success?: boolean;
  /** UTC time 1690285490220 */
  timestamp: number;
  /** UTC time 1690285490220
   * usually is the same as `activateAt`
   * used to no show again the modal
   */
  skip?: boolean;
  loading?: boolean;
  error?: SerializedError;
  dismissUntil?: number;
  scheduleId: string | null;
}

export interface AsyncTaskDeviceUpdateRequiredDictionary {
  [idDevice: string]: AsyncTaskDeviceUpdateRequired;
}
export interface AsyncTaskDeviceUpdateRequired {
  /** UTC time 1690285490220 */
  timestamp: number;
  /** UTC time 1690285490220
   * usually is the same as `activateAt`
   * used to no show again the modal
   */
  skip?: boolean;
  dismissUntil?: number;
}
