import { AxiosInstance } from 'axios';
import moment from 'moment';
import { getI18n } from 'react-i18next';
import { waitPromise } from '../../shared/util/waitPromise';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import {
  ConfirmCorrelationResponse,
  DevicesStoreInfoResponseV2,
  OpeningTimesWeekSetting,
  OpeningTimesStoreResponse,
  SetOpeningTimesResponse,
  StoreInfoResponse,
  CorrelationIDResponseAPI,
  TriggerDayPartAPIRequest,
} from './StoreService.model';

const NUM_TRIES = 5;
const SECONDS_TIMEOUT = 60 * 2;

/**
 * @description This service is responsible for all the authentication related
 * operations.
 */
class StoreService {
  private readonly baseUrl = 'flexeserve/operator';
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  public async getStoreInfo(): Promise<StoreInfoResponse> {
    const response = await this.axios.get<StoreInfoResponse>(this.baseUrl, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return response.data;
  }

  public async getOpeningTimesByStore(): Promise<OpeningTimesStoreResponse> {
    const url = `${this.baseUrl}/store/openingtimes`;
    const response = await this.axios.get<OpeningTimesStoreResponse>(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return response.data;
  }

  public async setOpeningTimesByStore(
    openingTimes: OpeningTimesWeekSetting
  ): Promise<SetOpeningTimesResponse> {
    const url = `${this.baseUrl}/store/openingtimes`;
    const response = await this.axios.post<SetOpeningTimesResponse>(
      url,
      openingTimes
    );
    return response.data;
  }

  /** confirm call wit correlationId */
  public getStatusByCorrelationId = async (
    encodedDeviceID: string,
    correlationID: string
  ): Promise<ConfirmCorrelationResponse> => {
    const url = `${this.baseUrl}/${encodedDeviceID}/response/${correlationID}/v2`;
    const response = await this.axios.get<ConfirmCorrelationResponse>(url);
    return response.data;
  };

  /**
   * confirm call with correlationId, with exponential backOff
   * throw error if passed more seconds than `SECONDS_TIMEOUT`
   * throw error if passed more tries than `NUM_TRIES`
   * throw error if not found correlationId
   * throw error if not case founded
   */
  public async confirmCorrelationIdRecursive(
    encodedDeviceID: string,
    correlationID: string,
    startTime = moment(),
    availableTries = NUM_TRIES,
    getStatusByCorrelationIdPromise = this.getStatusByCorrelationId
  ): Promise<boolean> {
    if (availableTries <= 0) {
      throw new Error('error.available_attempts_exceeded');
    }
    if (moment().diff(startTime, 'seconds') > SECONDS_TIMEOUT) {
      throw new Error('error.timeout_exceeded');
    }
    const statusRequest = await getStatusByCorrelationIdPromise(
      encodedDeviceID,
      correlationID
    );
    switch (statusRequest.status) {
      case 'NOT_FOUND':
        throw new Error('error.correlation_id_not_found');
      case 'PENDING':
        // add some delay with exponential backOff with 2^i to avoid too many requests
        const exponentialBackOffSeconds = 2 ** (NUM_TRIES - availableTries);
        await waitPromise(1000 * exponentialBackOffSeconds);
        return this.confirmCorrelationIdRecursive(
          encodedDeviceID,
          correlationID,
          startTime,
          availableTries - 1,
          getStatusByCorrelationIdPromise
        );
      case 'DEVICE_REPLIED':
        if (statusRequest.response.error !== 'NONE') {
          const { t } = getI18n();
          const message =
            `MQTT ${statusRequest.response.error}: ${statusRequest.response.message}`.substring(
              0,
              500
            );
          throw new Error(
            t('error.mqtt_error', {
              replace: {
                message,
              },
            }) ?? 'error.mqtt_error'
          );
        }
        return true;
      default:
        throw new Error(`error.${statusRequest}`);
    }
  }

  public async getStoreInfoDetails(): Promise<DevicesStoreInfoResponseV2> {
    const url = `${this.baseUrl}/store/v2`;
    const response = await this.axios.get<DevicesStoreInfoResponseV2>(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return response.data;
  }

  public async triggerDayPart(
    encodedDeviceID: string,
    data: TriggerDayPartAPIRequest
  ): Promise<boolean> {
    const url = `${this.baseUrl}/${encodedDeviceID}/triggerdaypart`;
    const response = await this.axios.post<CorrelationIDResponseAPI>(url, data);
    return await this.confirmCorrelationIdRecursive(
      encodedDeviceID,
      response.data.correlationID
    );
  }

  public async setScheduleRequireUpdate(
    encodedDeviceID: string
  ): Promise<boolean> {
    const url = `${this.baseUrl}/${encodedDeviceID}/schedule`;
    const response = await this.axios.post<CorrelationIDResponseAPI>(url);
    return await this.confirmCorrelationIdRecursive(
      encodedDeviceID,
      response.data.correlationID
    );
  }
}

export default new StoreService();
